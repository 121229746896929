<template>
    <div class="marketing-celebrity-slider-slide-photo-footer">
        <div class="picture-container">
            <BasePicture
                class="image"
                :width="image.width"
                :height="image.height"
                :src="image.url"
                :alt="image.alt"
                :is-lazy="true"
            />
        </div>
        <div class="celebrity-info">
            <BaseHeading class="celebrity-info-heading">
                {{ heading }}
            </BaseHeading>
            <BaseParagraph class="celebrity-info-paragraph">
                {{ subHeading }}
            </BaseParagraph>
        </div>
    </div>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';
import BasePicture from '@atoms/BasePicture/BasePicture';

import { IMAGE_TYPE_MARKETING_AVATAR_48w_48h } from '@types/Image';

export default {
    name: 'MarketingCelebritySliderSlidePhotoFooter',

    components: {
        BasePicture,
        BaseParagraph,
        BaseHeading,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        subHeading: {
            type: String,
            required: true,
        },

        avatarUrl: {
            type: String,
            required: true,
        },
    },

    computed: {
        image() {
            return this.$imaginator.getImage(
                this.avatarUrl,
                'marketing-avatar',
                `${this.heading} ${this.subHeading}`,
                IMAGE_TYPE_MARKETING_AVATAR_48w_48h
            );
        },
    },
};
</script>

<style lang="scss" scoped>
$navigation-buttons-width: 96px;

.marketing-celebrity-slider-slide-photo-footer {
    @apply flex;

    .picture-container {
        @apply w-7 h-7;
    }

    .celebrity-info {
        @apply flex flex-col justify-between ml-2 w-full;
    }

    .celebrity-info-heading,
    .celebrity-info-paragraph {
        @apply leading-s;
        max-width: calc(100% - #{$navigation-buttons-width});
    }

    &:deep(.image) {
        .image {
            @apply rounded-50;
        }
    }
}
</style>
