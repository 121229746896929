<template>
    <MarketingMFCHeroBanner v-bind="propsToBind" :class="cssClass" />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import MarketingMFCHeroBanner from '@organisms/MarketingMFCHeroBanner/MarketingMFCHeroBanner';

export default {
    name: 'CmsMarketingMFCHeroBanner',

    components: {
        MarketingMFCHeroBanner,
    },

    extends: CmsBaseComponent,

    props: getProps(MarketingMFCHeroBanner),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    heading: '',
                    paragraph: '',
                    buttonLabel: '',
                    buttonUrl: '',
                    imageUrl766x638: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                staticClass: false,
            },
        };
    },
};
</script>
