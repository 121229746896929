<template>
    <SimpleSliderSlide
        v-if="hasAllRequiredValues"
        :scroll-snap-on-mobile="true"
        class="marketing-celebrity-slider-slide"
        tag="div"
    >
        <div class="celebrity-card">
            <div class="picture-container">
                <BasePicture
                    class="slide-image"
                    :width="image.width"
                    :height="image.height"
                    :src="image.url"
                    :alt="image.alt"
                    :sources="image.sources"
                    :is-lazy="true"
                />
            </div>

            <MarketingCelebritySliderSlidePhotoFooter
                class="photo-footer"
                :heading="photoHeading"
                :sub-heading="photoSubHeading"
                :avatar-url="avatarUrl"
            />
        </div>
        <MarketingCelebritySliderSlideContent
            class="slide-content"
            :title="title"
            :content="content"
            :product-skus="productsSkus"
        />
    </SimpleSliderSlide>
</template>

<script>
import {
    DEFAULT_IMAGE_FORMAT,
    MARKETING_CELEBRITY_IMAGE_TYPES as IMAGE_TYPES,
    SOURCE_IMAGE_FORMATS,
} from '@types/Image';

import BasePicture from '@atoms/BasePicture/BasePicture';

import SimpleSliderSlide from '@molecules/SimpleSlider/SimpleSliderSlide';

import MarketingCelebritySliderSlideContent from '@organisms/MarketingCelebritySliderNew/MarketingCelebritySliderSlideContent';
import MarketingCelebritySliderSlidePhotoFooter from '@organisms/MarketingCelebritySliderNew/MarketingCelebritySliderSlidePhotoFooter';

export default {
    name: 'MarketingCelebritySliderSlide',

    components: {
        SimpleSliderSlide,
        MarketingCelebritySliderSlidePhotoFooter,
        BasePicture,
        MarketingCelebritySliderSlideContent,
    },

    props: {
        title: {
            type: String,
            required: true,
        },

        content: {
            type: String,
            required: true,
        },

        imageUrl: {
            type: String,
            required: true,
        },

        photoHeading: {
            type: String,
            required: true,
        },

        photoSubHeading: {
            type: String,
            required: true,
        },

        avatarUrl: {
            type: String,
            required: true,
        },

        productsSkus: {
            type: String,
            required: true,
        },
    },

    computed: {
        image() {
            return this.$imaginator.getImage(
                this.imageUrl,
                'marketing-celebrity',
                this.title,
                IMAGE_TYPES.marketing_celebrity_785w_785h.name,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS,
                Object.keys(IMAGE_TYPES)
            );
        },

        hasAllRequiredValues() {
            return (
                this.title &&
                this.content &&
                this.imageUrl &&
                this.photoHeading &&
                this.photoSubHeading &&
                this.avatarUrl
            );
        },
    },
};
</script>

<style lang="scss" scoped>
$celebrity-card-width-desktop: 45.5%;
$celebrity-product-cards-wrapper: 48.75%;
$product-card-hover-one-side-tablet: #{theme('customVariables.productsSlider.productHoverDesktop')};
$product-card-hover-one-side-desktop: #{theme(
        'customVariables.productsSlider.productHoverContainer'
    )};
$celebrity-slide-content-padding-desktop: 18px;

.marketing-celebrity-slider-slide {
    @apply min-w-full;

    .celebrity-card {
        @apply px-3 pt-3;
    }

    .photo-footer {
        @apply pt-3;
    }

    @screen lg {
        @apply flex justify-center mt-5;

        .photo-footer {
            @apply pb-5;
        }

        .celebrity-card {
            @apply px-0 pt-0;
            width: $celebrity-card-width-desktop;
        }

        .slide-content {
            @apply mt-0 pr-3 relative pl-7;

            right: calc(#{$product-card-hover-one-side-tablet} * -1);
            width: calc(
                #{$celebrity-product-cards-wrapper} + (#{$product-card-hover-one-side-tablet} * 2)
            );
        }

        &:deep() {
            .slider-container-content {
                .slider {
                    @apply px-0;
                }
            }

            .custom-products-simple-slider-wrapper {
                @apply pb-12p;
            }

            .container-content.slider-container-content {
                @apply px-0;
            }
        }
    }

    @screen container {
        .slide-content {
            right: calc(#{$product-card-hover-one-side-desktop} * -1);
            width: calc(
                #{$celebrity-product-cards-wrapper} + (#{$product-card-hover-one-side-desktop} * 2)
            );
        }
    }
}
</style>
