<template>
    <section class="marketing-discount-banner" :style="{ backgroundColor }">
        <ContainerContent :align-left="true" class="banner-container">
            <MarketingDiscountBannerHeading
                :heading="heading"
                :theme="theme"
                :rules="hasRulesValues"
                @rules-info-click="showRulesModal = true"
            />

            <MarketingDiscountBannerContent
                :description="description"
                :discount-code="discountCode"
                :products-button-label="productsButtonLabel"
                :products-url="productsUrl"
                :theme="theme"
            />

            <MarketingDiscountBannerRulesModal
                v-if="showRulesModal && hasRulesValues"
                :is-open="showRulesModal"
                :heading="rulesHeading"
                :content="rulesContent"
                @close="showRulesModal = false"
            />
        </ContainerContent>
    </section>
</template>

<script>
import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import { THEMES } from '@types/MarketingDiscountBanner';
import { secondary } from '@theme/resources/variables/colors.json';
import MarketingDiscountBannerHeading from './MarketingDiscountBannerHeading';
import MarketingDiscountBannerContent from './MarketingDiscountBannerContent';

export default {
    name: 'DiscountBanner',

    components: {
        ContainerContent,
        MarketingDiscountBannerHeading,
        MarketingDiscountBannerContent,
        MarketingDiscountBannerRulesModal: () => ({
            component: import(
                // eslint-disable-next-line max-len
                /* webpackChunkName: "marketing-discount-banner-rules-modal" */ '@organisms/MarketingDiscountBanner/MarketingDiscountBannerRulesModal'
            ),
        }),
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        description: {
            type: String,
            default: '',
        },

        productsButtonLabel: {
            type: String,
            required: true,
        },

        productsUrl: {
            type: String,
            required: true,
        },

        rulesHeading: {
            type: String,
            default: '',
        },

        rulesContent: {
            type: String,
            default: '',
        },

        discountCode: {
            type: String,
            default: '',
        },

        theme: {
            type: String,
            default: THEMES.THEME_LIGHT,
            validator: value => Object.values(THEMES).includes(value),
        },

        backgroundColor: {
            type: String,
            default: secondary,
        },
    },

    data() {
        return {
            showRulesModal: false,
        };
    },

    computed: {
        hasRulesValues() {
            return !!(this.rulesHeading && this.rulesContent);
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-discount-banner {
    @apply py-3;

    .banner-container {
        @apply px-3;
    }

    @screen lg {
        @apply py-5;

        .banner-container {
            @apply px-5;
        }
    }
}
</style>
