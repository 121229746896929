<template>
    <ContainerContent class="marketplace-cooperate-with-us">
        <BaseHeading class="heading" tag="h2" theme="h3">
            {{ heading }}
        </BaseHeading>

        <div class="list">
            <slot />
        </div>

        <BaseButton v-if="buttonUrl" :to="buttonUrl" class="button">
            {{ $t('How does it work?') }}
        </BaseButton>
    </ContainerContent>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseButton from '@atoms/BaseButton/BaseButton';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';

export default {
    name: 'MarketplaceCooperateWithUs',

    components: {
        BaseHeading,
        BaseButton,
        ContainerContent,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        buttonUrl: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="scss" scoped>
.marketplace-cooperate-with-us {
    @apply flex flex-col items-center text-center mt-7;

    .heading {
        @apply mb-5;
    }

    .button {
        @apply mt-5;
    }

    @screen md {
        .list {
            @apply flex justify-center;
        }
    }

    @screen lg {
        @apply mt-9;

        .heading {
            @apply text-xl leading-xl;
        }
    }
}
</style>
