<template>
    <ContainerContent class="marketing-main-category-text">
        <BaseHeading class="heading">
            {{ heading }}
        </BaseHeading>
        <div class="text-wrapper">
            <BaseParagraph class="paragraph">
                <span v-html="expanded ? fullText : shortText" />
            </BaseParagraph>
            <TextButton class="toggle-text" @click.native="expanded = !expanded">
                {{ toggleShowButton }}
            </TextButton>
        </div>
    </ContainerContent>
</template>

<script>
import TextButton from '@atoms/TextButton/TextButton';
import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';
import ContainerContent from '@molecules/ContainerContent/ContainerContent';

export default {
    name: 'MarketingMainCategoryText',

    components: {
        TextButton,
        ContainerContent,
        BaseHeading,
        BaseParagraph,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        shortText: {
            type: String,
            default: '',
        },

        fullText: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            expanded: false,
        };
    },

    computed: {
        toggleShowButton() {
            return this.expanded ? this.$t('Show less') : this.$t('Show more');
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-main-category-text {
    @apply flex flex-col;

    .heading {
        @apply leading-s mb-3;
    }
    .paragraph,
    .toggle-text {
        @apply text-tertiary;
    }

    .paragraph {
        @apply inline leading-s;
    }

    .toggle-text {
        @apply underline;
    }

    @screen mobile-only {
        @apply border-t-1 border-solid border-gray5 pt-6 mb-8;
    }

    @screen md {
        @apply mb-8;
    }
}
</style>
