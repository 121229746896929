<template>
    <div class="live-stream-accordion">
        <BaseHeading tag="h2" theme="h3" class="heading">
            {{ heading }}
        </BaseHeading>
        <slot />
    </div>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';

export default {
    name: 'LiveStreamAccordion',

    components: {
        BaseHeading,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.live-stream-accordion {
    @apply mb-7;

    .heading {
        @apply text-center;
    }

    @screen lg {
        @apply mb-6;

        .heading {
            @apply mb-6 text-xl leading-xl;
        }
    }
}
</style>
