<template>
    <li class="marketplace-familiar-brand">
        <BasePicture
            :width="image.width"
            :height="image.height"
            :src="image.url"
            :alt="image.alt"
            :sources="image.sources"
            :is-lazy="true"
        />
    </li>
</template>

<script>
import {
    DEFAULT_IMAGE_FORMAT,
    MARKETPLACE_FAMILIAR_BRANDS_TYPES as IMAGE_TYPES,
    SOURCE_IMAGE_FORMATS,
} from '@types/Image';

import BasePicture from '@atoms/BasePicture/BasePicture';

export default {
    name: 'MarketplaceFamiliarBrand',

    components: {
        BasePicture,
    },

    props: {
        imageUrl: {
            type: String,
            required: true,
        },

        brandName: {
            type: String,
            required: true,
        },
    },

    computed: {
        image() {
            return this.$imaginator.getImage(
                this.imageUrl,
                'marketplace_familiar_brands',
                `${this.brandName}`,
                IMAGE_TYPES.marketplace_familiar_brands_200w_100h.name,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS
            );
        },
    },
};
</script>

<style lang="scss" scoped>
$brand-md-margin: 20px;
$brand-md-items-in-row: 3;
$brand-max-width: 200px;

.marketplace-familiar-brand {
    @apply w-full mb-20p mr-20p mt-0;
    max-width: $brand-max-width;
    width: calc(
        (100% / #{$brand-md-items-in-row}) - ((3 * #{$brand-md-margin}) / #{$brand-md-items-in-row})
    );

    &:nth-child(3n) {
        @apply mr-0;
    }

    @screen md {
        &:nth-child(3) {
            @apply mr-20p;
        }
    }

    @screen lg {
        &:not(:last-child) {
            @apply mr-8;
        }
    }
}
</style>
