<template>
    <CmsWithAccordions>
        <StaticBlock v-bind="propsToBind" :class="cssClass" />
    </CmsWithAccordions>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import CmsWithAccordions from '@functionals/CmsWithAccordions/CmsWithAccordions';
import StaticBlock from '@atoms/StaticBlock/StaticBlock';

export default {
    name: 'CmsStaticBlockWithAccordions',

    components: {
        CmsWithAccordions,
        StaticBlock,
    },

    extends: CmsBaseComponent,

    props: {
        ...getProps(StaticBlock),
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    script: '',
                    content: '',
                },
            },
        };
    },
};
</script>
