<template>
    <MarketingCardsSlider2Slide v-bind="propsToBind" @click.native="onClick()" />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';

import MarketingCardsSlider2Slide from '@organisms/MarketingCardsSlider2/MarketingCardsSlider2Slide';

export default {
    name: 'CmsMarketingCardsSlider2Slide',

    components: {
        MarketingCardsSlider2Slide,
    },

    extends: CmsBaseComponent,

    inject: {
        componentClick: {
            default: () => () => {},
        },
    },

    props: getProps(MarketingCardsSlider2Slide),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    heading: '',
                    subHeading: '',
                    imageUrl: '',
                    url: '',
                    videoUrl: '',
                },
            },
            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },

    methods: {
        onClick() {
            this.componentClick({
                subheading: this.propsToBind.subHeading,
                id: this.internalId,
            });
        },
    },
};
</script>
