<template>
    <div class="marketing-multi-banner-media">
        <BasePicture
            v-if="image"
            :width="image.width"
            :height="image.height"
            :src="image.url"
            :alt="image.alt"
            :sources="image.sources"
            :is-lazy="isLazy"
        />
        <IframeVideo
            v-else
            :src="videoSource"
            :width="mediaWidth"
            :height="mediaHeight"
            :is-lazy="isLazy"
        />
    </div>
</template>

<script>
import {
    DEFAULT_IMAGE_FORMAT,
    SOURCE_IMAGE_FORMATS,
    IMAGE_TYPE_MARKETING_BANNER_360w_450h,
    IMAGE_TYPE_MARKETING_BANNER_767w_450h,
    IMAGE_TYPE_MARKETING_CARD_492w_656h,
} from '@types/Image';

import IframeVideo from '@atoms/IframeVideo/IframeVideo';
import BasePicture from '@atoms/BasePicture/BasePicture';

export default {
    name: 'MarketingMultiBannerMedia',

    components: {
        IframeVideo,
        BasePicture,
    },

    props: {
        mediaSrc: {
            type: String,
            required: true,
        },

        mediaWidth: {
            type: Number,
            required: true,
        },

        mediaHeight: {
            type: Number,
            required: true,
        },

        isMobile: {
            type: Boolean,
            required: true,
        },

        isLeft: {
            type: Boolean,
            default: false,
        },

        isVideo: {
            type: Boolean,
            default: false,
        },

        isLazy: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        videoSource() {
            return `${this.mediaSrc}?h&autoplay=1&loop=1&muted=1&controls=0&autopause=false`;
        },

        image() {
            if (this.isVideo) {
                return null;
            }

            let imageType = this.isLeft
                ? IMAGE_TYPE_MARKETING_BANNER_767w_450h
                : IMAGE_TYPE_MARKETING_CARD_492w_656h;

            if (this.isMobile) {
                imageType = IMAGE_TYPE_MARKETING_BANNER_360w_450h;
            }

            return this.$imaginator.getImage(
                this.mediaSrc,
                'marketing-multi-banner-image',
                '',
                imageType,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-multi-banner-media {
    @apply w-full;
}
</style>
