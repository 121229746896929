<template>
    <MarketplaceCooperationWithModivo :class="cssClass" v-bind="propsToBind">
        <template #subheading>
            <PageBuilderInterpreter :components-json="slotSubheading" />
        </template>
        <template #text>
            <PageBuilderInterpreter :components-json="slotText" />
        </template>
    </MarketplaceCooperationWithModivo>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';
import { getProps } from '@modules/page-builder/helpers/component';

import MarketplaceCooperationWithModivo from '@organisms/MarketplaceCooperationWithModivo/MarketplaceCooperationWithModivo';

export default {
    name: 'CmsMarketplaceCooperationWithModivo',

    components: { PageBuilderInterpreter, MarketplaceCooperationWithModivo },

    extends: CmsBaseComponent,

    props: {
        slotSubheading: {
            type: String,
            default: '',
        },

        slotText: {
            type: String,
            default: '',
        },

        ...getProps(MarketplaceCooperationWithModivo),
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                slots: {
                    subheading: '',
                    text: '',
                },
                props: {
                    heading: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                staticClass: false,
            },
        };
    },
};
</script>
