<template>
    <PageBuilderInterpreter
        :tag="FooterLinks"
        :components-json="slotDefault"
        :tag-props="propsToBind"
    />
</template>

<script>
import { getProps } from '@modules/page-builder/helpers/component';
import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

import FooterLinks from '@molecules/FooterLinks/FooterLinks';

export default {
    name: 'CmsFooterLinks',

    components: {
        PageBuilderInterpreter,
    },

    extends: CmsBaseComponent,

    props: {
        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },

        ...getProps(FooterLinks),
    },

    data() {
        return {
            FooterLinks,
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                slots: {
                    default: '',
                },

                props: {
                    heading: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },
};
</script>
