<template>
    <div>
        <PageBuilderInterpreter :components-json="slotDefault" />
    </div>
</template>

<script>
/* eslint-disable vue/no-unused-properties */
import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';

export default {
    name: 'CmsFooterConfiguration',

    components: {
        PageBuilderInterpreter,
    },
    props: {
        instagramUrl: {
            type: String,
            default: '',
        },

        facebookUrl: {
            type: String,
            default: '',
        },

        youtubeUrl: {
            type: String,
            default: '',
        },

        tiktokUrl: {
            type: String,
            default: '',
        },

        pinterestUrl: {
            type: String,
            default: '',
        },

        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },
    },

    data() {
        return {
            defaultConfig: {
                slots: {
                    default: '',
                },
                props: {
                    instagramUrl: '',
                    facebookUrl: '',
                    youtubeUrl: '',
                    tiktokUrl: '',
                    pinterestUrl: '',
                },
            },
        };
    },
};
</script>
