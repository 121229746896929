<template>
    <MarketingSaleBar v-bind="propsToBind" :class="cssClass" />
</template>

<script>
import { tertiary } from '@theme/resources/variables/colors.json';

import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import MarketingSaleBar from '@molecules/MarketingSaleBar/MarketingSaleBar';

import { BUTTON_LARGE_VARIANTS } from '@modivo-ui/components/ButtonLarge/v1';

export default {
    name: 'CmsMarketingSaleBar',

    components: {
        MarketingSaleBar,
    },

    extends: CmsBaseComponent,

    props: getProps(MarketingSaleBar),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    heading: '',
                    description1: '',
                    textColor: tertiary,
                    buttonVariant: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
                buttonVariant: Object.values(BUTTON_LARGE_VARIANTS),
            },
        };
    },
};
</script>
