<template>
    <SimpleSliderSlide
        v-if="hasAllRequiredValues"
        :scroll-snap-on-mobile="true"
        class="marketing-celebrity-slider-slide"
        tag="div"
    >
        <div class="celebrity-card">
            <div class="picture-container">
                <BasePicture
                    class="slide-image"
                    :width="image.width"
                    :height="image.height"
                    :src="image.url"
                    :alt="image.alt"
                    :sources="image.sources"
                    :is-lazy="true"
                />
            </div>

            <MarketingCelebritySliderSlidePhotoFooter
                class="photo-footer"
                :heading="photoHeading"
                :sub-heading="photoSubHeading"
                :avatar-url="avatarUrl"
            />
        </div>
        <MarketingCelebritySliderSlideContent
            class="slide-content"
            :title="title"
            :content="content"
            :product-skus="productsSkus"
        />
    </SimpleSliderSlide>
</template>

<script>
import SimpleSliderSlide from '@molecules/SimpleSlider/SimpleSliderSlide';
import MarketingCelebritySliderSlideContent from '@organisms/MarketingCelebritySlider/MarketingCelebritySliderSlideContent';
import BasePicture from '@atoms/BasePicture/BasePicture';
import MarketingCelebritySliderSlidePhotoFooter from '@organisms/MarketingCelebritySlider/MarketingCelebritySliderSlidePhotoFooter';
import {
    DEFAULT_IMAGE_FORMAT,
    MARKETING_CELEBRITY_IMAGE_TYPES as IMAGE_TYPES,
    SOURCE_IMAGE_FORMATS,
} from '@types/Image';

export default {
    name: 'MarketingCelebritySliderSlide',

    components: {
        SimpleSliderSlide,
        MarketingCelebritySliderSlidePhotoFooter,
        BasePicture,
        MarketingCelebritySliderSlideContent,
    },

    props: {
        title: {
            type: String,
            required: true,
        },

        content: {
            type: String,
            required: true,
        },

        imageUrl: {
            type: String,
            required: true,
        },

        photoHeading: {
            type: String,
            required: true,
        },

        photoSubHeading: {
            type: String,
            required: true,
        },

        avatarUrl: {
            type: String,
            required: true,
        },

        productsSkus: {
            type: String,
            required: true,
        },
    },

    computed: {
        image() {
            return this.$imaginator.getImage(
                this.imageUrl,
                'marketing-celebrity',
                this.title,
                IMAGE_TYPES.marketing_celebrity_492w_656h.name,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS,
                Object.keys(IMAGE_TYPES)
            );
        },

        hasAllRequiredValues() {
            return (
                this.title &&
                this.content &&
                this.imageUrl &&
                this.photoHeading &&
                this.photoSubHeading &&
                this.avatarUrl
            );
        },
    },
};
</script>

<style lang="scss" scoped>
$celebrity-card-width-desktop: calc((100% / 3) - (32px * 2) / 3);
$photo-footer-margin-bottom: $tailwindcss-customSizes-12p;
$slide-content-margin-top: $tailwindcss-spacing-4;

.marketing-celebrity-slider-slide {
    @apply min-w-full;

    .celebrity-card {
        @apply px-3;
    }

    .photo-footer {
        @apply px-12p pt-12p;
    }

    .slide-content {
        margin-top: calc(#{$photo-footer-margin-bottom} + #{$slide-content-margin-top});
    }

    @screen md {
        @apply flex;

        .celebrity-card {
            @apply w-1/2 self-center px-0;
        }

        .slide-content {
            @apply w-1/2 mt-0;
        }
    }

    @screen xl {
        .celebrity-card {
            width: $celebrity-card-width-desktop;
        }

        .slide-content {
            @apply w-2/3;
        }
    }
}
</style>
