import {
    CMS_MARKETING_HERO_BLOCK,
    CMS_RECOMMENDED_PRODUCTS_SIMPLE_SLIDER_WRAPPER,
    CMS_MARKETING_CARDS_SLIDER2,
    CMS_MARKETING_MULTI_BANNER,
    CMS_SPONSORED_SLIDER_WRAPPER,
} from '@modules/page-builder/page-builder.config';

const COMPONENTS_TO_COLLECT = [
    CMS_MARKETING_HERO_BLOCK,
    CMS_RECOMMENDED_PRODUCTS_SIMPLE_SLIDER_WRAPPER,
    CMS_MARKETING_CARDS_SLIDER2,
    CMS_MARKETING_MULTI_BANNER,
    CMS_SPONSORED_SLIDER_WRAPPER,
];

export const getRecommendedSliderComponentVersion = ({
    heading = '',
    subheading = '',
    buttonLabel = '',
    buttonLink = '',
}) => {
    const headingMask = 4;
    const subheadingMask = 2;
    const buttonMask = 1;

    const versionMap = {
        0: 'clear',
        4: 'no text & no button',
        5: 'no text',
        6: 'no button',
        7: 'full',
    };

    let version = 0;

    if (heading) {
        version += headingMask;
    }

    if (subheading) {
        version += subheadingMask;
    }

    if (buttonLabel && buttonLink) {
        version += buttonMask;
    }

    return versionMap[version] || 'unknown';
};

export const getMultibannerComponentVersion = (
    { description = '', buttonLabel = '' },
    { slider }
) => {
    if (!description && !buttonLabel && !slider) {
        return 'clear';
    }

    const versionParts = [];

    if (!description) {
        versionParts.push('no text');
    }

    if (!buttonLabel) {
        versionParts.push('no button');
    }

    if (!slider?.length) {
        versionParts.push('no carousel');
    }

    return versionParts.join(' & ') || 'full';
};

export const getHeroBlockComponentVersion = ({ description = '' }) => {
    return description ? 'full' : 'no text';
};

export const getSponsoredSliderComponentVersion = () => {
    return 'no text & button';
};

export const getMarketingCardsSlider2ComponentVersion = (_, slots) => {
    const { default: defaultSlot = [] } = slots;
    const hasEverySlideSubheading = defaultSlot.every(({ props }) => props.subHeading);

    return hasEverySlideSubheading ? 'full' : 'no text';
};

const COMPONENTS_VERSION_MATCHERS_MAP = {
    [CMS_MARKETING_HERO_BLOCK]: getHeroBlockComponentVersion,
    [CMS_RECOMMENDED_PRODUCTS_SIMPLE_SLIDER_WRAPPER]: getRecommendedSliderComponentVersion,
    [CMS_MARKETING_CARDS_SLIDER2]: getMarketingCardsSlider2ComponentVersion,
    [CMS_MARKETING_MULTI_BANNER]: getMultibannerComponentVersion,
    [CMS_SPONSORED_SLIDER_WRAPPER]: getSponsoredSliderComponentVersion,
};

const COMPONENTS_NAME_MAP = {
    [CMS_MARKETING_HERO_BLOCK]: 'hero',
    [CMS_RECOMMENDED_PRODUCTS_SIMPLE_SLIDER_WRAPPER]: 'reco',
    [CMS_MARKETING_CARDS_SLIDER2]: 'promo',
    [CMS_MARKETING_MULTI_BANNER]: 'main',
    [CMS_SPONSORED_SLIDER_WRAPPER]: 'sponsored',
};

export const getComponentsList = list => {
    let componentIds = {};

    list.forEach(component => {
        const { name, id, slots = {}, props = {} } = component;

        if (COMPONENTS_TO_COLLECT.includes(name)) {
            const componentVersion = COMPONENTS_VERSION_MATCHERS_MAP[name]?.(props, slots);
            const componentMappedName = COMPONENTS_NAME_MAP[name];

            componentIds[id] = `${componentMappedName} ${componentVersion}`;

            return;
        }

        Object.values(slots).forEach(slot => {
            if (!Array.isArray(slot)) {
                return;
            }

            componentIds = { ...componentIds, ...getComponentsList(slot) };
        });
    });

    return componentIds;
};
