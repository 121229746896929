export const TOGGLE_ICON_ARROW = 'arrow';
export const TOGGLE_ICON_PLUS_MINUS = 'plusMinus';
export const TOGGLE_ICON_PLUS_MINUS_LARGE = 'plusMinusLarge';

export const TOGGLE_ICONS = [
    TOGGLE_ICON_ARROW,
    TOGGLE_ICON_PLUS_MINUS,
    TOGGLE_ICON_PLUS_MINUS_LARGE,
];

export const TITLE_WEIGHT_NORMAL = 'normal';
export const TITLE_WEIGHT_SEMI = 'semibold';

export const TITLE_WEIGHTS = [TITLE_WEIGHT_NORMAL, TITLE_WEIGHT_SEMI];
