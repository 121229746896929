<template>
    <SimpleSliderSlide v-if="hasAllRequiredValues" class="loyalty-club-ambassador-slider-slide">
        <BasePicture
            class="image"
            :width="image.width"
            :height="image.height"
            :src="image.url"
            :alt="image.alt"
            :sources="image.sources"
            :is-lazy="true"
            :style="imageStyle"
        />

        <BaseHeading class="name">{{ name }}</BaseHeading>
        <BaseParagraph class="description">
            {{ description }}
        </BaseParagraph>

        <div v-if="socialMediaLinksMap.length" class="social-media-section">
            <BaseLinkStatic
                v-for="({ link, icon }, linkId) in socialMediaLinksMap"
                :key="linkId"
                :path="link"
                target="_blank"
                class="social-media-section-link"
            >
                <SvgIcon width="16px" height="16px" :fill-color="$theme.colors.gray2">
                    <component :is="icon" />
                </SvgIcon>
            </BaseLinkStatic>
        </div>
    </SimpleSliderSlide>
</template>

<script>
import { mapState } from 'vuex';

import {
    DEFAULT_IMAGE_FORMAT,
    SOURCE_IMAGE_FORMATS,
    IMAGE_TYPE_LOYALTY_CLUB_AVATAR_200w_200h,
} from '@types/Image';

import Instagram from '@static/icons/24px/instagram.svg?inline';
import Facebook from '@static/icons/24px/facebook.svg?inline';
import Youtube from '@static/icons/24px/youtube.svg?inline';

import BasePicture from '@atoms/BasePicture/BasePicture';
import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';
import SvgIcon from '@atoms/SvgIcon/SvgIcon';

import SimpleSliderSlide from '@molecules/SimpleSlider/SimpleSliderSlide';

const IMAGE_SIZE_MOBILE = '200px';

export default {
    name: 'LoyaltyClubAmbassadorsSliderSlide',

    components: {
        SimpleSliderSlide,
        BasePicture,
        BaseHeading,
        BaseParagraph,
        BaseLinkStatic,
        SvgIcon,
        Instagram,
        Facebook,
        Youtube,
    },

    props: {
        imageUrl: {
            type: String,
            required: true,
        },

        name: {
            type: String,
            required: true,
        },

        description: {
            type: String,
            required: true,
        },

        instagramUrl: {
            type: String,
            default: '',
        },

        facebookUrl: {
            type: String,
            default: '',
        },

        youtubeUrl: {
            type: String,
            default: '',
        },
    },

    computed: {
        ...mapState(['isMobile']),

        image() {
            return this.$imaginator.getImage(
                this.imageUrl,
                'loyalty-club-avatar',
                this.name,
                IMAGE_TYPE_LOYALTY_CLUB_AVATAR_200w_200h,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS,
                [IMAGE_TYPE_LOYALTY_CLUB_AVATAR_200w_200h]
            );
        },

        imageStyle() {
            return this.isMobile ? { paddingBottom: this.IMAGE_SIZE_MOBILE } : {};
        },

        hasAllRequiredValues() {
            return this.imageUrl && this.name && this.description;
        },

        socialMediaLinksMap() {
            return [
                {
                    link: this.instagramUrl,
                    icon: Instagram,
                },
                {
                    link: this.facebookUrl,
                    icon: Facebook,
                },
                {
                    link: this.youtubeUrl,
                    icon: Youtube,
                },
            ].filter(({ link }) => link);
        },
    },

    beforeCreate() {
        this.IMAGE_SIZE_MOBILE = IMAGE_SIZE_MOBILE;
    },
};
</script>

<style lang="scss" scoped>
$image-size-mobile: 200px;
$slide-width-mobile: 242px;
$slide-width-desktop: calc((100% / 5) - (48px * 4) / 5);

.loyalty-club-ambassador-slider-slide {
    @apply mr-3 shrink-0;
    width: $slide-width-mobile;

    &:last-of-type {
        @apply mr-0;
    }

    .image {
        @apply rounded-50 overflow-hidden mx-auto;
        width: $image-size-mobile;
    }

    .name {
        @apply block mt-2 text-center uppercase text-s leading-s;
    }

    .description {
        @apply mt-2 text-center text-s leading-s text-gray2;
    }

    .social-media-section {
        @apply flex justify-center mt-2;
    }

    .social-media-section-link {
        @apply mr-2;

        &:last-of-type {
            @apply mr-0;
        }
    }

    &:deep(.image) {
        .image {
            @apply w-full;
        }
    }

    @screen lg {
        @apply mr-7;
        width: $slide-width-desktop;

        .image {
            @apply w-auto;
        }

        .name {
            @apply text-r leading-r;
        }
    }
}
</style>
