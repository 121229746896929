import { THEME_PRIMARY as buttonThemePrimary } from '@types/Button';

export const THEME_LIGHT = 'light';
export const THEME_DARK = 'dark';

export const THEMES = {
    THEME_LIGHT,
    THEME_DARK,
};

export const BUTTON_THEME_DEFAULT = 'default';
export const BUTTON_THEME_PRIMARY = buttonThemePrimary;

export const BUTTON_THEMES = {
    BUTTON_THEME_DEFAULT,
    BUTTON_THEME_PRIMARY,
};

export const HEADING_TAGS = {
    SPAN: 'span',
    H1: 'h1',
    H2: 'h2',
    H3: 'h3',
    H4: 'h4',
    H5: 'h5',
    H6: 'h6',
};
