<template>
    <MarketingBar
        v-bind="baseProps"
        icon="Newsletter"
        class="marketing-bar-default"
        @click.native="openNewsletterModal()"
    >
        <template #after-text>
            <div class="text-wrapper">
                <span class="action-label">
                    {{ newsletterText }}
                </span>

                <Icon :icon="ChevronRight" width="16" height="16" class="icon" />
            </div>
        </template>
    </MarketingBar>
</template>

<script>
import {
    MODAL_NEWSLETTER_NAME,
    MODAL_NEWSLETTER_RELEASER,
    NEWSLETTER_RELEASER_HEADER,
    SHOULD_OPEN_MODAL_ON_CLICK,
} from '@configs/modals';

import MarketingBar from '@marketing-bar-components/molecules/MarketingBar/MarketingBar';

import { Icon } from '@modivo-ui/components/Icon/v1';

import { ChevronRight } from '@modivo-ui/icons/v2/navigation';

export default {
    name: 'MarketingBarDefault',

    components: {
        MarketingBar,
        Icon,
    },

    extends: MarketingBar,

    computed: {
        newsletterText() {
            return this.$t('Subscribe to the newsletter');
        },
    },

    beforeCreate() {
        this.ChevronRight = ChevronRight;
    },

    methods: {
        openNewsletterModal() {
            this.$modals.open(MODAL_NEWSLETTER_NAME, {
                [MODAL_NEWSLETTER_RELEASER]: NEWSLETTER_RELEASER_HEADER,
                [SHOULD_OPEN_MODAL_ON_CLICK]: true,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-bar-default {
    @apply flex flex-col justify-center items-center cursor-pointer;

    .text-wrapper {
        @apply flex flex-row items-center font-book h-5;
    }

    .action-label {
        @apply underline;
    }

    .icon {
        @apply ml-1;
    }

    @screen lg {
        @apply flex-row;

        .text-wrapper {
            @apply ml-2;
        }
    }
}
</style>
