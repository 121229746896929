<template>
    <MarketingCelebritySliderSlideNew v-bind="propsToBind" />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';

import MarketingCelebritySliderSlideNew from '@organisms/MarketingCelebritySliderNew/MarketingCelebritySliderSlide';

export default {
    name: 'CmsMarketingCelebritySliderSlideNew',

    components: {
        MarketingCelebritySliderSlideNew,
    },

    extends: CmsBaseComponent,

    props: getProps(MarketingCelebritySliderSlideNew),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    title: '',
                    content: '',
                    imageUrl: '',
                    photoHeading: '',
                    photoSubHeading: '',
                    avatarUrl: '',
                    productsSkus: '',
                },
            },
            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },
};
</script>
