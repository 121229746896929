<template>
    <div />
</template>

<script>
import { v4 as uuid } from 'uuid';

import { BUTTON_LARGE_VARIANTS } from '@modivo-ui/components/ButtonLarge/v1';

let AVAILABLE_PAGES = [];

export default {
    name: 'CmsSmartBannerConfig',

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        enabledPages: {
            type: Array,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        capId: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        isLarge: {
            type: Boolean,
            default: false,
        },

        // eslint-disable-next-line vue/no-unused-properties
        bannerText: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        buttonText: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        oneLinkUrl: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        imageUrl: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        buttonVariant: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        bannerName: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    enabledPages: [],
                    capId: uuid(),
                    isLarge: false,
                    bannerText: '',
                    buttonText: '',
                    oneLinkUrl: '',
                    imageUrl: '',
                    buttonVariant: BUTTON_LARGE_VARIANTS.PRIMARY,
                    bannerName: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                enabledPages: AVAILABLE_PAGES,
                buttonVariant: Object.values(BUTTON_LARGE_VARIANTS),
            },

            // eslint-disable-next-line vue/no-unused-properties
            propsCustomFormConfig: {
                capId: {
                    hasActionButton: true,
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            propsCustomData: {
                capId: {
                    actionFunction: () => uuid(),
                },
            },
        };
    },

    beforeCreate() {
        AVAILABLE_PAGES = this.$router.options.routes
            .map(item => [item, ...(item?.children || [])].filter(item2 => item2?.name))
            .flat()
            .map(({ name }) => name);
    },
};
</script>
