<template>
    <component :is="isLazy ? 'div' : 'LazyHydrate'" :never="isLazy ? null : true">
        <component
            :is="wrapperComponent"
            :style="style"
            :observe-once="isLazy ? true : null"
            :class="{ 'background-filter': isBackgroundFilterOn }"
            class="video-container"
            @intersect="onIntersect($event)"
        >
            <iframe
                ref="iframe"
                :src="isLazy ? renderSrc : src"
                class="video-iframe"
                allowfullscreen
                allow="autoplay"
                @load="$emit('loaded')"
            />
        </component>
    </component>
</template>

<script>
import round from '@assets/round';
import LazyHydrate from '@assets/vue-lazy-hydration/LazyHydrate';

import Observer from '@atoms/Observer/Observer';

export default {
    name: 'IframeVideo',

    components: {
        Observer,
        LazyHydrate,
    },

    inject: {
        backgroundColor: {
            default: 'transparent',
        },
    },

    props: {
        src: {
            type: String,
            required: true,
        },

        width: {
            type: Number,
            default: null,
        },

        height: {
            type: Number,
            default: null,
        },

        isLazy: {
            type: Boolean,
            default: false,
        },

        isBackgroundFilterOn: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            renderSrc: this.isLazy ? null : this.src,
        };
    },

    computed: {
        aspectRatio() {
            if (!this.width || !this.height) {
                return null;
            }

            const ratio = (this.height / this.width) * 100;

            return round(ratio, 3);
        },

        wrapperComponent() {
            return this.isLazy ? 'Observer' : 'div';
        },

        style() {
            const styles = {
                '--iframe-video-background-color': this.backgroundColor,
            };

            if (this.aspectRatio) {
                styles.paddingTop = `${this.aspectRatio}%`;
            }

            return styles;
        },
    },

    methods: {
        onIntersect(intersect) {
            if (intersect) {
                this.renderSrc = this.src;
            }
        },

        // eslint-disable-next-line vue/no-unused-properties
        play() {
            this.$refs.iframe?.contentWindow?.postMessage(JSON.stringify({ method: 'play' }), '*');
        },
    },
};
</script>

<style lang="scss">
.video-container {
    @apply relative;

    &.background-filter {
        @apply bg-gray5;

        .video-iframe {
            @apply mix-blend-multiply brightness-102;
        }
    }

    .video-iframe {
        @apply border-0 h-full w-full left-0 top-0 absolute;

        background: var(--iframe-video-background-color);
    }
}
</style>
