<template>
    <section class="marketing-celebrity-slider">
        <ContainerContent>
            <SimpleSlider
                v-if="applySlider"
                :items-count="1"
                navigation-buttons-class="marketing-celebrity-slider-navigation"
                :horizontal-navigation-buttons="false"
                tag="div"
            >
                <template #slides>
                    <slot />
                </template>
            </SimpleSlider>

            <slot v-else />
        </ContainerContent>
    </section>
</template>

<script>
/* eslint-disable max-len */
import SimpleSlider from '@molecules/SimpleSlider/SimpleSlider';
import ContainerContent from '@molecules/ContainerContent/ContainerContent';

export default {
    name: 'MarketingCelebritySliderNew',

    components: {
        SimpleSlider,
        ContainerContent,
    },

    computed: {
        applySlider() {
            return this.$slots?.default?.length > 1;
        },
    },
};
</script>

<style lang="scss" scoped>
$celebrity-card-width-desktop: 45.5%;
$container-padding: $tailwindcss-spacing-3;
$navigation-buttons-width: $tailwindcss-spacing-7 * 2;
$navigation-button-right-padding: 16px;
$navigation-button-right-position: 4px;

.marketing-celebrity-slider {
    &:deep() {
        .container-content {
            @apply px-0;
        }

        .navigation-buttons {
            &.marketing-celebrity-slider-navigation {
                @apply flex bg-transparent;
                right: $navigation-button-right-position;
                top: calc(100vw - #{$container-padding});
            }
        }
    }

    @screen md {
        @apply bg-gray5;

        &:deep() {
            .container-content.slider-container-content {
                @apply px-0;
            }

            .simple-slider {
                .navigation-button {
                    @apply bg-gray5;

                    &:active {
                        @apply bg-gray5;

                        &:focus,
                        &:hover {
                            @apply bg-gray5;
                        }
                    }
                }
            }
        }
    }

    @screen lg {
        @apply px-3;

        &:deep() {
            .navigation-buttons {
                &.marketing-celebrity-slider-navigation {
                    @apply pb-5 pt-0 right-auto top-auto bottom-0;
                    left: calc(
                        #{$celebrity-card-width-desktop} - #{$navigation-buttons-width} + 2 * #{$navigation-button-right-padding}
                    );
                }
            }
        }
    }

    @screen container {
        &:deep() {
            .navigation-buttons {
                &.marketing-celebrity-slider-navigation {
                    left: calc(
                        #{$celebrity-card-width-desktop} - #{$navigation-buttons-width} + 3 * #{$navigation-button-right-padding}
                    );
                }
            }
        }
    }
}
</style>
