<template>
    <ContainerContent
        class="marketing-hero-banner"
        :full-width="fullWidth"
        :class="{ 'reset-left-and-right-space': !fullWidth }"
    >
        <div class="banner-container">
            <div class="banner-wrapper">
                <slot />
            </div>
        </div>
    </ContainerContent>
</template>

<script>
import ContainerContent from '@molecules/ContainerContent/ContainerContent';

export default {
    name: 'MarketingHeroBanner',

    components: {
        ContainerContent,
    },

    props: {
        fullWidth: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style lang="scss" scoped>
$marketing-hero-max-width: theme('customVariables.marketingHero.maxWidth');
$hero-max-margin-right: 132px;

.marketing-hero-banner {
    .banner-container {
        @apply w-full;
    }

    &:deep() {
        &.reset-left-and-right-space {
            .content {
                @apply px-0;
            }
        }

        .marketing-hero {
            &:not(:first-of-type) {
                margin-top: theme('customVariables.marketingHero.heroMarginTopMobile');
            }
        }
    }

    @screen md {
        @apply flex-nowrap;

        .banner-container {
            @apply pt-5 pb-7 px-5 bg-gray5 flex justify-center;
        }

        .banner-wrapper {
            @apply flex justify-between w-full;
            max-width: calc((2 * #{$marketing-hero-max-width}) + #{$hero-max-margin-right});
        }

        &:deep() {
            .marketing-hero {
                &:first-of-type {
                    @apply mr-7;
                }

                &:not(:first-of-type) {
                    @apply mt-0;
                }
            }
        }
    }

    @screen lg {
        @apply px-5;
    }
}
</style>
