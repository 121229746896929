<template>
    <MarketingMultiLinkBannerLarge v-bind="propsToBind" :class="cssClass">
        <template v-if="!!slotLinks" #links>
            <PageBuilderInterpreter
                :tag="MarketingMultiLinkBannerLinks"
                :components-json="slotLinks"
            />
        </template>
    </MarketingMultiLinkBannerLarge>
</template>

<script>
import { THEMES, HEADING_TAGS } from '@types/MarketingBanner';

import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';

import MarketingMultiLinkBannerLarge from '@organisms/MarketingMultiLinkBanner/MarketingMultiLinkBannerLarge';
import MarketingMultiLinkBannerLinks from '@organisms/MarketingMultiLinkBanner/MarketingMultiLinkBannerLinks';

export default {
    name: 'CmsMarketingMultiLinkBannerLarge',

    components: { PageBuilderInterpreter, MarketingMultiLinkBannerLarge },

    extends: CmsBaseComponent,

    props: {
        slotLinks: {
            type: String,
            default: '',
        },

        ...getProps(MarketingMultiLinkBannerLarge),
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    theme: THEMES.THEME_LIGHT,
                    heading: '',
                    headingTag: HEADING_TAGS.SPAN,
                    imageUrl450x450: '',
                    imageUrl767x450: '',
                    imageUrl1532x450: '',
                },

                slots: {
                    links: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                staticClass: false,
                theme: Object.values(THEMES),
                headingTag: Object.values(HEADING_TAGS),
            },
        };
    },

    beforeCreate() {
        this.MarketingMultiLinkBannerLinks = MarketingMultiLinkBannerLinks;
    },
};
</script>
