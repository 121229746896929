<template>
    <StaticBlock v-bind="propsToBind" :class="cssClass" />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';

import StaticBlock from '@atoms/StaticBlock/StaticBlock';

export default {
    name: 'CmsStaticBlock',

    components: {
        StaticBlock,
    },

    extends: CmsBaseComponent,

    props: {
        ...getProps(StaticBlock),
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    script: '',
                    content: '',
                },
            },
        };
    },
};
</script>
