<template>
    <ListItem v-bind="propsToBind" :class="cssClass">
        <PageBuilderInterpreter :components-json="slotDefault" tag="span" />
    </ListItem>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';

import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';
import ListItem from '@atoms/ListItem/ListItem';
import { TYPES, THEMES, SIZES } from '@types/ListItem';

export default {
    name: 'CmsListItem',

    components: {
        ListItem,
        PageBuilderInterpreter,
    },

    extends: CmsBaseComponent,

    props: {
        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },
        ...getProps(ListItem),
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                slots: {
                    default: '',
                },
                props: {
                    marginTop: 'mt-3',
                },
            },
            // eslint-disable-next-line vue/no-unused-properties
            options: {
                type: Object.values(TYPES),
                theme: Object.values(THEMES),
                size: Object.values(SIZES),
            },
        };
    },
};
</script>
