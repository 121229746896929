<template>
    <section class="marketing-mfc-hero-banner">
        <ContainerContent class="banner-container">
            <div class="texts-wrapper">
                <div class="heading-main-wrapper">
                    <SvgIcon width="48px" height="48px">
                        <MFCLogo />
                    </SvgIcon>
                    <BaseHeading class="heading-main">
                        {{ heading }}
                    </BaseHeading>
                </div>
                <BaseParagraph>
                    <span v-html="paragraph" />
                </BaseParagraph>
                <ButtonLarge :to="buttonUrl" :variant="BUTTON_VARIANT">
                    {{ buttonLabel }}
                </ButtonLarge>
            </div>
            <div class="image-wrapper">
                <BasePicture
                    class="hero-image"
                    :src="image.url"
                    :alt="image.alt"
                    :width="image.width"
                    :height="image.height"
                    :is-lazy="true"
                />
            </div>
        </ContainerContent>
    </section>
</template>

<script>
import { IMAGE_TYPE_MARKETING_MFC_HERO_BANNER_766w_638h } from '@types/Image';

import MFCLogo from '@static/icons/48px/mfc.svg?inline';

import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';
import BasePicture from '@atoms/BasePicture/BasePicture';
import SvgIcon from '@atoms/SvgIcon/SvgIcon';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';

import { ButtonLarge, BUTTON_LARGE_VARIANTS } from '@modivo-ui/components/ButtonLarge/v1';

export default {
    name: 'MarketingMFCHeroBanner',

    components: {
        BaseHeading,
        BaseParagraph,
        BasePicture,
        MFCLogo,
        SvgIcon,
        ContainerContent,
        ButtonLarge,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        paragraph: {
            type: String,
            required: true,
        },

        buttonLabel: {
            type: String,
            required: true,
        },

        buttonUrl: {
            type: String,
            required: true,
        },

        imageUrl766x638: {
            type: String,
            required: true,
        },
    },

    computed: {
        image() {
            return this.$imaginator.getImage(
                this.imageUrl766x638,
                'mfc-hero-banner',
                this.heading,
                IMAGE_TYPE_MARKETING_MFC_HERO_BANNER_766w_638h
            );
        },
    },

    beforeCreate() {
        this.BUTTON_VARIANT = BUTTON_LARGE_VARIANTS.SECONDARY;
    },
};
</script>

<style lang="scss" scoped>
$headingMainDesktop: 36px;
$headingMainDesktopLine: 45px;
$textsPaddingBottom: 34px;
$mainPaddingTop: 50px;
$wrapperWidthTablet: 441px;
$wrapperWidthDesktop: 496px;
$imageMaxWidth: 766px;
$bg-gray: #e5e6e8;

.marketing-mfc-hero-banner {
    padding-top: $mainPaddingTop;
    background: $bg-gray;

    &:deep(.container-content) {
        @apply px-0;
    }

    .banner-container {
        @apply flex-col;
    }

    .texts-wrapper {
        @apply flex flex-col items-center px-4;
        padding-bottom: $textsPaddingBottom;
    }

    .heading-main-wrapper {
        @apply flex justify-center items-center w-full pb-1;
    }

    .heading-main {
        @apply pl-10p text-new-m leading-new-m;
    }

    &:deep(.paragraph) {
        @apply pb-4 leading-s text-r text-center text-tertiary;
    }

    .image-wrapper {
        @apply w-full;
    }

    @screen md {
        @apply pt-0;

        &:deep(.container-content) {
            @apply px-5;
        }

        .image-wrapper {
            @apply w-1/2;
            max-width: $imageMaxWidth;
        }

        .heading-main {
            @apply text-new-l leading-new-l;
        }

        .banner-container {
            @apply flex-nowrap flex-row-reverse items-center;
        }

        .texts-wrapper {
            @apply px-0 pb-0 justify-center items-center mr-auto ml-auto w-1/2;
            max-width: $wrapperWidthTablet;
        }
    }

    @screen lg {
        .texts-wrapper {
            max-width: $wrapperWidthDesktop;
        }

        .heading-main {
            font-size: $headingMainDesktop;
            line-height: $headingMainDesktopLine;
        }

        &:deep(.paragraph) {
            @apply text-new-r leading-new-m;
        }
    }
}
</style>
