export const SIZE_BIG = 'big';
export const SIZE_MEDIUM = 'medium';
export const SIZE_SMALL = 'small';
export const SIZE_EXTRA_SMALL = 'extra-small';

export const DISPLAY_INLINE = 'inline';
export const DISPLAY_BLOCK = 'block';

export const ALIGN_LEFT = 'left';
export const ALIGN_CENTER = 'center';
export const ALIGN_RIGHT = 'right';
export const SIZES = { SIZE_BIG, SIZE_MEDIUM, SIZE_SMALL, SIZE_EXTRA_SMALL };

export const DISPLAYS = { DISPLAY_INLINE, DISPLAY_BLOCK };
export const ALIGNS = { ALIGN_LEFT, ALIGN_CENTER, ALIGN_RIGHT };
