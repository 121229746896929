<template>
    <SimpleSliderSlide
        class="vertical-products-simple-slider-slide"
        :scroll-snap-on-desktop="false"
        :scroll-snap-on-mobile="false"
    >
        <VerticalSliderProduct :product="product" />
    </SimpleSliderSlide>
</template>

<script>
import SimpleSliderSlide from '@molecules/SimpleSlider/SimpleSliderSlide';
import VerticalSliderProduct from '@organisms/VerticalSliderProduct/VerticalSliderProduct';

export default {
    name: 'VerticalProductsSimpleSliderSlide',

    components: {
        SimpleSliderSlide,
        VerticalSliderProduct,
    },

    props: {
        product: {
            type: Object,
            required: true,
        },
    },
};
</script>
