<template>
    <li class="list-item" :class="[type, theme, size]">
        <slot />
    </li>
</template>

<script>
import { TYPES, THEMES, SIZES } from '@types/ListItem';

import { checkIfExistsInValuesMap } from '@assets/props';

export default {
    name: 'ListItem',
    props: {
        /**
         * type of list item
         */
        type: {
            type: String,
            default: TYPES.TYPE_DOT,
            validator: checkIfExistsInValuesMap(TYPES, true),
        },
        /**
         * theme of list item
         */
        theme: {
            type: String,
            default: THEMES.THEME_PRIMARY,
            validator: checkIfExistsInValuesMap(THEMES, true),
        },
        /**
         * size of list item
         */
        size: {
            type: String,
            default: SIZES.SIZE_SMALL,
            validator: checkIfExistsInValuesMap(SIZES, true),
        },
    },
};
</script>

<style lang="scss" scoped>
.list-item {
    @apply list-none flex text-r text-gray6 leading-r;

    &::before {
        @apply inline-block pr-3;

        content: '';
    }

    &.num {
        counter-increment: list;

        &::before {
            content: counter(list) '.';
        }
    }

    &.dot {
        &::before {
            @apply text-m;

            content: '•';
        }
    }

    &.empty {
        &::before {
            @apply hidden;
        }
    }

    &.smaller {
        @apply text-s leading-s;
    }

    &.big {
        @apply text-m leading-m;

        &::before {
            @apply text-xl;
        }
    }

    &.primary {
        &::before {
            @apply text-primary;
        }
    }

    &.tertiary {
        @apply text-tertiary;
    }

    &.dark {
        @apply text-dark;
    }
}
</style>
