export const THEME_PRIMARY = 'primary';
export const THEME_TERTIARY = 'tertiary';
export const THEME_DARK = 'dark';
export const THEME_INFORMATION = 'information';

export const SIZE_BIG = 'big';
export const SIZE_NORMAL = 'normal';
export const SIZE_SMALL = 'small';
export const SIZE_SMALL_DESKTOP_NORMAL = 'small-desktop-normal';

export const THEMES = {
    THEME_PRIMARY,
    THEME_TERTIARY,
    THEME_INFORMATION,
    THEME_DARK,
};

export const SIZES = {
    SIZE_BIG,
    SIZE_NORMAL,
    SIZE_SMALL,
    SIZE_SMALL_DESKTOP_NORMAL,
};
