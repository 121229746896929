<template>
    <BaseBadge v-bind="propsToBind" />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import { THEMES, SIZES } from '@types/Badge';
import BaseBadge from '@atoms/BaseBadge/BaseBadge';

export default {
    name: 'CmsBaseBadge',

    components: {
        BaseBadge,
    },

    extends: CmsBaseComponent,

    props: getProps(BaseBadge),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    content: 'example badge content',
                },
            },
            // eslint-disable-next-line vue/no-unused-properties
            options: {
                theme: Object.values(THEMES),
                size: Object.values(SIZES),
            },
        };
    },
};
</script>
