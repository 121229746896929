import { DISPLAYS, SIZES, ALIGNS } from '@types/ProductPrice';
import { checkIfExistsInValuesMap } from '@assets/props';

export default {
    props: {
        pricePromotional: {
            type: String,
            required: true,
        },

        priceRegular: {
            type: String,
            default: '',
        },

        showDiscount: {
            type: Boolean,
            default: true,
        },

        showDiscountValue: {
            type: Boolean,
            default: true,
        },

        display: {
            type: String,
            default: DISPLAYS.DISPLAY_BLOCK,
            validator: checkIfExistsInValuesMap(DISPLAYS, true),
        },

        size: {
            type: String,
            default: SIZES.SIZE_SMALL,
            validator: checkIfExistsInValuesMap(SIZES, true),
        },

        alignText: {
            type: String,
            default: ALIGNS.ALIGN_CENTER,
            validator: checkIfExistsInValuesMap(ALIGNS, true),
        },
    },
};
