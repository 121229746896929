<template>
    <PageBuilderInterpreter :components-json="json" tag="div" />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';

export default {
    name: 'CmsBlockImport',

    components: {
        PageBuilderInterpreter,
    },

    extends: CmsBaseComponent,

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        blockId: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            json: JSON.stringify(''),
            // eslint-disable-next-line vue/no-unused-properties
            options: {
                // @TODO get list of importable blocks from backend
                blockId: () => import('@modules/page-builder/example-json/import-blocks.json'),
                marginTop: false,
            },
        };
    },

    async fetch() {
        await this.fetchBlock(this.blockId);
    },

    watch: {
        blockId: {
            async handler(blockIdValue) {
                await this.fetchBlock(blockIdValue);
            },
        },
    },

    methods: {
        // eslint-disable-next-line vue/no-unused-properties
        async fetchBlock(blockId) {
            // @TODO fetch block by id from backend
            try {
                const block = await import(
                    // eslint-disable-next-line prefer-template
                    '@modules/page-builder/example-json/' + blockId + '.json'
                );

                this.json = JSON.stringify(block.default);
            } catch (err) {
                console.log(err);
            }
        },
    },
};
</script>
