<template>
    <li class="marketplace-partners-benefit">
        <div class="icon">
            <SvgIcon class="icon-svg" width="88px" height="88px">
                <component :is="iconType" />
            </SvgIcon>
        </div>
        <div class="text">
            <BaseHeading class="title" tag="h3">
                {{ heading }}
            </BaseHeading>
            <BaseParagraph class="description">
                {{ text }}
            </BaseParagraph>
        </div>
    </li>
</template>

<script>
import { ICON_MAP, ICON_TYPES } from '@configs/marketplace-partner-benefit';

import { checkIfExistsInValuesMap } from '@assets/props';

import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';
import SvgIcon from '@atoms/SvgIcon/SvgIcon';

export default {
    name: 'MarketplacePartnersBenefit',

    components: { BaseHeading, BaseParagraph, SvgIcon },

    props: {
        heading: {
            type: String,
            required: true,
        },

        text: {
            type: String,
            required: true,
        },

        icon: {
            type: String,
            required: true,
            validator: checkIfExistsInValuesMap(ICON_TYPES),
        },
    },

    computed: {
        iconType() {
            return ICON_MAP[this.icon];
        },
    },
};
</script>

<style lang="scss" scoped>
$horizontal-gap-mobile: 24px;
$horizontal-gap-desktop: 88px;

.marketplace-partners-benefit {
    @apply flex w-full;

    &:not(:first-child) {
        @apply mt-4;
    }

    .icon {
        @apply w-8 h-8 rounded-50 bg-gray5 mr-3 shrink-0 flex items-center justify-center;
    }

    .icon-svg {
        @apply w-5 h-5;
    }

    .title {
        @apply mb-1;
    }

    @screen mobile-only {
        .description {
            @apply text-s leading-s;
        }
    }

    @screen md {
        width: calc((100% - #{$horizontal-gap-mobile}) / 2);

        &:nth-child(2n) {
            @apply ml-4;
        }

        &:not(:first-child) {
            @apply mt-0;
        }

        &:nth-child(n + 3) {
            @apply mt-4;
        }
    }

    @screen lg {
        @apply items-center;

        width: calc((100% - #{$horizontal-gap-desktop}) / 2);

        &:nth-child(2n) {
            @apply ml-9;
        }

        &:nth-child(n + 3) {
            @apply mt-8;
        }

        .icon {
            @apply mr-5;

            width: theme('customVariables.marketplacePartnersBenefit.circleWidthDesktop');
            height: theme('customVariables.marketplacePartnersBenefit.circleHeightDesktop');
        }

        .icon-svg {
            @apply h-9 w-9;
        }

        .title {
            @apply mb-3 text-m leading-m;
        }
    }
}
</style>
