<template>
    <li ref="accordionItem" class="accordion-item">
        <div
            v-if="showAllItemsAndDisableToggle"
            :class="titleWeight"
            class="accordion-title accordion-title-active disable-pointer"
        >
            <slot name="accordion-title" />
        </div>

        <BaseButton
            v-else
            :class="[
                {
                    'accordion-title-active': isActive,
                },
                titleWeight,
            ]"
            :theme="THEME_PURE"
            :type="TYPE_BUTTON"
            class="accordion-title"
            @click="toggle()"
        >
            <slot name="accordion-title" />
            <div class="toggle-icon">
                <SvgIcon :width="iconWidth" :height="iconWidth">
                    <component :is="isActive ? activeIcon : inactiveIcon" />
                </SvgIcon>
            </div>
        </BaseButton>

        <transition
            name="accordion"
            mode="out-in"
            @enter="start($event)"
            @after-enter="end($event)"
            @before-leave="start($event)"
            @after-leave="end($event)"
        >
            <div
                v-if="!doNotRenderContentWhenClosed || isActive"
                v-show="isActive"
                class="accordion-content"
            >
                <slot name="accordion-content" />
            </div>
        </transition>
    </li>
</template>

<script>
import {
    ANIMATION_DURATION,
    ACCORDION_CLOSE_STATE,
    ACCORDION_OPEN_STATE,
} from '@configs/accordion';

import { checkIfExistsInValuesMap } from '@assets/props';

import Arrow1Down from '@static/icons/16px/arrow1-down.svg?inline';
import Arrow1Up from '@static/icons/16px/arrow1-up.svg?inline';
import Minus1 from '@static/icons/16px/minus1.svg?inline';
import Plus1 from '@static/icons/16px/plus1.svg?inline';

import Minus from '@static/icons/24px/minus.svg?inline';
import Add2 from '@static/icons/24px/add2.svg?inline';

import {
    TOGGLE_ICONS,
    TOGGLE_ICON_ARROW,
    TOGGLE_ICON_PLUS_MINUS,
    TOGGLE_ICON_PLUS_MINUS_LARGE,
    TITLE_WEIGHTS,
    TITLE_WEIGHT_SEMI,
} from '@types/AccordionItem';
import { THEME_PURE, TYPE_BUTTON } from '@types/Button';

import BaseButton from '@atoms/BaseButton/BaseButton';
import SvgIcon from '@atoms/SvgIcon/SvgIcon';

const toggleIconMap = {
    [TOGGLE_ICON_ARROW]: {
        activeIcon: Arrow1Up,
        inactiveIcon: Arrow1Down,
    },

    [TOGGLE_ICON_PLUS_MINUS]: {
        activeIcon: Minus1,
        inactiveIcon: Plus1,
    },

    [TOGGLE_ICON_PLUS_MINUS_LARGE]: {
        activeIcon: Minus,
        inactiveIcon: Add2,
    },
};

export default {
    name: 'AccordionItem',

    components: {
        Arrow1Down,
        Arrow1Up,
        Minus1,
        Plus1,
        Minus,
        Add2,
        BaseButton,
        SvgIcon,
    },

    props: {
        activeItemKey: {
            type: String,
            default: '',
        },

        toggleIcon: {
            type: String,
            default: TOGGLE_ICON_ARROW,
            validator: checkIfExistsInValuesMap(TOGGLE_ICONS),
        },

        titleWeight: {
            type: String,
            default: TITLE_WEIGHT_SEMI,
            validator: checkIfExistsInValuesMap(TITLE_WEIGHTS),
        },

        showAllItemsAndDisableToggle: {
            type: Boolean,
            default: false,
        },

        isActiveScrollTo: {
            type: Boolean,
            default: true,
        },

        doNotRenderContentWhenClosed: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        isActive() {
            return this.showAllItemsAndDisableToggle || this.$vnode.key === this.activeItemKey;
        },

        inactiveIcon() {
            return toggleIconMap[this.toggleIcon].inactiveIcon;
        },

        activeIcon() {
            return toggleIconMap[this.toggleIcon].activeIcon;
        },

        iconWidth() {
            return this.toggleIcon === TOGGLE_ICON_PLUS_MINUS_LARGE ? '24px' : '16px';
        },
    },

    beforeCreate() {
        this.THEME_PURE = THEME_PURE;
        this.TYPE_BUTTON = TYPE_BUTTON;
    },

    methods: {
        toggle() {
            const { isActive } = this;

            const key = isActive ? '' : this.$vnode.key;
            const state = isActive ? ACCORDION_CLOSE_STATE : ACCORDION_OPEN_STATE;

            this.$emit('accordion-toggle', {
                key,
                itemRef: this.$refs.accordionItem,
                state,
            });
        },

        async start(el) {
            if (this.doNotRenderContentWhenClosed) {
                await this.$nextTick();
            }

            el.style.height = `${el.scrollHeight}px`;
        },

        end(el) {
            const { isActive, $scrollTo, isActiveScrollTo } = this;

            el.style.height = '';

            if (isActive && $scrollTo && isActiveScrollTo) {
                setTimeout(() => $scrollTo(this.$refs.accordionItem), ANIMATION_DURATION);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@theme/resources/mixin/accordion-transition.scss';

.accordion-item {
    @apply flex flex-col relative border-border border-b-1;

    .accordion-title {
        @apply w-full flex justify-between items-center cursor-pointer;

        &.normal {
            @apply font-book;
        }

        &.semibold {
            @apply font-semibold;
        }

        &.accordion-title-active ~ .accordion-content {
            @apply mb-3;
        }

        &.disable-pointer {
            @apply cursor-default;
        }
    }

    @include accordion-transition();
}
</style>
