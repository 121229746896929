import { xl as BREAKPOINT_XL } from '@theme/resources/variables/responsiveBreakpoints.json';

export default {
    data() {
        return {
            mediaQueryList: null,
            isXlBreakpointMediaQuery: false,
            itemsCountLg: 2,
            itemsCountXl: 3,
        };
    },

    computed: {
        productsSliderItems() {
            return this.isXlBreakpointMediaQuery ? this.itemsCountXl : this.itemsCountLg;
        },
    },

    beforeMount() {
        this.mediaQueryList = window.matchMedia(`(min-width: ${BREAKPOINT_XL})`);
        this.mediaQueryList.addListener(this.checkViewport);
        this.isXlBreakpointMediaQuery = this.mediaQueryList.matches;
    },

    beforeDestroy() {
        this.mediaQueryList.removeListener(this.checkViewport);
    },

    methods: {
        checkViewport(event) {
            this.isXlBreakpointMediaQuery = event.matches;
        },
    },
};
