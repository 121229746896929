<template>
    <PageBuilderInterpreter
        :tag="FAQListItem"
        :components-json="slotDefault"
        :class="cssClass"
        :tag-props="propsToBind"
    />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';

import FAQListItem from '@organisms/FAQList/components/FAQListItem/FAQListItem';

export default {
    name: 'CmsFAQListItem',

    components: {
        PageBuilderInterpreter,
    },

    extends: CmsBaseComponent,

    props: {
        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },
        ...getProps(FAQListItem),
    },

    data() {
        return {
            FAQListItem,
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                slots: {
                    default: '',
                },
                props: {
                    title: '',
                },
            },
            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },
};
</script>
