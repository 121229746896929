<template>
    <component
        :is="linkComponent"
        :path="path"
        :target="target"
        :style="{ color }"
        class="marketing-bar-link"
    >
        <span class="label">{{ label }}</span>
        <Icon v-if="hasIcon" :icon="ChevronRight" width="16" height="16" class="icon" />
    </component>
</template>

<script>
import { isHttpLink } from '@assets/link';

import BaseLink from '@atoms/BaseLink/BaseLink';
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';

import { Icon } from '@modivo-ui/components/Icon/v1';

import { ChevronRight } from '@modivo-ui/icons/v2/navigation';

export default {
    name: 'MarketingBarLink',

    components: {
        BaseLinkStatic,
        BaseLink,
        Icon,
    },

    props: {
        path: {
            type: String,
            required: true,
        },

        label: {
            type: String,
            required: true,
        },

        color: {
            type: String,
            required: true,
        },

        hasIcon: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        linkComponent() {
            return isHttpLink(this.path) ? BaseLinkStatic : BaseLink;
        },

        target() {
            return isHttpLink(this.path) ? '_blank' : null;
        },
    },

    beforeCreate() {
        this.ChevronRight = ChevronRight;
    },
};
</script>

<style lang="scss" scoped>
.marketing-bar-link {
    @apply flex items-center text-s leading-ui-18 font-book;

    .label {
        @apply underline text-s;
    }

    .icon {
        @apply ml-1;
    }
}
</style>
