<template>
    <PromotedSliderWrapper
        v-bind="propsToBind"
        :class="cssClass"
        @sponsored-loaded="onLoadedProducts($event)"
    />
</template>

<script>
import { RECO_BANNER_VIEW } from '@analytics-types/Events';

import { HOME_PAGE_PLACEMENT_PAGE_NAME } from '@configs/synerise';

import { getSponsoredSliderComponentVersion } from '@assets/pagebuilder';

import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';

import PromotedSliderWrapper from '@organisms/PromotedSlider/PromotedSliderWrapper';

export default {
    name: 'CmsSponsoredSliderWrapper',

    components: {
        PromotedSliderWrapper,
    },

    extends: CmsBaseComponent,

    provide() {
        return {
            sliderListOverride: this.componentVersion,
        };
    },

    inject: {
        componentLoaded: {
            default: () => () => {},
        },
    },

    props: getProps(PromotedSliderWrapper),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    heading: '',
                    placementPageName: HOME_PAGE_PLACEMENT_PAGE_NAME,
                },
            },
        };
    },

    computed: {
        componentVersion() {
            return `sponsored ${getSponsoredSliderComponentVersion()}`;
        },
    },

    beforeCreate() {
        this.HOME_PAGE_PLACEMENT_PAGE_NAME = HOME_PAGE_PLACEMENT_PAGE_NAME;
    },

    methods: {
        onLoadedProducts(count) {
            const position = Object.keys(this.componentsList).indexOf(this.internalId);

            if (position >= 0) {
                this.$analytics.emit(RECO_BANNER_VIEW, {
                    version: this.componentVersion,
                    position,
                    campaingName: this.heading,
                    offersCount: count,
                });
            }

            this.componentLoaded({
                count,
            });
        },
    },
};
</script>
