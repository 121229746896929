<template>
    <div>
        <pre>{{ propsToBind }}</pre>
    </div>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

export default {
    name: 'CmsListingSurveyConfig',

    extends: CmsBaseComponent,

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        heading: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        description: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        callToAction: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        surveyUrl: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    heading: '',
                    description: '',
                    callToAction: '',
                    surveyUrl: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            propsCustomFormConfig: {
                surveyUrl: {
                    isUrl: true,
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },
};
</script>
