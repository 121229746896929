<template>
    <ContainerContent class="marketing-usp-benefits">
        <ul class="benefits">
            <slot />
        </ul>
    </ContainerContent>
</template>

<script>
import ContainerContent from '@molecules/ContainerContent/ContainerContent';

export default {
    name: 'MarketingUSPBenefits',

    components: {
        ContainerContent,
    },
};
</script>

<style lang="scss" scoped>
.marketing-usp-benefits {
    @apply mb-6;

    .benefits {
        @apply flex w-full flex-wrap;
    }

    @screen md {
        @apply mb-7;

        .benefits {
            @apply flex-nowrap justify-between;
        }
    }

    @screen lg {
        @apply pt-3;
    }
}
</style>
