<template>
    <div>
        <pre>{{ propsToBind }}</pre>
    </div>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

export default {
    name: 'CmsTestNewTypes',

    extends: CmsBaseComponent,

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        colorTheme: {
            type: Object,
            default: () => ({
                light: '',
                dark: '',
            }),
        },

        // eslint-disable-next-line vue/no-unused-properties
        videoTheme: {
            type: Object,
            default: () => ({
                light: '',
                dark: '',
            }),
        },

        // eslint-disable-next-line vue/no-unused-properties
        url: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        imageTheme: {
            type: Object,
            default: () => ({
                light: '',
                dark: '',
            }),
        },

        // eslint-disable-next-line vue/no-unused-properties
        dateTime: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        mediaLinkTheme: {
            type: Object,
            default: () => ({
                light: {
                    type: '',
                    url: '',
                },
                dark: {
                    type: '',
                    url: '',
                },
            }),
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            propsCustomFormConfig: {
                colorTheme: {
                    hasColorTheme: true,
                    required: true,
                },

                videoTheme: {
                    hasVideoTheme: true,
                    required: true,
                },

                url: {
                    isUrl: true,
                    required: true,
                },

                imageTheme: {
                    hasImageTheme: true,
                    required: true,
                },

                dateTime: {
                    hasDateTimePicker: true,
                    required: true,
                },

                mediaLinkTheme: {
                    hasMediaLinkTheme: true,
                    required: true,
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },
};
</script>
