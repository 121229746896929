<script>
import CoreTextButton from '@core-components/atoms/TextButton/TextButton';

export default {
    name: 'TextButton',
    extends: CoreTextButton,
};
</script>

<style lang="scss" scoped>
@import '@core-components/atoms/TextButton/TextButton.scss';

.text-button {
    &:focus {
        @apply outline-none;
    }
}
</style>
