<template>
    <div class="marketing-promo-slider">
        <ContainerContent class="container">
            <div class="header-wrapper">
                <BaseHeading
                    v-if="sliderTitle"
                    :style="{ backgroundColor, color: textColor }"
                    theme="h3"
                    class="title title-wrapper"
                >
                    {{ sliderTitle }}
                </BaseHeading>
                <div v-if="isCounterVisible" :id="timerId" class="countdown-timer">
                    <span class="text">
                        {{ countdownText }}
                    </span>
                    <CountdownTimer
                        ref="timer"
                        :timer-id="timerId"
                        :timestamp="startDateCountdownFormat"
                        :is-days-in-hours="true"
                        :before-countdown-text="beforeCountdownText"
                        @hide-timer-section="hideTimerSection($event)"
                    />
                </div>
                <div v-if="showBeforeCountdownText" class="text countdown-text">
                    {{ beforeCountdownText }}
                </div>
            </div>
        </ContainerContent>

        <div class="slider">
            <slot />
        </div>

        <ContainerContent v-if="showAllCopy && showAllLink" class="container show-all-copy">
            <ButtonLarge :variant="BUTTON_VARIANT" :to="showAllLink">
                {{ showAllCopy }}
            </ButtonLarge>
        </ContainerContent>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import { tertiary, red1 } from '@theme/resources/variables/colors.json';

import SyncDateHelper from '@models/DateHelper/SyncDateHelper';

import { FORMAT_YYYYMMDD_HHMM } from '@types/DateFormat';

import Countdown from '@mixins/Countdown';

import BaseHeading from '@atoms/BaseHeading/BaseHeading';

import CountdownTimer from '@molecules/CountdownTimer/CountdownTimer';
import ContainerContent from '@molecules/ContainerContent/ContainerContent';

import { ButtonLarge, BUTTON_LARGE_VARIANTS } from '@modivo-ui/components/ButtonLarge/v1';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'MarketingPromoSlider',

    components: {
        CountdownTimer,
        BaseHeading,
        ContainerContent,
        ButtonLarge,
    },

    mixins: [Countdown],

    props: {
        sliderTitle: {
            type: String,
            default: '',
        },

        textColor: {
            type: String,
            default: tertiary,
        },

        showAllCopy: {
            type: String,
            default: '',
        },

        showAllLink: {
            type: String,
            default: '',
        },

        countdownText: {
            type: String,
            default: '',
        },

        timerId: {
            type: String,
            default: '',
        },

        date: {
            type: String,
            default: '',
        },

        beforeCountdownText: {
            type: String,
            default: '',
        },

        backgroundColor: {
            type: String,
            default: red1,
        },
    },

    data() {
        return {
            isCounterVisible: false,
        };
    },

    computed: {
        ...mapState(['isMobile']),
        ...mapConfigGetters(['timezone']),

        // eslint-disable-next-line vue/no-unused-properties
        startDate() {
            const dateHelper = new SyncDateHelper(this.timezone, FORMAT_YYYYMMDD_HHMM);

            return SyncDateHelper.toJSDate(dateHelper.createDateFromStringInTimezone(this.date));
        },

        showBeforeCountdownText() {
            return !this.isCounterVisible && this.beforeCountdownText;
        },
    },

    beforeCreate() {
        this.BUTTON_VARIANT = BUTTON_LARGE_VARIANTS.SECONDARY;
    },

    mounted() {
        this.isCounterVisible = this.timerId && this.date && this.beforeEvent;
    },

    methods: {
        hideTimerSection(element) {
            if (element === this.$refs.timer.$refs[this.timerId]) {
                this.isCounterVisible = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
$title-desktop-margin: 7px;

.marketing-promo-slider {
    @apply block;

    .title {
        @apply inline-flex items-center uppercase text-m leading-m justify-center px-2 py-1;
    }

    .header-wrapper {
        @apply text-center;
    }

    .text {
        @apply text-s leading-s text-gray1 mt-2p mb-1;
    }

    .countdown-timer {
        @apply flex justify-center items-center flex-col pb-4 text-gray1;

        &:deep() {
            .timer {
                @apply text-xl leading-xl;
            }

            .time-description {
                @apply text-xs leading-s uppercase pr-4;
            }

            [data-hours],
            [data-minutes] {
                &::after {
                    @apply px-14p;
                }
            }
        }
    }

    @screen lg {
        .text {
            @apply text-m leading-m mt-6p mr-3 whitespace-nowrap font-semibold self-start;

            &.countdown-text {
                @apply mr-0;
            }
        }

        .container {
            @apply block;
        }

        .title {
            @apply flex text-xl leading-xl justify-start;
        }

        .title-wrapper {
            margin-top: $title-desktop-margin;
        }

        .header-wrapper {
            @apply flex justify-between px-3;
        }

        .countdown-timer {
            @apply flex-row pb-0;
        }

        .slider {
            @apply mx-3;
        }

        .show-all-copy {
            @apply text-center;
        }
    }
}
</style>
