export default {
    props: {
        product: {
            type: Object,
            default: () => ({}),
        },

        isLinkDisabled: {
            type: Boolean,
            default: false,
        },

        chosenSize: {
            type: Object,
            default: null,
        },

        isLazy: {
            type: Boolean,
            default: true,
        },

        offerItems: {
            type: Array,
            default: () => [],
        },

        extendInfo: {
            type: Boolean,
            default: true,
        },

        hasWishListButton: {
            type: Boolean,
            default: true,
        },

        numberOfItems: {
            type: Number,
            default: 3,
        },

        showDiscount: {
            type: Boolean,
            default: true,
        },

        showDiscountValue: {
            type: Boolean,
            default: true,
        },

        wishlistButtonOnHover: {
            type: Boolean,
            default: true,
        },

        sizesInfo: {
            type: Boolean,
            default: false,
        },

        pictureSizes: {
            type: String,
            default: null,
        },

        openInNewTab: {
            type: Boolean,
            default: false,
        },

        isStylization: {
            type: Boolean,
            default: false,
        },

        attributionToken: {
            type: [String, null],
            default: null,
        },

        index: {
            type: Number,
            default: 0,
        },

        actionField: {
            type: String,
            default: '',
        },
    },
};
