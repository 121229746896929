<template>
    <LoyaltyClubDarkSection v-bind="propsToBind" />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import LoyaltyClubDarkSection from '@organisms/LoyaltyClubDarkSection/LoyaltyClubDarkSection';

export default {
    name: 'CmsLoyaltyClubDarkSection',

    components: {
        LoyaltyClubDarkSection,
    },

    extends: CmsBaseComponent,

    props: getProps(LoyaltyClubDarkSection),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    marginTop: '',
                    heading: '',
                    isSubscribed: false,
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            propsCustomFormConfig: {
                isSubscribed: {
                    disabled: true,
                },

                staticBlockContent: {
                    disabled: true,
                },
            },
        };
    },
};
</script>
