import { IS_OMNIBUS_STRICT_ENABLED } from '@localeConfig/keys';

import { getFirstOfferFromVariant } from '@assets/product-offers';
import { getPrice } from '@assets/product';
import { getProductBadgeConfig } from '@assets/product-badge-config';

export default {
    computed: {
        chosenOffer() {
            return this.getChosenOffer(this.chosenSize?.size);
        },

        productPrice() {
            const { chosenOffer, product } = this;

            if (!chosenOffer) {
                return product.price;
            }

            const isOmnibusStrictEnabled = this.$configProvider.getConfig(
                IS_OMNIBUS_STRICT_ENABLED
            );

            const {
                price,
                final_price: finalPrice,
                omnibus_price: omnibusPrice,
                discount: { amount: discountAmount },
                omnibus_discount: { amount: omnibusDiscountAmount },
            } = chosenOffer;

            const omnibusDiscountFormatted =
                omnibusDiscountAmount > 0 ? `${omnibusDiscountAmount}%` : null;

            const regularDiscountFormatted = discountAmount > 0 ? `${discountAmount}%` : null;

            const discountApplied = isOmnibusStrictEnabled
                ? omnibusDiscountFormatted
                : regularDiscountFormatted;

            return getPrice(
                price,
                finalPrice,
                omnibusPrice,
                discountApplied,
                regularDiscountFormatted,
                false
            );
        },

        productBadgeConfig() {
            const {
                productPrice,
                product: { productBadgeConfig: productBadges = [] },
            } = this;

            return getProductBadgeConfig({
                productBadges,
                productPrice,
                isOmnibusEnabled: this.$configProvider.getConfig(IS_OMNIBUS_STRICT_ENABLED),
                $t: this.$t.bind(this),
            });
        },
    },

    methods: {
        getChosenOffer(size) {
            if (!size) {
                return null;
            }

            return getFirstOfferFromVariant(this.product.variants[size]);
        },
    },
};
