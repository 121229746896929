<template>
    <div
        :class="{
            'wishlist-button-on-hover': wishlistButtonOnHover,
            'show-extend-info': extendInfo,
            'has-sizes-only': hasSizesOnly,
        }"
        class="product-card"
        @mouseenter="hover = true"
        @mouseleave="mouseLeaveProductCard()"
    >
        <LabelInformation
            v-if="product.isSponsored"
            :type="advertisementType"
            :company-account-id="product.companyAccountId"
            class="product-card-information"
        />
        <BaseLink
            :path="targetPath"
            :label="product.name"
            :title="product.name"
            :disabled="isLinkDisabled"
            :target="linkTarget"
            class="product-card-link"
            @click.exact.native="$emit('product-click', { product, mouseEvent: $event })"
            @click.ctrl.exact.native="$emit('product-click', { product, mouseEvent: $event })"
            @click.exact.native.middle="
                $emit('product-click', {
                    product,
                    mouseEvent: { ...$event, middleKey: true },
                })
            "
            @contextmenu.native="$emit('product-open-contextmenu', { product })"
        >
            <div class="picture-wrapper">
                <div class="add-to-wishlist-wrapper">
                    <ButtonIconToggle
                        v-if="hasWishListButton"
                        :variant="BUTTON_VARIANT"
                        :disabled="isLoading"
                        :toggled="isAdded"
                        class="add-to-wishlist"
                        @click.prevent.stop="
                            [
                                toggle(true),
                                $emit('wishlist-button-click', {
                                    product,
                                    isAdded,
                                }),
                            ]
                        "
                    />
                </div>

                <div class="picture-switcher">
                    <div v-if="productBadgesToShow.length > 0" class="badges">
                        <BadgeEdge
                            v-for="{ type, label, variant } in productBadgesToShow"
                            :key="type"
                            :variant="variant"
                        >
                            {{ label }}
                        </BadgeEdge>
                    </div>

                    <BadgePromoWide
                        v-if="promotionBadgeToShow"
                        :background-color="promotionBadgeToShow.backgroundColor"
                        :color="promotionBadgeToShow.color"
                        :label="promotionBadgeToShow.content"
                        :variant="BADGE_PROMO_WIDE_VARIANT"
                        class="promotion-badge"
                    />

                    <BasePicture
                        :src="picture.url"
                        :alt="picture.alt || ''"
                        :sources="picture.sources"
                        :width="product.pictureWidth || pictureWidth"
                        :height="product.pictureHeight || pictureHeight"
                        :is-background-filter-on="true"
                        :index="productSku"
                        :sizes="pictureSizes"
                        :is-lazy="isLazy"
                        :fetch-priority="fetchPriority"
                        class="picture"
                        @mouseenter="switchPicture = true"
                        @mouseleave="switchPicture = false"
                    />
                </div>
            </div>
            <div class="description-wrapper">
                <span class="product-card-name brand">{{ product.brandName }}</span>
                <span class="product-card-name name">{{ productName }}</span>

                <ProductPrice
                    :discount="productPrice.discount"
                    :price-regular="productPrice.regular.formatted"
                    :price-promotional="productPrice.promotional.formatted"
                    :price-omnibus="priceOmnibus"
                    :show-discount="showDiscount"
                    :is-minimal-offer="isMinimalOffer"
                    :is-with-marketing-action="isWithMarketingAction"
                    :show-discount-value="showDiscountValue"
                    :omnibus-variant="OMNIBUS_VARIANT"
                    class="price"
                    size="small"
                />
                <slot name="after-price" :product="product" />
            </div>
        </BaseLink>

        <div v-if="extendInfo && hover" class="extend-info">
            <ProductColorVariantsSlider
                v-if="offerItems.length"
                :id="`product-color-variants-slider-id-${productSku}`"
                :color-variants="offerItems"
                :current-product-url="product.url"
                :items-count="numberOfItems"
                class="product-color-variants-slider"
                @color-variant-hover="changeColorVariant($event)"
            />
            <div v-if="sizesInfo" class="product-sizes">
                {{ productSizes }}
            </div>
        </div>

        <slot name="action-tools" />
    </div>
</template>

<script>
import { DOUBLE_SIZE_TYPE } from '@configs/product-attribute-names';

import { ASYNC_AD_TECH_SERVICE_CONFIG } from '@async-services/adTech/meta';

import {
    IMAGE_TYPE_PRODUCT_CARD,
    IMAGE_TYPE_PRODUCT_CARD_STYLIZATION,
    PRODUCT_IMAGE_TYPES,
} from '@types/Image';
import { BADGE_EDGE_VARIANTS_MAP, TYPES_FOR_INTERNAL_OFFERS_ONLY } from '@types/ProductBadge';
import { OFFER } from '@types/AdvertisementModal';

import { getImage } from '@assets/images';
import { getProductVariantLabel, sortProductVariantsBySortOrder } from '@assets/product';
import { filterInternalOnlyBadges, getBadgesToShow } from '@assets/product-badge';
import { isLowestOfferInternal } from '@assets/product-offers';

import AddToWishlist from '@mixins/AddToWishlist';
import ChosenOfferWithPrice from '@mixins/ChosenOfferWithPrice';
import FetchPriority from '@mixins/FetchPriority';
import ProductCardProps from '@mixins/ProductCard';

import BaseLink from '@atoms/BaseLink/BaseLink';
import BasePicture from '@atoms/BasePicture/BasePicture';
import LabelInformation from '@atoms/LabelInformation/LabelInformation';

import ProductPrice from '@molecules/ProductPrice/ProductPrice';

import { OMNIBUS_VARIANTS } from '@modivo-ui/components/Omnibus/v1';
import { BadgePromoWide, BADGE_PROMO_WIDE_VARIANTS } from '@modivo-ui/components/BadgePromoWide/v1';
import { BadgeEdge } from '@modivo-ui/components/BadgeEdge/v1';

import {
    ButtonIconToggle,
    BUTTON_ICON_TOGGLE_VARIANTS,
} from '@modivo-ui/components/ButtonIconToggle/v1';

const {
    NAME: AD_TECH_SERVICE_NAME,
    METHODS: AD_TECH_SERVICE_METHODS,
} = ASYNC_AD_TECH_SERVICE_CONFIG;

export default {
    name: 'ProductCard',

    components: {
        BadgeEdge,
        BaseLink,
        BasePicture,
        ButtonIconToggle,
        BadgePromoWide,
        ProductPrice,
        LabelInformation,
        ProductColorVariantsSlider: () => ({
            component: import(
                /* webpackChunkName: "product-color-variants-slider" */
                '@organisms/ProductColorVariantsSlider/ProductColorVariantsSlider'
            ),
        }),
    },

    mixins: [ProductCardProps, AddToWishlist, ChosenOfferWithPrice, FetchPriority],

    data() {
        return {
            hover: false,
            switchPicture: false,
            wasChangedColorVariant: false,
        };
    },

    computed: {
        pictureWidth() {
            return this.pictureSize('width');
        },

        pictureHeight() {
            return this.pictureSize('height');
        },

        productName() {
            return this.product.shortName || this.product.nameDisplay;
        },

        picture() {
            if (
                this.switchPicture &&
                this.secondaryPicture &&
                Object.keys(this.secondaryPicture).length
            ) {
                return this.secondaryPicture;
            }

            return this.primaryPicture;
        },

        priceOmnibus() {
            return this.productPrice.omnibus?.formatted;
        },

        isWithMarketingAction() {
            return !this.isMinimalOffer && this.product.hasPromoSticker;
        },

        imageType() {
            return this.isStylization
                ? IMAGE_TYPE_PRODUCT_CARD_STYLIZATION
                : IMAGE_TYPE_PRODUCT_CARD;
        },

        primaryPicture() {
            const {
                imageType,
                product: { primaryPicture, images = [] },
            } = this;

            if (primaryPicture) {
                return {
                    ...primaryPicture,
                    url: primaryPicture.src,
                };
            }

            return getImage(images, imageType);
        },

        secondaryPicture() {
            const {
                imageType,
                product: { secondaryPicture, images = [] },
            } = this;

            if (secondaryPicture) {
                return {
                    ...secondaryPicture,
                    url: secondaryPicture.src,
                };
            }

            if (images.length > 1 && images[1][imageType]) {
                return images[1][imageType];
            }

            return null;
        },

        productSizes() {
            const maxDisplaySizes = 5;

            const {
                product: {
                    family: { variant_dimension: variantDimension = null },
                },
            } = this;

            const sizesArray = Object.values(this.product.variants)
                .filter(({ stock_quantity }) => stock_quantity > 0)
                .sort(sortProductVariantsBySortOrder)
                .map(variant =>
                    getProductVariantLabel(variant, DOUBLE_SIZE_TYPE, variantDimension)
                );

            const sizes =
                sizesArray.length > maxDisplaySizes
                    ? [this.$t('Available in many sizes')]
                    : sizesArray;

            const separator = ' \u00B7 ';

            return sizes.join(separator);
        },

        hasSizesOnly() {
            return this.offerItems.length === 0 && this.sizesInfo;
        },

        linkTarget() {
            return this.openInNewTab ? '_blank' : null;
        },

        isMinimalOffer() {
            return this.productPrice.isMinimal;
        },

        badgesToShow() {
            const { productBadgeConfig, isMinimalOffer, isChosenOfferInternal } = this;

            if (!productBadgeConfig?.length) {
                return [];
            }

            const badges = filterInternalOnlyBadges(
                productBadgeConfig,
                isChosenOfferInternal && !isMinimalOffer
            );

            return badges || [];
        },

        productBadgesToShow() {
            const badges = this.badgesToShow.filter(
                badgeToShow => !TYPES_FOR_INTERNAL_OFFERS_ONLY.includes(badgeToShow.type)
            );

            return (getBadgesToShow(badges) || []).map(({ type, content: label }) => ({
                variant: BADGE_EDGE_VARIANTS_MAP[type],
                label,
                type,
            }));
        },

        promotionBadgeToShow() {
            const [badge] = this.badgesToShow.filter(badgeToShow =>
                TYPES_FOR_INTERNAL_OFFERS_ONLY.includes(badgeToShow.type)
            );

            return badge || null;
        },

        productSku() {
            return this.product.sku;
        },

        isChosenOfferInternal() {
            if (this.chosenSize) {
                return this.chosenSize.isInternal ?? false;
            }

            const { variants = {} } = this.product;

            return variants ? isLowestOfferInternal(variants) : false;
        },

        targetPath() {
            const { url } = this.product;

            if (!this.attributionToken) {
                return url;
            }

            return `${url}?token=${this.attributionToken}`;
        },
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                if (this.product.isSponsored) {
                    this.product.adTechEvents.onLoadBeacons.forEach(beacon => {
                        this.$asyncServices.use(
                            AD_TECH_SERVICE_NAME,
                            AD_TECH_SERVICE_METHODS.NOTIFY_BEACON,
                            { beacon }
                        );
                    });
                }
            },
        },
    },

    beforeCreate() {
        this.BUTTON_VARIANT = BUTTON_ICON_TOGGLE_VARIANTS.FAVOURITE;
        this.advertisementType = OFFER;
        this.OMNIBUS_VARIANT = OMNIBUS_VARIANTS.S;
        this.BADGE_PROMO_WIDE_VARIANT = BADGE_PROMO_WIDE_VARIANTS.S;
    },

    methods: {
        pictureSize(size) {
            return PRODUCT_IMAGE_TYPES[this.imageType][size];
        },

        changeColorVariant(variant) {
            this.$emit('color-variant-hover', variant);

            this.wasChangedColorVariant = true;
        },

        mouseLeaveProductCard() {
            this.hover = false;

            if (this.wasChangedColorVariant) {
                this.$emit('color-variant-hover', 0);

                this.wasChangedColorVariant = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.product-card {
    @apply relative flex flex-nowrap flex-col items-center w-full mb-2 pb-3;
    max-width: theme('customVariables.productImageWidth.product');

    .product-card-name {
        @apply truncate text-center w-full min-h-l;

        &:hover {
            @apply no-underline;
        }
    }

    .brand {
        @apply font-semibold text-s mt-1;
    }

    .name {
        @apply text-gray text-s;
    }

    .product {
        @apply text-gray2;
    }

    .picture-switcher {
        @apply w-full relative;
    }

    .badges {
        @apply absolute left-ui-0 z-1 bottom-[26px];
        @apply flex flex-col items-start;
        @apply gap-y-ui-1;
    }

    .extend-info {
        @apply w-full hidden;
    }

    .add-to-wishlist {
        @apply absolute top-0 right-0 z-2;
    }

    .product-card-link {
        @apply w-full;

        &:hover {
            @apply no-underline;
        }
    }

    .picture-wrapper {
        @apply relative w-full;
    }

    .description-wrapper {
        @apply px-4 flex flex-col w-full items-center;
    }

    .add-to-wishlist-wrapper {
        @apply relative;
    }

    .product-card-information {
        @apply absolute -top-24 left-ui-4;
    }

    .price {
        @apply justify-center w-full text-s;

        &:deep(.line) {
            @apply justify-center;
        }
    }

    .product-card-badge {
        @apply px-2 text-tertiary text-s uppercase;
    }

    &:deep(.promotion-badge) {
        @apply w-full flex justify-center items-center;
        @apply absolute left-0 z-1 w-full bottom-0;
    }

    @screen lg {
        .picture-wrapper {
            @apply z-1;
        }

        .product-card-information {
            @apply left-0;
        }

        .badges {
            @apply bottom-30;
        }

        .description-wrapper {
            @apply px-20p pb-20p;
        }

        &.wishlist-button-on-hover {
            .add-to-wishlist:not(.toggled) {
                @apply hidden;
            }

            &:hover {
                .add-to-wishlist {
                    @apply inline-flex;
                }
            }
        }

        .product-card-link,
        .product-card-information {
            @apply z-1;
        }

        .price {
            @apply h-8;
        }

        .extend-info {
            @apply absolute flex flex-col px-20p z-1;
            top: 100%;

            .product-color-variants-slider {
                @apply mb-2;
            }

            .product-sizes {
                @apply h-7 mb-2 truncate whitespace-normal;
                @apply text-s font-book text-gray2 text-center;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }

        &:hover {
            @apply z-2;

            &::before {
                content: '';
                @apply absolute border-1 border-border bg-light top-0 left-0 z-1 h-full w-full;
            }
        }

        &.show-extend-info {
            &:hover {
                &::before {
                    height: calc(100% + 114px);
                }
            }

            .description-wrapper {
                @apply pb-0;
            }
        }

        &.has-sizes-only {
            &:hover {
                &::before {
                    height: calc(100% + 56px);
                }
            }
        }
    }
}
</style>
