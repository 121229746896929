<template>
    <div class="marketing-blog-content">
        <div class="post-meta">
            <div class="badge">{{ badge }}</div>
            <div class="date">{{ date }}</div>
        </div>

        <BaseHeading class="title">
            {{ title }}
        </BaseHeading>
        <BaseParagraph class="text">
            {{ displayedText }}
        </BaseParagraph>

        <BaseLinkStatic class="link" :path="link" :label="$t('Read entire article')" />
    </div>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';

const TEXT_CHARS_LIMIT_LONG = 340;
const TEXT_CHARS_LIMIT_SHORT = 140;

export default {
    name: 'MarketingBlogContent',
    components: {
        BaseParagraph,
        BaseHeading,
        BaseLinkStatic,
    },

    props: {
        badge: {
            type: String,
            required: true,
        },

        date: {
            type: String,
            required: true,
        },

        title: {
            type: String,
            required: true,
        },

        text: {
            type: String,
            required: true,
        },

        link: {
            type: String,
            required: true,
        },

        hasSlider: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        displayedText() {
            const limit = this.hasSlider ? TEXT_CHARS_LIMIT_SHORT : TEXT_CHARS_LIMIT_LONG;

            return this.text.length > limit ? `${this.text.slice(0, limit)}...` : this.text;
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-blog-content {
    .post-meta {
        @apply flex my-3 text-left;

        .badge {
            @apply uppercase font-semibold text-primary mr-3;
        }

        .date {
            @apply text-dark;
        }
    }

    .title {
        @apply text-l leading-l;
    }

    .text {
        @apply my-4 text-dark text-m leading-m;
    }

    .link {
        @apply no-underline border-b-2 border-tertiary font-semibold text-m leading-m;
    }

    @screen md {
        .post-meta {
            @apply mt-0;
        }

        .title {
            @apply text-xl leading-xl;
        }

        .text {
            @apply my-5;
        }
    }

    @screen container {
        .title {
            @apply text-xxl leading-xxxl;
        }
    }
}
</style>
