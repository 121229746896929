<template>
    <ContainerContent class="marketplace-cooperation-with-modivo">
        <BaseHeading class="heading" tag="h2" theme="h3">
            {{ heading }}
        </BaseHeading>

        <div class="subheading">
            <slot name="subheading" />
        </div>

        <div class="text">
            <slot name="text" />
        </div>

        <BaseButton class="button" :theme="THEME_PRIMARY" @click="openJoinModivoMarketplaceModal()">
            {{ $t('Join us') }}
        </BaseButton>
    </ContainerContent>
</template>

<script>
import { THEME_PRIMARY } from '@types/Button';

import BaseButton from '@atoms/BaseButton/BaseButton';
import BaseHeading from '@atoms/BaseHeading/BaseHeading';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';

export default {
    name: 'MarketplaceCooperationWithModivo',

    components: {
        BaseButton,
        BaseHeading,
        ContainerContent,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },
    },

    beforeCreate() {
        this.THEME_PRIMARY = THEME_PRIMARY;
    },

    methods: {
        openJoinModivoMarketplaceModal() {
            // @todo PM-324 - spięcie z modalem
        },
    },
};
</script>

<style lang="scss" scoped>
.marketplace-cooperation-with-modivo {
    @apply bg-gray5 mt-7 py-5 flex flex-col items-center;

    .heading {
        @apply text-center;
    }

    .subheading {
        @apply mt-4;

        &:deep() {
            .paragraph {
                @apply text-r leading-r text-tertiary text-center;
            }
        }
    }

    .text {
        &:deep() {
            .paragraph {
                @apply mt-2 text-s leading-s text-tertiary text-center;
            }
        }
    }

    .button {
        @apply mt-4;
    }

    @screen mobile-only {
        .button {
            @apply w-full justify-center;
        }
    }

    @screen lg {
        @apply mt-9;

        .subheading {
            &:deep() {
                .paragraph {
                    @apply text-m leading-m;
                }
            }
        }

        .text {
            &:deep() {
                .paragraph {
                    @apply text-r leading-r;
                }
            }
        }

        .heading {
            @apply text-xl leading-xl;
        }
    }
}
</style>
