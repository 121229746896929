<template>
    <div class="marketing-hero" :class="{ 'is-reversed': isReversed }">
        <div class="picture-wrapper">
            <BaseLinkStatic :path="buttonLink" class="picture">
                <BasePicture
                    :width="image.width"
                    :height="image.height"
                    :src="image.url"
                    :alt="image.alt"
                    :sources="image.sources"
                    :is-lazy="true"
                />
            </BaseLinkStatic>
            <MarketingHeroTimer
                :timer-id="timerId"
                :date="date"
                :theme="theme"
                :custom-background="customBackground"
                :event-title="eventTitle"
                :event-dates="eventDates"
                :event-details="eventDetails"
                :event-address="eventAddress"
            />
        </div>
        <MarketingHeroContent
            class="content"
            :heading="heading"
            :description="description"
            :button-label="buttonLabel"
            :button-link="buttonLink"
        />
    </div>
</template>

<script>
import { IMAGE_TYPE_MARKETING_HERO_570w_600h } from '@types/Image';

import { THEMES } from '@types/MarketingBanner';

import { checkIfExistsInValuesMap } from '@assets/props';

import BasePicture from '@atoms/BasePicture/BasePicture';
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';

import MarketingHeroContent from '@organisms/MarketingHeroContent/MarketingHeroContent';
import MarketingHeroTimer from '@organisms/MarketingHeroBanner/MarketingHeroTimer';

export default {
    name: 'MarketingHero',

    components: {
        MarketingHeroContent,
        BasePicture,
        BaseLinkStatic,
        MarketingHeroTimer,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        description: {
            type: String,
            required: true,
        },

        buttonLink: {
            type: String,
            required: true,
        },

        buttonLabel: {
            type: String,
            required: true,
        },

        imageUrl: {
            type: String,
            required: true,
        },

        isReversed: {
            type: Boolean,
            default: false,
        },

        timerId: {
            type: String,
            default: '',
        },

        date: {
            type: String,
            default: '',
        },

        theme: {
            type: String,
            default: THEMES.THEME_LIGHT,
            validator: checkIfExistsInValuesMap(THEMES),
        },

        customBackground: {
            type: String,
            default: '',
        },

        eventTitle: {
            type: String,
            default: '',
        },

        eventDates: {
            type: String,
            default: '',
        },

        eventDetails: {
            type: String,
            default: '',
        },

        eventAddress: {
            type: String,
            default: '',
        },
    },

    computed: {
        image() {
            return this.$imaginator.getImage(
                this.imageUrl,
                'marketing-hero',
                this.heading,
                IMAGE_TYPE_MARKETING_HERO_570w_600h
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-hero {
    @apply flex flex-col mx-auto w-full;
    max-width: theme('customVariables.marketingHero.maxWidth');

    .content {
        @apply px-3 pt-3;
    }

    .picture-wrapper {
        @apply relative;
    }

    @screen md {
        @apply mx-0;

        &.is-reversed {
            .picture-wrapper {
                @apply order-2;
            }

            .content {
                @apply pt-0 px-0 pb-5;
            }
        }

        .content {
            @apply p-0 pt-5;
        }
    }

    @screen lg {
        &.is-reversed {
            .content {
                @apply pb-5;
            }
        }
    }
}
</style>
