<template>
    <section class="marketing-category-slider">
        <ContainerContent class="marketing-category-slider-content">
            <MarketingCategorySliderHeading class="slider-heading" :link="link" :title="title" />

            <MarketingCategorySliderBox
                :link="link"
                :title="title"
                :image-url="imageUrl"
                class="slider-box"
            />

            <div v-if="customCampaignId || productSkus" class="slider-wrapper">
                <RecommendedProductsSimpleSliderWrapper
                    v-if="customCampaignId"
                    :recommendation-type="TYPE_CUSTOM"
                    :custom-campaign-id="customCampaignId"
                    :items-count="ITEMS_COUNT"
                />

                <CustomProductsSimpleSliderWrapper
                    v-else-if="productSkus"
                    :items-count="ITEMS_COUNT"
                    :product-skus="productSkus"
                />
            </div>
        </ContainerContent>
    </section>
</template>

<script>
import { TYPE_CUSTOM } from '@types/Synerise';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';

import MarketingCategorySliderBox from '@organisms/MarketingCategorySlider/MarketingCategorySliderBox';
import MarketingCategorySliderHeading from '@organisms/MarketingCategorySlider/MarketingCategorySliderHeading';
import CustomProductsSimpleSliderWrapper from '@organisms/CustomProductsSimpleSliderWrapper/CustomProductsSimpleSliderWrapper';
import RecommendedProductsSimpleSliderWrapper from '@organisms/RecommendedProductsSimpleSliderWrapper/RecommendedProductsSimpleSliderWrapper';

export default {
    name: 'MarketingCategorySlider',

    components: {
        MarketingCategorySliderHeading,
        MarketingCategorySliderBox,
        CustomProductsSimpleSliderWrapper,
        RecommendedProductsSimpleSliderWrapper,
        ContainerContent,
    },

    props: {
        imageUrl: {
            type: String,
            required: true,
        },

        link: {
            type: String,
            required: true,
        },

        title: {
            type: String,
            required: true,
        },

        customCampaignId: {
            type: String,
            default: '',
        },

        productSkus: {
            type: String,
            default: '',
        },
    },

    beforeCreate() {
        this.TYPE_CUSTOM = TYPE_CUSTOM;
        this.ITEMS_COUNT = 4;
    },
};
</script>

<style lang="scss" scoped>
$product-hover-padding: #{theme('customVariables.productsSlider.productHoverContainer')};
$category-image-width: 318px;

.marketing-category-slider {
    .slider-box {
        @apply hidden;
    }

    .slider-wrapper {
        @apply w-full mt-2;
    }

    .marketing-category-slider-content {
        @apply px-0;
    }

    .slider-heading {
        @apply px-3 w-full flex justify-between items-center;
    }

    @screen lg {
        .slider-box {
            @apply block w-1/4;
            max-width: $category-image-width;
        }

        .slider-heading {
            @apply hidden;
        }

        .slider-wrapper {
            @apply w-3/4;
            margin-top: -$product-hover-padding;
        }

        .marketing-category-slider-content {
            @apply px-5;
            padding-top: $product-hover-padding;
        }

        &:deep() {
            .products-simple-slider {
                @apply self-start pl-6p;
            }
        }
    }
}
</style>
