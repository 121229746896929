<template>
    <Observer
        v-if="displayContainer"
        :options="OBSERVER_OPTIONS"
        :observe-once="true"
        class="custom-products-wrapper"
        :class="{ 'is-loading': !isProductsLoaded }"
        @intersect="displayContent($event)"
    >
        <template v-if="isDisplayed">
            <Loader v-if="!isProductsLoaded" height="100%" />
            <CustomProductsDataProvider
                #default="{ products }"
                :product-skus="productSkus"
                :with-stocks="withStocks"
                @loaded="isProductsLoaded = true"
                @error="handleError()"
            >
                <slot v-if="isProductsLoaded" :products="products" />
            </CustomProductsDataProvider>
        </template>
    </Observer>
</template>

<script>
import Loader from '@atoms/Loader/Loader';
import Observer from '@atoms/Observer/Observer';

const OBSERVER_OPTIONS = {
    root: null,
    threshold: 0,
    rootMargin: '0px 0px 200px 0px',
};

export default {
    name: 'CustomProductsWrapper',

    components: {
        Observer,
        Loader,
        CustomProductsDataProvider: () => ({
            component: import(
                /* webpackChunkName: "custom-products-data-provider" */
                '@molecules/CustomProductsDataProvider/CustomProductsDataProvider'
            ),
        }),
    },

    props: {
        productSkus: {
            type: Array,
            default: () => [],
        },

        withStocks: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isDisplayed: false,
            isProductsLoaded: false,
            displayContainer: true,
            OBSERVER_OPTIONS,
        };
    },

    methods: {
        displayContent(intersect) {
            if (intersect) {
                this.isDisplayed = true;
            }
        },

        handleError() {
            this.displayContainer = false;
            this.isDisplayed = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.custom-products-wrapper {
    @apply relative;
}
</style>
