<template>
    <RecentlyViewedProductsSimpleSliderWrapper v-bind="propsToBind" :class="cssClass" />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import RecentlyViewedProductsSimpleSliderWrapper from '@organisms/RecentlyViewedProductsSimpleSliderWrapper/RecentlyViewedProductsSimpleSliderWrapper';
import { ITEMS_COUNT } from '@configs/simple-slider';

export default {
    name: 'CmsRecentlyViewedProductsSimpleSliderWrapper',

    components: {
        RecentlyViewedProductsSimpleSliderWrapper,
    },

    extends: CmsBaseComponent,

    props: getProps(RecentlyViewedProductsSimpleSliderWrapper),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    heading: '',
                },
            },
            // eslint-disable-next-line vue/no-unused-properties
            options: {
                itemsCount: Object.values(ITEMS_COUNT),
            },
        };
    },
};
</script>
