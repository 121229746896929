<template>
    <ContainerContent class="faq-list">
        <BaseHeading class="heading" tag="h2" theme="h3">
            {{ heading }}
        </BaseHeading>

        <Accordion class="list">
            <slot />
        </Accordion>

        <div class="footer">
            <slot name="footer" />
        </div>
    </ContainerContent>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';

import Accordion from '@molecules/Accordion/Accordion';
import ContainerContent from '@molecules/ContainerContent/ContainerContent';

export default {
    name: 'FAQList',

    components: {
        BaseHeading,
        Accordion,
        ContainerContent,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.faq-list {
    @apply flex flex-col items-center mt-7;

    max-width: theme('customVariables.faqList.containerMaxWidth');

    .heading {
        @apply text-center;
    }

    .list {
        @apply w-full;
    }

    .footer {
        &:deep() {
            .paragraph {
                @apply text-tertiary text-center;
            }

            .text-link {
                @apply underline;
            }
        }
    }

    @screen lg {
        @apply mt-9;

        .heading {
            @apply text-xl leading-xl;
        }
    }
}
</style>
