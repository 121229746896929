<template>
    <div class="marketing-homepage-section-category">
        <component :is="componentTag" :path="link" class="image-link">
            <BasePicture
                :width="image.width"
                :height="image.height"
                :src="image.url"
                :alt="image.alt"
                :sources="image.sources"
                :sources-with-media="sourcesWithMedia"
            />
        </component>
        <ButtonLarge :variant="BUTTON_VARIANT" :to="link" class="button">
            {{ buttonText }}
        </ButtonLarge>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import { isHttpLink } from '@assets/link';

import {
    DEFAULT_IMAGE_FORMAT,
    SOURCE_IMAGE_FORMATS,
    IMAGE_TYPE_HOMEPAGE_356w_400h,
    IMAGE_TYPE_HOMEPAGE_640w_360h,
} from '@types/Image';

import BasePicture from '@atoms/BasePicture/BasePicture';
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';
import BaseLink from '@atoms/BaseLink/BaseLink';

import { ButtonLarge, BUTTON_LARGE_VARIANTS } from '@modivo-ui/components/ButtonLarge/v1';

const SOURCES_MAP = {
    640: '(max-width: 1023px)',
    356: '(min-width: 1024px)',
};

export default {
    name: 'MarketingHomepageSectionCategory',

    components: {
        BasePicture,
        ButtonLarge,
        BaseLinkStatic,
        BaseLink,
    },

    props: {
        imageUrl356x400: {
            type: String,
            required: true,
        },

        imageUrl640x360: {
            type: String,
            required: true,
        },

        buttonText: {
            type: String,
            required: true,
        },

        link: {
            type: String,
            required: true,
        },
    },

    computed: {
        ...mapState(['isMobile']),

        image() {
            const image356x400 = this.$imaginator.getImage(
                this.imageUrl356x400,
                'marketing-homepage',
                this.buttonText,
                IMAGE_TYPE_HOMEPAGE_356w_400h,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS,
                [IMAGE_TYPE_HOMEPAGE_356w_400h]
            );

            const image640x360 = this.$imaginator.getImage(
                this.imageUrl640x360,
                'marketing-homepage',
                this.buttonText,
                IMAGE_TYPE_HOMEPAGE_640w_360h,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS,
                [IMAGE_TYPE_HOMEPAGE_640w_360h]
            );

            Object.entries(image640x360.sources).forEach(([type, source]) => {
                image356x400.sources[type] = image356x400.sources[type].concat(source);
            });

            return image356x400;
        },

        sourcesWithMedia() {
            const { sources } = this.image;
            const result = [];

            Object.keys(sources).forEach(type => {
                sources[type].forEach(source => {
                    result.push({
                        type,
                        srcset: source.src,
                        media: this.getMediaForSource(source),
                    });
                });
            });

            return result;
        },

        componentTag() {
            return isHttpLink(this.url) ? BaseLinkStatic : BaseLink;
        },
    },

    beforeCreate() {
        this.BUTTON_VARIANT = BUTTON_LARGE_VARIANTS.PRIMARY_ALTERNATIVE;
    },

    methods: {
        getMediaForSource(source) {
            if (!SOURCES_MAP[source.width]) {
                return '';
            }

            return SOURCES_MAP[source.width];
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-homepage-section-category {
    @apply relative w-ui-percent-100 mt-ui-6 bg-ui-container-skeleton-default;
    @apply flex items-center justify-center;

    max-width: theme('customVariables.homepage.imageMobileMaxWidth');

    .button {
        @apply absolute bottom-[30px] w-ui-percent-50;
    }

    .image-link {
        @apply w-ui-percent-100;
    }

    @screen lg {
        @apply ml-ui-7 mt-ui-0;
        max-width: theme('customVariables.homepage.imageDesktopMaxWidth');
    }
}
</style>
