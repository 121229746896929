<template>
    <ButtonLarge :to="url" :variant="BUTTON_VARIANT" class="marketing-popular-category">
        {{ label }}
    </ButtonLarge>
</template>

<script>
import { ButtonLarge, BUTTON_LARGE_VARIANTS } from '@modivo-ui/components/ButtonLarge/v1';

export default {
    name: 'MarketingPopularCategory',

    components: {
        ButtonLarge,
    },

    props: {
        url: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
    },

    beforeCreate() {
        this.BUTTON_VARIANT = BUTTON_LARGE_VARIANTS.SECONDARY;
    },
};
</script>

<style lang="scss" scoped>
.marketing-popular-category {
    @apply justify-center whitespace-nowrap;
}
</style>
