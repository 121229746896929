<template>
    <section class="marketing-multi-link-banner-large" :class="theme">
        <ContainerContent class="banner-container">
            <div class="wrapper">
                <BasePicture
                    :width="image.width"
                    :height="image.height"
                    :src="image.url"
                    :alt="image.alt"
                    :sources="image.sources"
                    :sources-with-media="sourcesWithMedia"
                    :is-lazy="true"
                />
                <div class="details">
                    <div class="details-container">
                        <BaseHeading :tag="headingTag" class="heading">
                            {{ heading }}
                        </BaseHeading>
                        <slot name="links" />
                    </div>
                </div>
            </div>
        </ContainerContent>
    </section>
</template>

<script>
import { checkIfExistsInValuesMap } from '@assets/props';

import {
    DEFAULT_IMAGE_FORMAT,
    IMAGE_TYPE_MARKETING_BANNER_1152w_450h,
    IMAGE_TYPE_MARKETING_BANNER_1532w_450h,
    IMAGE_TYPE_MARKETING_BANNER_450w_450h,
    IMAGE_TYPE_MARKETING_BANNER_767w_450h,
    MARKETING_BANNER_IMAGE_TYPES as IMAGE_TYPES,
    SOURCE_IMAGE_FORMATS,
} from '@types/Image';
import { THEMES, HEADING_TAGS } from '@types/MarketingBanner';

import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BasePicture from '@atoms/BasePicture/BasePicture';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';

export default {
    name: 'MarketingMultiLinkBannerLarge',

    components: {
        BasePicture,
        BaseHeading,
        ContainerContent,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        imageUrl450x450: {
            type: String,
            required: true,
        },

        imageUrl767x450: {
            type: String,
            required: true,
        },

        imageUrl1532x450: {
            type: String,
            required: true,
        },

        theme: {
            type: String,
            default: THEMES.THEME_LIGHT,
            validator: checkIfExistsInValuesMap(THEMES),
        },

        headingTag: {
            type: String,
            default: HEADING_TAGS.SPAN,
            validator: checkIfExistsInValuesMap(HEADING_TAGS),
        },
    },

    computed: {
        image() {
            const image450x450 = this.$imaginator.getImage(
                this.imageUrl450x450,
                'marketing-banner',
                this.heading,
                IMAGE_TYPES.marketing_banner_450w_450h.name,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS,
                [IMAGE_TYPE_MARKETING_BANNER_450w_450h]
            );

            const image767x450 = this.$imaginator.getImage(
                this.imageUrl767x450,
                'marketing-banner',
                this.heading,
                IMAGE_TYPES.marketing_banner_767w_450h.name,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS,
                [IMAGE_TYPE_MARKETING_BANNER_767w_450h]
            );

            const result = this.$imaginator.getImage(
                this.imageUrl1532x450,
                'marketing-banner',
                this.heading,
                IMAGE_TYPES.marketing_banner_1532w_450h.name,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS,
                [IMAGE_TYPE_MARKETING_BANNER_1532w_450h, IMAGE_TYPE_MARKETING_BANNER_1152w_450h]
            );

            [image767x450, image450x450].forEach(image => {
                Object.entries(image.sources).forEach(([type, source]) => {
                    result.sources[type] = result.sources[type].concat(source);
                });
            });

            return result;
        },

        sourcesWithMedia() {
            const { sources } = this.image;
            const result = [];

            Object.keys(sources).forEach(type => {
                sources[type].forEach(source => {
                    result.push({
                        type,
                        srcset: source.src,
                        media: this.getMediaForSource(source),
                    });
                });
            });

            return result;
        },
    },

    methods: {
        getMediaForSource(source) {
            if (source.width === 450) {
                return '(max-width: 450px)';
            }

            if (source.width === 767) {
                return '(min-width: 451px)';
            }

            if (source.width === 1152) {
                return '(min-width: 768px)';
            }

            if (source.width === 1532) {
                return '(min-width: 1153px)';
            }

            return '';
        },
    },
};
</script>

<style lang="scss" scoped>
$banner-height: 450px;
$banner-dark-background-color: rgba(34, 34, 34, 0.5);
$banner-light-background-color: rgba($tailwindcss-colors-light, 0.5);

.marketing-multi-link-banner-large {
    &.light {
        .details-container {
            background-color: $banner-light-background-color;
        }
    }

    &.dark {
        .details-container {
            background-color: $banner-dark-background-color;
        }

        .heading {
            @apply text-light;
        }

        &:deep() {
            .marketing-multi-link-banner-link {
                @apply text-light;
            }
        }
    }

    .banner-container {
        @apply px-0;
    }

    .wrapper {
        @apply flex w-full relative overflow-hidden;
        height: $banner-height;
    }

    &:deep(.base-picture) {
        .image {
            @apply max-w-none max-h-none absolute left-1/2;
            transform: translateX(-50%);
        }
    }

    .details {
        @apply absolute flex flex-col justify-end w-full h-full;
    }

    .details-container {
        @apply flex flex-col justify-center items-start w-full p-3;
    }

    .heading {
        @apply text-l leading-l;
    }

    &:deep() {
        .links-content {
            @apply justify-start mt-0;
        }
    }

    @screen md {
        .banner-container {
            @apply px-3;
        }

        &:deep(.base-picture) {
            .image {
                @apply left-auto right-0 transform-none;
            }
        }

        .details {
            @apply flex-row-reverse items-center;
        }

        .details-container {
            @apply top-0 h-full w-1/3;
        }

        .heading {
            @apply text-xl leading-xl;
        }
    }

    @screen lg {
        .banner-container {
            @apply px-5;
        }

        .details-container {
            @apply p-4;
        }

        .heading {
            @apply text-xxl leading-xxxl;
        }

        &:deep() {
            .links-content {
                @apply mt-3;
            }
        }
    }
}
</style>
