<template>
    <MarketplaceSteps v-bind="propsToBind" :class="cssClass">
        <template v-if="isSlotWithContent(slotFirstStep)" #firstStep>
            <PageBuilderInterpreter :components-json="slotFirstStep" tag="span" />
        </template>
        <template v-if="isSlotWithContent(slotSecondStep)" #secondStep>
            <PageBuilderInterpreter :components-json="slotSecondStep" tag="span" />
        </template>
        <template v-if="isSlotWithContent(slotThirdStep)" #thirdStep>
            <PageBuilderInterpreter :components-json="slotThirdStep" tag="span" />
        </template>
    </MarketplaceSteps>
</template>

<script>
import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';

import MarketplaceSteps from '@organisms/MarketplaceSteps/MarketplaceSteps';

export default {
    name: 'CmsMarketplaceSteps',

    components: { PageBuilderInterpreter, MarketplaceSteps },

    extends: CmsBaseComponent,

    props: {
        slotFirstStep: {
            type: String,
            default: '',
        },

        slotSecondStep: {
            type: String,
            default: '',
        },

        slotThirdStep: {
            type: String,
            default: '',
        },

        ...getProps(MarketplaceSteps),
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    heading: '',
                    termsUrl: '',
                },

                slots: {
                    firstStep: '',
                    secondStep: '',
                    thirdStep: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                staticClass: false,
            },
        };
    },

    methods: {
        isSlotWithContent(slot) {
            return !!slot && !['[]', '""'].includes(slot);
        },
    },
};
</script>
