<template>
    <MarketingMainCategoryText v-bind="propsToBind" :class="cssClass" />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import MarketingMainCategoryText from '@organisms/MarketingMainCategoryText/MarketingMainCategoryText';

export default {
    name: 'CmsMarketingMainCategoryText',

    components: {
        MarketingMainCategoryText,
    },

    extends: CmsBaseComponent,

    props: getProps(MarketingMainCategoryText),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    heading: '',
                    shortText: '',
                    fullText: '',
                },
            },
            // eslint-disable-next-line vue/no-unused-properties
            options: {
                staticClass: false,
            },
        };
    },
};
</script>
