import Desktop from '@static/icons/custom/desktop.svg?inline';
import CustomerService from '@static/icons/custom/customer-service.svg?inline';
import Wallet from '@static/icons/custom/wallet.svg?inline';
import Store from '@static/icons/custom/store.svg?inline';

export const ICON_TYPE_CUSTOMER_SERVICE = 'customerService';
export const ICON_TYPE_DESKTOP = 'desktop';
export const ICON_TYPE_STORE = 'store';
export const ICON_TYPE_WALLET = 'wallet';

export const ICON_TYPES = {
    ICON_TYPE_CUSTOMER_SERVICE,
    ICON_TYPE_DESKTOP,
    ICON_TYPE_STORE,
    ICON_TYPE_WALLET,
};

export const ICON_MAP = {
    [ICON_TYPE_CUSTOMER_SERVICE]: CustomerService,
    [ICON_TYPE_DESKTOP]: Desktop,
    [ICON_TYPE_STORE]: Store,
    [ICON_TYPE_WALLET]: Wallet,
};
