<template>
    <BaseLabel v-bind="propsToBind">
        <slot v-if="slotDefault">{{ JSON.parse(slotDefault) }}</slot>
    </BaseLabel>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import BaseLabel from '@atoms/BaseLabel/BaseLabel';

export default {
    name: 'CmsBaseLabel',

    components: {
        BaseLabel,
    },

    extends: CmsBaseComponent,

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },
        ...getProps(BaseLabel),
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    id: 'example-base-label-id',
                },
                slots: {
                    default: 'example label',
                },
            },
        };
    },
};
</script>
