<template>
    <MarketingBlogBar v-bind="propsToBind" :class="cssClass" />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import MarketingBlogBar from '@organisms/MarketingBlogBar/MarketingBlogBar';

export default {
    name: 'CmsMarketingBlogBar',

    components: {
        MarketingBlogBar,
    },

    extends: CmsBaseComponent,

    props: getProps(MarketingBlogBar),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    tagline: '',
                    heading: '',
                    description: '',
                    buttonLabel: '',
                    buttonLink: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                staticClass: false,
            },
        };
    },
};
</script>
