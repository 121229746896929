<template>
    <section class="loyalty-club-ambassadors-slider">
        <ContainerContent :align-left="true">
            <SimpleSlider
                :items-count="ITEMS_COUNT"
                :has-navigation-buttons="$slots.default.length > ITEMS_COUNT"
            >
                <template #slides>
                    <slot />
                </template>
            </SimpleSlider>
        </ContainerContent>
    </section>
</template>

<script>
import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import SimpleSlider from '@molecules/SimpleSlider/SimpleSlider';

const ITEMS_COUNT = 5;

export default {
    name: 'LoyaltyClubAmbassadorsSlider',

    components: {
        ContainerContent,
        SimpleSlider,
    },

    beforeCreate() {
        this.ITEMS_COUNT = ITEMS_COUNT;
    },
};
</script>

<style lang="scss" scoped>
$container-padding-desktop: $tailwindcss-spacing-3;
$items-count: 5;
$slide-gap: $tailwindcss-spacing-7;
$container-max-width: #{theme('container.maxWidth')};
$view-width: calc(min(100vw, #{$container-max-width}));
$navigation-buttons-height: $tailwindcss-spacing-8;

$slide-min-width-desktop: calc(
    (#{$view-width} - (#{$container-padding-desktop} * 2)) / #{$items-count} - (
            #{$slide-gap} * (#{$items-count} - 1)
        ) / #{$items-count}
);

.loyalty-club-ambassadors-slider {
    &:deep() {
        .navigation-buttons {
            right: calc(#{$slide-min-width-desktop} - #{$slide-gap} / 2);
            top: calc(#{$slide-min-width-desktop} / 2 - #{$navigation-buttons-height} / 2);
        }
    }
}
</style>
