<template>
    <ContainerContent class="marketing-homepage-section">
        <MarketingHomepageSectionTitle class="text-panel" :title="title" :subtitle="subtitle" />
        <div class="categories">
            <slot />
        </div>
    </ContainerContent>
</template>

<script>
import ContainerContent from '@molecules/ContainerContent/ContainerContent';

import MarketingHomepageSectionTitle from '@organisms/MarketingHomepageSection/MarketingHomepageSectionTitle';

export default {
    name: 'MarketingHomepageSection',

    components: {
        MarketingHomepageSectionTitle,
        ContainerContent,
    },

    props: {
        title: {
            type: String,
            required: true,
        },

        subtitle: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-homepage-section {
    &:deep() {
        &.container-content {
            @apply px-0;
        }
    }

    .categories {
        @apply flex flex-col items-center w-full;
    }

    @screen lg {
        &:deep() {
            &.container-content {
                @apply px-3;
            }
        }

        .categories {
            @apply flex-row w-3/4 self-center;
        }

        .text-panel {
            @apply w-1/4;
        }
    }
}
</style>
