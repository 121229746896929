<template>
    <SimpleSliderSlide v-if="hasAllRequiredValues" class="marketing-brands-slider-slide">
        <BaseLink :title="brandLabel" :path="brandUrl">
            <BasePicture
                :width="image.width"
                :height="image.height"
                :src="image.url"
                :alt="image.alt"
                :is-lazy="true"
            />
        </BaseLink>
    </SimpleSliderSlide>
</template>

<script>
/* eslint-disable max-len */
import { IMAGE_TYPE_MARKETING_BRAND_115w_55h } from '@types/Image';

import BaseLink from '@atoms/BaseLink/BaseLink';
import BasePicture from '@atoms/BasePicture/BasePicture';

import SimpleSliderSlide from '@molecules/SimpleSlider/SimpleSliderSlide';

export default {
    name: 'MarketingBrandsSliderSlide',

    components: {
        BaseLink,
        SimpleSliderSlide,
        BasePicture,
    },

    props: {
        brandLabel: {
            type: String,
            required: true,
        },

        brandUrl: {
            type: String,
            required: true,
        },

        brandImageUrl: {
            type: String,
            required: true,
        },

        brandCode: {
            type: String,
            required: true,
        },
    },

    computed: {
        image() {
            return this.$imaginator.getImage(
                this.brandImageUrl,
                this.brandCode,
                this.brandLabel,
                IMAGE_TYPE_MARKETING_BRAND_115w_55h
            );
        },

        hasAllRequiredValues() {
            return this.brandLabel && this.brandUrl && this.brandImageUrl && this.brandCode;
        },
    },
};
</script>

<style lang="scss" scoped>
$slide-gap-mobile: $tailwindcss-spacing-3;
$slide-min-width-mobile: 70px;

$items-count-desktop: 12;
$slide-gap-desktop: $tailwindcss-spacing-5;
$slide-min-width-desktop: calc(
    100% / #{$items-count-desktop} - (#{$slide-gap-desktop} * (#{$items-count-desktop} - 1) / #{$items-count-desktop})
);

.marketing-brands-slider-slide {
    margin-right: $slide-gap-mobile;
    min-width: $slide-min-width-mobile;

    &:first-child {
        margin-left: $slide-gap-mobile;
    }

    &:last-child {
        @apply mr-0;
        padding-right: $slide-gap-mobile;
        min-width: calc(#{$slide-min-width-mobile} + #{$tailwindcss-spacing-3});
    }

    @screen lg {
        margin-right: $slide-gap-desktop;
        min-width: $slide-min-width-desktop;

        &:first-child {
            @apply ml-0;
        }

        &:last-child {
            @apply mr-0 pr-0;
            min-width: $slide-min-width-desktop;
        }
    }
}
</style>
