<template>
    <div class="loyalty-club-dark-section">
        <div class="section-wrapper" :class="{ reverse, 'is-subscribed': isSubscribed }">
            <ContainerContent class="text-container">
                <BaseHeading theme="h5" class="dark-section-heading">
                    {{ heading }}
                </BaseHeading>
                <div v-if="$slots.default || content" class="dark-section-content">
                    <slot>
                        <BaseParagraph class="dark-section-paragraph">
                            {{ content }}
                        </BaseParagraph>
                    </slot>
                </div>
            </ContainerContent>
            <ContainerContent class="media-container">
                <BasePicture
                    v-if="imageUrl"
                    class="image"
                    :width="image.width"
                    :height="image.height"
                    :src="image.url"
                    :alt="image.alt"
                    :sources="image.sources"
                    :is-lazy="true"
                />
                <StaticBlock
                    v-else-if="staticBlockContent"
                    :content="staticBlockContent"
                    class="static-block-wrapper"
                />
            </ContainerContent>
        </div>
    </div>
</template>

<script>
import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';
import BasePicture from '@atoms/BasePicture/BasePicture';
import StaticBlock from '@atoms/StaticBlock/StaticBlock';
import {
    DEFAULT_IMAGE_FORMAT,
    LOYALTY_CLUB_IMAGE_TYPES as IMAGE_TYPES,
    SOURCE_IMAGE_FORMATS,
} from '@types/Image';

export default {
    name: 'LoyaltyClubDarkSection',

    components: {
        ContainerContent,
        BaseHeading,
        BaseParagraph,
        BasePicture,
        StaticBlock,
    },

    props: {
        isSubscribed: {
            type: Boolean,
            required: true,
        },

        heading: {
            type: String,
            required: true,
        },

        content: {
            type: String,
            default: '',
        },

        imageUrl: {
            type: String,
            default: '',
        },

        reverse: {
            type: Boolean,
            default: false,
        },

        staticBlockContent: {
            type: String,
            default: '',
        },
    },

    computed: {
        image() {
            return this.$imaginator.getImage(
                this.imageUrl,
                'loyalty-club-dark-section',
                this.heading,
                IMAGE_TYPES.loyalty_club_672w_378h.name,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS,
                Object.keys(IMAGE_TYPES)
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.loyalty-club-dark-section {
    .text-container {
        @apply pt-5 flex-col;
    }

    .media-container {
        @apply flex-col pt-5;
        background: linear-gradient(#222 20%, #fff 20%);

        &:deep(.image) {
            @apply flex flex-col items-center;
        }
    }

    .dark-section-heading {
        @apply text-center text-light;
    }

    .dark-section-content {
        @apply flex flex-col items-center pt-3;
    }

    .dark-section-paragraph {
        @apply text-center text-light;
    }

    .static-block-wrapper {
        @apply flex justify-center w-full;

        &:deep() {
            .iframe-wrapper {
                @apply w-full relative;
                padding-top: theme('customVariables.iframeVideo.paddingTop');

                iframe {
                    @apply absolute w-full h-full top-0 left-0 right-0 my-auto;
                }
            }
        }
    }

    @screen mobile-only {
        .text-container {
            background: $tailwindcss-colors-tertiary;
        }

        .media-container {
            background: linear-gradient(#222 20%, #fff 20%);
        }
    }

    @screen lg {
        @apply flex;
        background: linear-gradient(#fff 10%, #222 10%, #222 90%, #fff 90%);

        .section-wrapper {
            @apply flex mx-auto w-full;
            max-width: #{theme('container.maxWidth')};

            &.reverse {
                .text-container {
                    @apply order-1;
                }
            }

            &.is-subscribed {
                .dark-section-heading {
                    @apply pt-7;
                }
            }
        }

        .text-container,
        .media-container {
            @apply w-1/2 pt-0;
            background: linear-gradient(#fff 10%, #222 10%, #222 90%, #fff 90%);
        }

        .text-container {
            @apply px-9;
        }

        .media-container {
            &:deep(.image) {
                @apply justify-center;
            }
        }

        .dark-section-heading {
            @apply pt-8 text-xl leading-xl;
        }

        .dark-section-content {
            @apply pb-7;
        }

        .dark-section-content,
        .dark-section-paragraph {
            @apply text-m leading-m;
        }
    }

    @screen container {
        .dark-section-heading {
            @apply pt-0;
        }

        .dark-section-content {
            @apply pb-0;
        }

        .section-wrapper {
            &.is-subscribed {
                .dark-section-heading {
                    @apply pt-0;
                }
            }
        }
    }
}
</style>
