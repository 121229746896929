<template>
    <div class="marketing-homepage-section-title">
        <BaseHeading class="title" theme="h5">{{ title }}</BaseHeading>

        <BaseHeading class="subtitle" theme="h2">
            {{ subtitle }}
        </BaseHeading>
    </div>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';

export default {
    name: 'MarketingHomepageSectionTitle',

    components: {
        BaseHeading,
    },

    props: {
        title: {
            type: String,
            required: true,
        },

        subtitle: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
$letter-spacing-space: $tailwindcss-customSizes-10p;

.marketing-homepage-section-title {
    @apply flex flex-col items-center px-3;

    .title {
        @apply pb-2 relative uppercase text-center;
        letter-spacing: $letter-spacing-space;
        text-indent: $letter-spacing-space;

        &::before {
            @apply absolute h-1p inset-x-0 bottom-0 mx-auto bg-gray1 w-11/20;
            content: '';
        }
    }

    .subtitle {
        @apply text-center mt-2 leading-xl;
    }

    @screen lg {
        @apply self-center px-0;

        .title {
            @apply pb-3 text-l leading-xl;
        }

        .subtitle {
            @apply mt-3;
        }
    }

    @screen container {
        .subtitle {
            @apply text-xxl leading-xxxl;
        }
    }
}
</style>
