<template>
    <div class="marketing-hero-content">
        <div class="text">
            <BaseHeading class="heading" theme="h5">
                {{ heading }}
            </BaseHeading>
            <BaseParagraph class="description">
                <span v-html="description" />
            </BaseParagraph>
        </div>

        <ButtonLarge :to="buttonLink" :variant="BUTTON_THEME" class="action">
            {{ buttonLabel }}
        </ButtonLarge>
    </div>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';

import { ButtonLarge, BUTTON_LARGE_VARIANTS } from '@modivo-ui/components/ButtonLarge/v1';

export default {
    name: 'MarketingHeroContent',

    components: {
        BaseHeading,
        BaseParagraph,
        ButtonLarge,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        description: {
            type: String,
            required: true,
        },

        buttonLink: {
            type: String,
            required: true,
        },

        buttonLabel: {
            type: String,
            required: true,
        },
    },

    beforeCreate() {
        this.BUTTON_THEME = BUTTON_LARGE_VARIANTS.SECONDARY;
    },
};
</script>

<style lang="scss" scoped>
.marketing-hero-content {
    .text {
        max-width: theme('customVariables.marketingHero.heroTextMaxWidth');
    }

    .description {
        @apply mt-1 text-gray1 leading-s;
    }

    .action {
        @apply mt-ui-6;
    }

    .heading {
        @apply text-new-m leading-new-m;
    }

    @screen md {
        .heading {
            @apply text-new-l leading-new-l;
        }
    }

    @screen lg {
        .heading {
            @apply text-xl leading-new-xl;
        }
    }
}
</style>
