<template>
    <ContainerContent v-bind="propsToBind">
        <PageBuilderInterpreter :components-json="slotDefault" :class="cssClass" />
    </ContainerContent>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';

import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';
import ContainerContent from '@molecules/ContainerContent/ContainerContent';

export default {
    name: 'CmsContainerContent',

    components: {
        ContainerContent,
        PageBuilderInterpreter,
    },

    extends: CmsBaseComponent,

    props: {
        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },
        ...getProps(ContainerContent),
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                slots: {
                    default: '',
                },
            },
        };
    },
};
</script>
