import { render, staticRenderFns } from "./MarketplaceFamiliarBrands.vue?vue&type=template&id=e8ac5ea8&scoped=true"
import script from "./MarketplaceFamiliarBrands.vue?vue&type=script&lang=js"
export * from "./MarketplaceFamiliarBrands.vue?vue&type=script&lang=js"
import style0 from "./MarketplaceFamiliarBrands.vue?vue&type=style&index=0&id=e8ac5ea8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8ac5ea8",
  null
  
)

export default component.exports