<template>
    <div class="products-simple-slider-wrapper">
        <ContainerContent class="slider-container">
            <div class="heading-wrapper">
                <div class="heading">
                    <BaseHeading v-if="heading" class="heading-text">
                        {{ heading }}
                    </BaseHeading>
                    <ButtonSmall
                        v-if="link && !isMobile"
                        :to="link"
                        :variant="BUTTON_SMALL_VARIANT"
                        @click="emitSeeMore()"
                    >
                        {{ $t('See all') }}
                    </ButtonSmall>
                </div>

                <div v-if="subheading" class="subheading">
                    {{ subheading }}
                </div>
                <ButtonLarge
                    v-if="buttonLink && buttonLabel"
                    :to="buttonLink"
                    :variant="BUTTON_LARGE_VARIANT"
                    class="button"
                    @click.native="$emit('button-link-click')"
                >
                    {{ buttonLabel }}
                </ButtonLarge>
            </div>
            <WithWishlistedProducts :products-skus="productsSkus">
                <ProductSimpleSlider
                    :products="products"
                    :items-count="itemsCount"
                    :action-field="actionField"
                    @product-click="$emit('product-click', $event)"
                    @scroll="$emit('scroll')"
                />
            </WithWishlistedProducts>
            <div v-if="link && isMobile" class="after-slides">
                <ButtonSmall :to="link" :variant="BUTTON_SMALL_VARIANT" @click="emitSeeMore()">
                    {{ $t('See all') }}
                </ButtonSmall>
            </div>
        </ContainerContent>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import { ITEMS_COUNT } from '@configs/simple-slider';

import BaseHeading from '@atoms/BaseHeading/BaseHeading';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import WithWishlistedProducts from '@molecules/WithWishlistedProducts/WithWishlistedProducts';
import ProductSimpleSlider from '@molecules/ProductSimpleSlider/ProductSimpleSlider';

import { ButtonLarge, BUTTON_LARGE_VARIANTS } from '@modivo-ui/components/ButtonLarge/v1';
import { ButtonSmall, BUTTON_SMALL_VARIANTS } from '@modivo-ui/components/ButtonSmall/v1';

export default {
    name: 'ProductsSimpleSliderWrapper',

    components: {
        ContainerContent,
        BaseHeading,
        WithWishlistedProducts,
        ProductSimpleSlider,
        ButtonLarge,
        ButtonSmall,
    },

    props: {
        heading: {
            type: String,
            default: '',
        },

        subheading: {
            type: String,
            default: '',
        },

        buttonLabel: {
            type: String,
            default: '',
        },

        buttonLink: {
            type: String,
            default: '',
        },

        link: {
            type: String,
            default: '',
        },

        products: {
            type: Array,
            default: () => [],
        },

        actionField: {
            type: String,
            default: '',
        },

        itemsCount: {
            type: Number,
            default: 6,
            validator: value => ITEMS_COUNT.includes(value),
        },
    },

    computed: {
        ...mapState(['isMobile']),

        productsSkus() {
            return this.products.map(({ sku }) => sku);
        },
    },

    beforeCreate() {
        this.BUTTON_LARGE_VARIANT = BUTTON_LARGE_VARIANTS.SECONDARY;
        this.BUTTON_SMALL_VARIANT = BUTTON_SMALL_VARIANTS.SECONDARY;
    },

    methods: {
        emitSeeMore() {
            this.$emit('see-more-click');
        },
    },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';

$container-with-slider-max-width: 1602px;
$shadow-width: 3px;
$product-card-description-height: 120px;
$product-card-image-height-mobile: 197px;
$navigation-buttons-height: 48px;
$hover-size-desktop: $tailwindcss-customSizes-12p;
$slider-x-padding: $tailwindcss-spacing-3 - $shadow-width;

$navigation-spacing-container: 27px;
$navigation-top: calc(
    (100% - #{$product-card-description-height}) / 2 - #{math.div($navigation-buttons-height, 2)}
);

$desktop-price-min-height: 44px;
$badge-line-height: 20px;
$slider-product-min-height-mobile: 21px;

.products-simple-slider-wrapper {
    @apply w-full;

    .heading-wrapper {
        @apply w-full px-3 mb-3;
    }

    .heading {
        @apply flex items-center;
    }

    .heading-text {
        @apply leading-l text-l;
    }

    .subheading {
        @apply mt-1 text-gray1;
    }

    .button {
        @apply mt-5;
    }

    .after-slides {
        @apply pl-2 pr-3 whitespace-nowrap;
        padding-top: calc(#{$product-card-image-height-mobile} / 2);
    }

    &:deep() {
        .slider-container {
            @apply py-4 px-0;
        }
    }

    @screen lg {
        .heading-wrapper {
            @apply w-full px-5;
        }

        .heading {
            @apply justify-between;
        }

        .heading-text {
            @apply text-xl leading-xl;
        }

        &:deep() {
            .slider-container {
                @apply p-0;
            }

            .slider-container-content {
                @apply px-3;

                .slider {
                    @apply px-1;
                }
            }

            .navigation-buttons {
                &.horizontal-nav {
                    top: $navigation-top;
                    width: calc(100% - 2 * #{$tailwindcss-customSizes-20p});
                    margin-left: $tailwindcss-spacing-3;
                }
            }
        }
    }

    @screen container {
        &:deep() {
            .slider-container-content {
                padding-left: $slider-x-padding;
                padding-right: $slider-x-padding;

                .slider {
                    @apply px-0;
                }
            }

            .navigation-buttons {
                &.horizontal-nav {
                    width: calc(100% - 2 * #{$navigation-spacing-container});
                    margin-left: $navigation-spacing-container;
                }
            }
        }
    }
}
</style>
