<template>
    <div class="live-stream-accordion-item">
        <div class="accordion-title" @click="toggle()">
            <LiveStreamAccordionItemTitle :time="time" :name="name" :description="description" />
            <div class="toggle-icon">
                <SvgIcon width="16px" height="16px">
                    <component :is="toggleIcon" />
                </SvgIcon>
            </div>
        </div>
        <div v-show="isItemActive" class="accordion-content">
            <slot />
        </div>
    </div>
</template>

<script>
import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import Minus1 from '@static/icons/16px/minus1.svg?inline';
import Plus1 from '@static/icons/16px/plus1.svg?inline';
import LiveStreamAccordionItemTitle from '@molecules/LiveStreamAccordion/LiveStreamAccordionItemTitle';

export default {
    name: 'LiveStreamAccordionItem',

    components: {
        SvgIcon,
        Minus1,
        Plus1,
        LiveStreamAccordionItemTitle,
    },

    props: {
        isActive: {
            type: Boolean,
            default: false,
        },

        time: {
            type: String,
            default: '',
        },

        name: {
            type: String,
            default: '',
        },

        description: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            isItemActive: this.isActive,
        };
    },

    computed: {
        toggleIcon() {
            return this.isItemActive ? Minus1 : Plus1;
        },
    },

    methods: {
        toggle() {
            this.isItemActive = !this.isItemActive;
        },
    },
};
</script>

<style lang="scss" scoped>
.live-stream-accordion-item {
    @apply border-border border-b-1;

    .accordion-title {
        @apply flex justify-between cursor-pointer;
    }

    .toggle-icon {
        @apply mt-1 pt-4;
    }

    .accordion-content {
        @apply pb-4;
    }

    @screen lg {
        &:last-of-type {
            @apply border-b-0;
        }

        .accordion-title {
            @apply items-center;
        }

        .toggle-icon {
            @apply mt-0 pt-0;
        }
    }
}
</style>
