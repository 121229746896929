<template>
    <section class="marketing-brands-slider">
        <ContainerContent class="slider-container">
            <SimpleSlider
                :has-navigation-buttons="false"
                :additional-scroll-offset="additionalScrollOffset"
                :has-auto-play="true"
                :pause-auto-play-on-mouse-over="true"
            >
                <template #slides>
                    <slot></slot>
                </template>
            </SimpleSlider>
        </ContainerContent>
    </section>
</template>

<script>
import { mapState } from 'vuex';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import SimpleSlider from '@molecules/SimpleSlider/SimpleSlider';

const SLIDE_GAP_MOBILE = 16;
const SLIDE_GAP_DESKTOP = 32;

export default {
    name: 'MarketingBrandsSlider',

    components: {
        ContainerContent,
        SimpleSlider,
    },

    computed: {
        ...mapState(['isMobile']),

        additionalScrollOffset() {
            return this.isMobile ? SLIDE_GAP_MOBILE : SLIDE_GAP_DESKTOP;
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-brands-slider {
    &:deep() {
        .slider-container {
            @apply px-0;
        }
    }

    @screen lg {
        &:deep() {
            .slider-container {
                @apply px-5;
            }
        }
    }
}
</style>
