<template>
    <div />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

const mapCategoriesToArray = (categories, locale) => {
    if (Object.keys(categories).length === 0) {
        return;
    }

    const { items } = categories;

    return items.map(({ code, parent, translations }) => {
        const { label, url } = translations[locale] || {};

        return {
            parentId: parent,
            label,
            description: url,
            value: code,
        };
    });
};

export default {
    name: 'CmsSyneriseMapCategories',

    extends: CmsBaseComponent,

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        categoriesMap: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },

            // eslint-disable-next-line vue/no-unused-properties
            propsCustomData: {
                categoriesMap: {
                    fetchFunction: (app, locale) => app.$services.search.getCategories(locale),
                    mapFunction: (items, locale) => mapCategoriesToArray(items, locale),
                    tableHeaders: ['Category', 'ID', 'Action'],
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            propsCustomFormConfig: {
                categoriesMap: {
                    hasValuesMap: true,
                },
            },
        };
    },
};
</script>
