<template>
    <MarketplacePartnersBenefit v-bind="propsToBind" :class="cssClass" />
</template>

<script>
import { ICON_TYPES } from '@configs/marketplace-partner-benefit';

import { getProps } from '@modules/page-builder/helpers/component';

import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

import MarketplacePartnersBenefit from '@organisms/MarketplacePartnersBenefits/MarketplacePartnersBenefit';

export default {
    name: 'CmsMarketplacePartnersBenefit',

    components: {
        MarketplacePartnersBenefit,
    },

    extends: CmsBaseComponent,

    props: {
        ...getProps(MarketplacePartnersBenefit),
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    heading: '',
                    text: '',
                    icon: ICON_TYPES.ICON_TYPE_STORE,
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                icon: Object.values(ICON_TYPES),
            },
        };
    },
};
</script>
