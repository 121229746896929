<template>
    <div class="vertical-slider-product">
        <div class="column one">
            <BaseLink :path="product.url" :title="product.name">
                <BasePicture
                    class="image"
                    :src="image.url"
                    :width="image.width"
                    :height="image.height"
                    :alt="image.alt"
                />
            </BaseLink>
        </div>

        <div class="column two">
            <div class="details-container">
                <div class="brand-name">
                    <BaseLink
                        class="brand"
                        :path="product.brandUrl"
                        :label="product.brandName"
                        :title="product.brandName"
                    />
                    <BaseLink
                        class="name"
                        :path="product.url"
                        :label="product.name"
                        :title="product.name"
                    />
                </div>
            </div>
            <div class="price-container">
                <ProductPrice
                    size="small"
                    display="inline"
                    :show-discount-value="showDiscountPercent"
                    :discount="product.price.discount"
                    :cart-discount="null"
                    :price-regular="product.price.regular.formatted"
                    :price-promotional="product.price.promotional.formatted"
                    :price-omnibus="priceOmnibus"
                    :show-discount="showDiscountedPrice"
                    class="item-price"
                />
            </div>
        </div>
        <div class="column three">
            <AddToWishlistWrapper #default="{ isLoading, isAdded, toggle }" :product="product">
                <ButtonAddToWishlist
                    class="add-to-wishlist"
                    only-icon
                    :is-loading="isLoading"
                    :is-added="isAdded"
                    @toggle="toggle()"
                />
            </AddToWishlistWrapper>
        </div>
    </div>
</template>

<script>
import { IMAGE_TYPE_CART } from '@types/Image';

import { getImage } from '@assets/images';

import BaseLink from '@atoms/BaseLink/BaseLink';
import BasePicture from '@atoms/BasePicture/BasePicture';

import ProductPrice from '@molecules/ProductPrice/ProductPrice';
import ButtonAddToWishlist from '@molecules/ButtonAddToWishlist/ButtonAddToWishlist';
import AddToWishlistWrapper from '@molecules/AddToWishlistWrapper/AddToWishlistWrapper';

export default {
    name: 'VerticalSliderProduct',

    components: {
        ProductPrice,
        BaseLink,
        BasePicture,
        AddToWishlistWrapper,
        ButtonAddToWishlist,
    },

    props: {
        product: {
            type: Object,
            required: true,
        },
        showDiscountPercent: {
            type: Boolean,
            default: false,
        },
        showDiscountedPrice: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        image() {
            return getImage(this.product.images, IMAGE_TYPE_CART);
        },

        priceOmnibus() {
            return this.product.price.omnibus?.formatted;
        },
    },
};
</script>

<style lang="scss" scoped>
.vertical-slider-product {
    @apply flex my-2;

    .column {
        &.one {
            @apply mr-3 w-1/3 shrink-0;
            max-width: 70px;

            &:deep(.image) {
                .image {
                    @apply mx-0;
                }
            }
        }

        &.two {
            @apply self-center w-full min-w-0;

            .details-container {
                @apply w-full min-w-0;
            }
        }

        &.three {
            @apply self-center;
        }
    }

    .brand-name {
        .text-link {
            @apply block text-s leading-s;
        }

        .brand {
            @apply uppercase truncate font-semibold;
        }

        .name {
            @apply text-text truncate w-full;
        }
    }

    &:deep() {
        .price-container {
            @apply text-s leading-s;

            .product-price {
                @apply mt-1;
            }

            .price-with-discount {
                @apply pr-2;
            }
        }

        .button-add-to-wishlist {
            @apply w-7 h-7;

            .svg {
                @apply w-3;
            }
        }
    }

    @screen md {
        .brand-name {
            .text-link {
                @apply text-r leading-r;
            }
        }

        &:deep() .price-container {
            @apply text-r leading-r;

            .product-price {
                @apply mt-2;
            }
        }
    }

    @screen lg {
        @apply my-3;

        .column.one {
            @apply mr-4;
            max-width: 120px;
        }

        .brand-name {
            .text-link {
                @apply text-m leading-m;
            }
        }

        &:deep() {
            .price-container {
                .wrapper {
                    @apply items-baseline;
                }
            }

            .price-regular {
                .price-container {
                    @apply text-r leading-r;
                }
            }

            .final-price-wrapper {
                .price-container {
                    @apply text-m leading-m;
                }
            }
        }
    }
}
</style>
