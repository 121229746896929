import { createNamespacedHelpers } from 'vuex';

import { startDateLocaleString } from '@assets/date';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    data() {
        return {
            nowTimestamp: null,
        };
    },

    computed: {
        ...mapConfigGetters(['locale']),

        formatLocale() {
            return this.locale.replace('_', '-');
        },

        startDateTimestamp() {
            return this.startDate.getTime();
        },

        beforeEvent() {
            return this.nowTimestamp && this.nowTimestamp < this.startDateTimestamp;
        },

        startDateCountdownFormat() {
            const MILISECONDS_IN_SECOND = 1000;

            return parseInt(this.startDateTimestamp / MILISECONDS_IN_SECOND, 10);
        },

        startDateLocaleString() {
            return startDateLocaleString(this.startDate, this.formatLocale);
        },
    },

    mounted() {
        this.nowTimestamp = new Date().getTime();
    },
};
