<template>
    <div :class="[size, `display-${displayType}`, `aligned-${alignText}`]" class="product-price">
        <div class="wrapper">
            <div
                v-if="isSale && isRegularPriceEnabled && !isOmnibusStrictEnabled"
                class="price-with-discount"
            >
                <div
                    v-if="!isMinimalOffer"
                    :data-test-id="PRODUCT_PRICE_REGULAR"
                    class="price-regular"
                >
                    <div class="price-container">
                        <div :data-test-id="PRODUCT_FULL_PRICE" class="price">
                            {{ priceRegularWithoutCents }}
                        </div>
                    </div>
                </div>
                <div v-if="showDiscountValue" class="discount">
                    <BaseBadge
                        class="discount-badge"
                        :content="discountToShow"
                        :theme="THEME_TERTIARY"
                        size="normal"
                    >
                        <span class="discount-text">{{ discountToShow }}</span>
                    </BaseBadge>
                </div>
            </div>
            <div :data-test-id="PRODUCT_PRICE_WRAPPER" class="final-price-wrapper">
                <div class="price-container">
                    <ProductPricePromotion
                        :data-test-id="PRODUCT_PRICE"
                        :is-sale="isSale"
                        :html-element="pricePromotionalToShow"
                    />
                </div>
            </div>
            <Omnibus
                v-if="showOmnibusPriceInformation"
                :minimal="omnibusMinimalPrice"
                :regular="omnibusRegularPrice"
                :variant="omnibusVariant"
            />
        </div>
    </div>
</template>

<script>
import {
    IS_OMNIBUS_STRICT_ENABLED,
    IS_REGULAR_PRICE_ENABLED,
    IS_CUT_OFF_THE_CENTS_ENABLED,
    IS_GOOD_PRICE_ENABLED,
} from '@localeConfig/keys';

import { THEME_TERTIARY } from '@types/Badge';
import { DISPLAYS } from '@types/ProductPrice';
import {
    PRODUCT_PRICE_WRAPPER,
    PRODUCT_PRICE,
    PRODUCT_PRICE_REGULAR,
    PRODUCT_FULL_PRICE,
} from '@types/AutomaticTestIDs';

import ProductPrice from '@mixins/ProductPrice';

import BaseBadge from '@atoms/BaseBadge/BaseBadge';
import ProductPricePromotion from '@molecules/ProductPricePromotion/ProductPricePromotion';

import { Omnibus, OMNIBUS_VARIANTS } from '@modivo-ui/components/Omnibus/v1';

export default {
    name: 'ProductPrice',

    components: {
        BaseBadge,
        ProductPricePromotion,
        Omnibus,
    },

    mixins: [ProductPrice],

    props: {
        discount: {
            type: [String, Number],
            default: '',
        },

        discountRegular: {
            type: String,
            default: '',
        },

        showLongOmnibusMessage: {
            type: Boolean,
            default: false,
        },

        priceOmnibus: {
            type: String,
            default: '',
        },

        omnibusVariant: {
            type: String,
            default: OMNIBUS_VARIANTS.M,
        },

        cartDiscount: {
            type: String,
            default: null,
        },

        isMinimalOffer: {
            type: Boolean,
            default: false,
        },

        isWithMarketingAction: {
            type: Boolean,
            default: false,
        },

        isOmnibusPricePercentEnabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        isOmnibusStrictEnabled() {
            return this.$configProvider.getConfig(IS_OMNIBUS_STRICT_ENABLED);
        },

        isCutOffTheCentsEnabled() {
            return this.$configProvider.getConfig(IS_CUT_OFF_THE_CENTS_ENABLED);
        },

        displayType() {
            return this.isOmnibusStrictEnabled ? DISPLAYS.DISPLAY_BLOCK : this.display;
        },

        priceRegularWithoutCents() {
            return this.cutOffTheCents(this.priceRegular);
        },

        priceOmnibusWithoutCents() {
            return this.cutOffTheCents(this.priceOmnibus);
        },

        showPricesOnGoodPrice() {
            return this.$configProvider.getConfig(IS_GOOD_PRICE_ENABLED)
                ? this.arePricesDifferent
                : null;
        },

        arePricesDifferent() {
            return this.pricePromotional !== this.priceRegular;
        },

        arePricesTheSame() {
            const { pricePromotional } = this;

            return pricePromotional === this.priceRegular && pricePromotional === this.priceOmnibus;
        },

        isSale() {
            if (!this.isOmnibusStrictEnabled) {
                return (
                    (this.discountToShow && this.showDiscount && this.arePricesDifferent) ?? false
                );
            }

            return (
                (this.discountToShow &&
                    this.showDiscount &&
                    this.priceOmnibus &&
                    this.priceOmnibus !== this.pricePromotional) ??
                false
            );
        },

        showOmnibusPriceInformation() {
            return (
                this.isOmnibusStrictEnabled &&
                this.priceOmnibus &&
                !this.isMinimalOffer &&
                !this.arePricesTheSame &&
                (this.showPricesOnGoodPrice || this.isSale || this.isWithMarketingAction)
            );
        },

        discountValue() {
            const { discount, cartDiscount } = this;

            if (discount && cartDiscount) {
                return Math.max(parseInt(discount, 10), parseInt(cartDiscount, 10));
            }

            return parseInt(discount, 10) || parseInt(cartDiscount, 10) || null;
        },

        discountToShow() {
            const { discountValue } = this;

            return discountValue && !this.isMinimalOffer ? `-${discountValue}%` : null;
        },

        pricePromotionalToShow() {
            const pricePromotionalWithoutCents = this.cutOffTheCents(this.pricePromotional);

            return this.isMinimalOffer
                ? this.$t('from {number}', { number: pricePromotionalWithoutCents })
                : pricePromotionalWithoutCents;
        },

        lowestPriceText() {
            return `${
                this.showLongOmnibusMessage
                    ? this.$t('Lowest price in 30 days before the discount')
                    : this.$t('Lowest price')
            }:`;
        },

        regularPriceText() {
            return `${this.$t('Regular price')}:`;
        },

        isRegularPriceEnabled() {
            return this.$configProvider.getConfig(IS_REGULAR_PRICE_ENABLED);
        },

        omnibusMinimalPrice() {
            if (this.showOmnibusPriceInformation) {
                return {
                    label: this.lowestPriceText,
                    value: this.priceOmnibusWithoutCents,
                    discount: this.isOmnibusPricePercentEnabled ? `-${this.discount}` : '',
                };
            }

            return {};
        },

        omnibusRegularPrice() {
            if (this.isRegularPriceEnabled) {
                return {
                    label: this.regularPriceText,
                    value: this.priceRegularWithoutCents,
                    discount: this.isOmnibusPricePercentEnabled ? `-${this.discountRegular}` : '',
                };
            }

            return {};
        },
    },

    beforeCreate() {
        this.THEME_TERTIARY = THEME_TERTIARY;
        this.PRODUCT_PRICE = PRODUCT_PRICE;
        this.PRODUCT_PRICE_WRAPPER = PRODUCT_PRICE_WRAPPER;
        this.PRODUCT_PRICE_REGULAR = PRODUCT_PRICE_REGULAR;
        this.PRODUCT_FULL_PRICE = PRODUCT_FULL_PRICE;
    },

    methods: {
        cutOffTheCents(priceFormatted) {
            const regex = /,[0-9][0-9]/i;

            if (this.isCutOffTheCentsEnabled && priceFormatted) {
                return priceFormatted.replace(regex, '');
            }

            return priceFormatted;
        },
    },
};
</script>

<style lang="scss" scoped>
.product-price {
    @apply text-center flex;

    .price-regular {
        @apply text-gray2;

        .price {
            @apply text-gray2;
        }
    }

    &.aligned-left {
        .price-container {
            @apply justify-start;
        }
    }

    &.aligned-right {
        .price-container {
            @apply justify-end;
        }
    }

    &.big {
        .final-price-wrapper {
            @apply text-l leading-new-l font-semibold;
        }

        &:deep() {
            .discount {
                .text {
                    @apply text-s leading-s text-gray1;
                }
            }

            .final-price-wrapper {
                .text {
                    @apply text-r leading-r;
                }
            }
        }
    }

    &.medium {
        .price-regular {
            @apply text-xs leading-xxs font-book;
        }

        &.display-inline {
            .price-with-discount {
                @apply pr-10p;
            }
        }

        .discount {
            @apply text-xs leading-xxs text-gray2;

            &::before {
                @apply mx-6p;
            }

            .discount-text {
                @apply text-xs leading-xxs;
            }
        }

        .final-price-wrapper {
            @apply text-r leading-new-xs;
        }
    }

    &.extra-small {
        .price-regular {
            @apply text-xs leading-xs;
        }

        .final-price-wrapper {
            @apply text-s leading-new-s;
        }

        .discount {
            @apply text-xs leading-xs;

            &::before {
                @apply mx-1;
            }

            .base-badge {
                @apply text-xs leading-xs;
            }
        }

        &.display-inline {
            .price-with-discount {
                @apply pr-1;
            }
        }
    }

    &.display-inline {
        .price-with-discount {
            @apply pr-20p;
        }

        .wrapper {
            @apply flex flex-wrap;
        }
    }

    &.display-block {
        .wrapper {
            @apply block;
        }
    }

    .wrapper {
        @apply items-center;
    }

    .price {
        @apply text-tertiary;
    }

    .price-with-discount {
        @apply flex flex-row whitespace-nowrap justify-center items-center;
    }

    .price-regular {
        @apply line-through text-r text-gray2;

        & + .discount {
            &::before {
                content: '|';
            }
        }
    }

    .final-price-wrapper {
        @apply text-r font-semibold leading-r;
    }

    .price-container {
        @apply flex justify-center;
    }

    .is-sale {
        @apply text-sale;
    }

    .discount {
        @apply leading-s;

        &::before {
            @apply inline-block mx-1 align-top;
        }

        &:deep() {
            .base-badge {
                @apply p-0 text-r;
                line-height: inherit;
            }
        }
    }

    :deep(.discount-badge) {
        @apply normal-case;
    }

    @screen lg {
        &.big {
            .price-regular,
            .discount {
                @apply text-r leading-new-r;
            }

            &:deep() {
                .discount {
                    .base-badge,
                    .text {
                        @apply text-r leading-new-r;
                    }
                }

                .final-price-wrapper {
                    .text {
                        @apply text-m leading-m;
                    }
                }
            }
        }

        &.medium {
            .price-regular {
                @apply text-s leading-new-xxs;
            }

            &.display-inline {
                .price-with-discount {
                    @apply pr-10p;
                }
            }

            .discount {
                @apply text-s leading-new-xxs;

                .discount-text {
                    @apply text-s leading-new-xxs;
                }
            }

            .final-price-wrapper {
                @apply text-m leading-new-s;
            }
        }
    }
}
</style>
