<template>
    <div>
        <pre>{{ preview }}</pre>
    </div>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

export default {
    name: 'CmsAppImageCarouselV2',

    extends: CmsBaseComponent,

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        slotHeader: {
            type: String,
            default: JSON.stringify(''),
        },

        // eslint-disable-next-line vue/no-unused-properties
        slotCarouselItem: {
            type: String,
            default: JSON.stringify(''),
        },

        // eslint-disable-next-line vue/no-unused-properties
        slotVisibleRules: {
            type: String,
            default: JSON.stringify(''),
        },

        // eslint-disable-next-line vue/no-unused-properties
        tag: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                slots: {
                    header: '',
                    carouselItem: '',
                    visibleRules: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },

    computed: {
        preview() {
            return {
                ...this.propsToBind,
                slotHeader: JSON.parse(this.slotHeader) || '',
                slotCarouselItem: JSON.parse(this.slotCarouselItem) || '',
                slotVisibleRules: JSON.parse(this.slotVisibleRules) || '',
            };
        },
    },
};
</script>
