<template>
    <li class="marketing-usp-benefit">
        <div class="icon-wrapper">
            <SvgIcon class="icon" width="48px" height="48px">
                <component :is="iconType" />
            </SvgIcon>
        </div>
        <BaseParagraph class="text">
            {{ text }}
        </BaseParagraph>
    </li>
</template>

<script>
import { ICON_MAP, ICON_TYPES } from '@configs/marketing-usp-icons';

import { checkIfExistsInValuesMap } from '@assets/props';

import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';
import SvgIcon from '@atoms/SvgIcon/SvgIcon';

export default {
    name: 'MarketingUSPBenefit',

    components: {
        BaseParagraph,
        SvgIcon,
    },

    props: {
        text: {
            type: String,
            required: true,
        },

        icon: {
            type: String,
            required: true,
            validator: checkIfExistsInValuesMap(ICON_TYPES),
        },
    },

    computed: {
        iconType() {
            return ICON_MAP[this.icon];
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-usp-benefit {
    @apply w-1/2 my-3;

    .icon-wrapper {
        @apply flex justify-center;
    }

    .text {
        @apply font-semibold text-heading text-center leading-new-m text-new-r;
    }

    .icon {
        @apply mb-3 w-7 h-7;
    }

    @screen md {
        @apply w-auto my-0;
        &:not(:last-child) {
            @apply mr-10p;
        }

        .text {
            @apply text-s leading-xs;
        }
    }

    @screen lg {
        .text {
            @apply leading-new-m text-new-r;
        }
    }
}
</style>
