<template>
    <MarketingBanner v-bind="propsToBind" :class="cssClass" />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import MarketingBanner from '@organisms/MarketingBanner/MarketingBanner';
import { THEMES, BUTTON_THEMES } from '@types/MarketingBanner';

const propsConfigToOverride = {
    imageUrl450x450: {
        type: String,
        required: false,
        default: '',
    },

    imageUrl767x450: {
        type: String,
        required: false,
        default: '',
    },

    imageUrl1532x450: {
        type: String,
        required: false,
        default: '',
    },
};

export default {
    name: 'CmsMarketingBanner',

    components: {
        MarketingBanner,
    },

    extends: CmsBaseComponent,

    props: getProps(MarketingBanner, propsConfigToOverride),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    theme: THEMES.THEME_LIGHT,
                    buttonTheme: BUTTON_THEMES.BUTTON_THEME_DEFAULT,
                    heading: '',
                    subHeading: '',
                    buttonLabel: '',
                    // eslint-disable-next-line max-len
                    // @todo (MOD-2139): props "buttonUrl" changed in MOD-2063 to "bannerUrl", left for backward compatibility
                    buttonUrl: '',
                    // @todo end
                    bannerUrl: '',
                    imageUrl: '',
                    imageUrl450x450: '',
                    imageUrl767x450: '',
                    imageUrl1532x450: '',
                    // eslint-disable-next-line max-len
                    // @todo (PM-415): props "imageUrl1532x1149" changed to "imageUrl1532x450", left for backward compatibility
                    imageUrl1532x1149: '',
                    // @todo end
                    videoUrl: '',
                    videoPlaceholder: '',
                },
            },
            // eslint-disable-next-line vue/no-unused-properties
            options: {
                theme: Object.values(THEMES),
                buttonTheme: Object.values(BUTTON_THEMES),
            },
            // eslint-disable-next-line max-len
            // @todo (MOD-2139): props "buttonUrl" changed in MOD-2063 to "bannerUrl", left for backward compatibility
            // eslint-disable-next-line vue/no-unused-properties
            propsCustomFormConfig: {
                buttonUrl: {
                    required: false,
                    disabled: true,
                },

                imageUrl1532x1149: {
                    required: false,
                    disabled: true,
                },

                imageUrl450x450: {
                    required: true,
                },

                imageUrl767x450: {
                    required: true,
                },

                imageUrl1532x450: {
                    required: true,
                },

                imageUrl: {
                    required: false,
                    disabled: true,
                },
            },
            // @todo end
        };
    },
};
</script>
