// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../static/icons/16px/arrow1-up.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../static/icons/16px/arrow1-down.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cms-with-accordions[data-v-17b7761e]  .accordion-section{border-bottom-width:1px;border-style:solid;border-color:#DFE0E0}.cms-with-accordions[data-v-17b7761e]  .accordion-section.open .accordion-button:after{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat}.cms-with-accordions[data-v-17b7761e]  .accordion-section.open .accordion-content{height:auto}.cms-with-accordions[data-v-17b7761e]  .accordion-section:last-of-type{border-bottom-width:0}.cms-with-accordions[data-v-17b7761e]  .accordion-button{position:relative;cursor:pointer;padding-top:16px;padding-bottom:16px;padding-right:24px;font-weight:600;color:#222222}.cms-with-accordions[data-v-17b7761e]  .accordion-button:after{position:absolute;right:0;top:20px;height:16px;width:16px;content:\"\";background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat}.cms-with-accordions[data-v-17b7761e]  .accordion-content{overflow:hidden;font-size:1.4rem;line-height:2.2rem;color:#474747;height:0}.cms-with-accordions[data-v-17b7761e]  .accordion-content p{margin-bottom:24px;color:#222222}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
