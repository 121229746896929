<template>
    <ContainerContent class="marketplace-partners-benefits">
        <BaseHeading class="heading" tag="h2" theme="h3">
            {{ heading }}
        </BaseHeading>

        <ul class="benefits">
            <slot />
        </ul>
    </ContainerContent>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';

export default {
    name: 'MarketplacePartnersBenefits',

    components: {
        BaseHeading,
        ContainerContent,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.marketplace-partners-benefits {
    @apply mt-7;

    max-width: theme('customVariables.marketplacePartnersBenefits.containerMaxWidth');

    .heading {
        @apply text-center mb-5 w-full;
    }

    .benefits {
        @apply flex flex-wrap w-full;
    }

    @screen lg {
        @apply mt-9;

        .heading {
            @apply text-xl leading-xl;
        }
    }
}
</style>
