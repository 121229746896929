<template>
    <div class="countdown-timer">
        <div
            :ref="timerId"
            :data-timestamp="timestamp"
            :data-event-name="STOP_COUNTING_EVENT_NAME"
            class="timer"
        >
            <div v-if="!isDaysInHours" class="time-column">
                <div data-days />
                <span class="time-description">
                    {{ $t('days.') }}
                </span>
            </div>
            <div class="time-column">
                <div data-hours />
                <span class="time-description">
                    {{ $t('hrs.') }}
                </span>
            </div>
            <div class="time-column">
                <div data-minutes />
                <span class="time-description">
                    {{ $t('min.') }}
                </span>
            </div>
            <div class="time-column seconds">
                <div data-seconds />
                <span class="time-description">
                    {{ $t('sec.') }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
const STOP_COUNTING_EVENT_NAME = 'stopCount';

export default {
    name: 'CountdownTimer',

    props: {
        timerId: {
            type: String,
            required: true,
        },

        timestamp: {
            type: Number,
            required: true,
        },

        isDaysInHours: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            hasStopCountingEventListener: false,
        };
    },

    mounted() {
        const counter = this.$refs[this.timerId];

        if (counter instanceof HTMLElement) {
            import(/* webpackChunkName: "countdown-clock" */ '@assets/countdown-clock').then(
                ({ default: countdownClock }) => {
                    const countDown = countdownClock.init(counter, this.isDaysInHours);

                    if (countDown) {
                        countDown.start();
                    }
                }
            );

            window.addEventListener(STOP_COUNTING_EVENT_NAME, this.onCountdownStop);

            this.hasStopCountingEventListener = true;
        }
    },

    beforeCreate() {
        this.STOP_COUNTING_EVENT_NAME = STOP_COUNTING_EVENT_NAME;
    },

    beforeDestroy() {
        if (this.hasStopCountingEventListener) {
            window.removeEventListener(STOP_COUNTING_EVENT_NAME, this.onCountdownStop);
        }
    },

    methods: {
        onCountdownStop({ detail }) {
            this.$emit('hide-timer-section', detail.element);
        },
    },
};
</script>

<style lang="scss" scoped>
$timer-text-line-height: 10px;
$time-description-padding-right-desktop: 18px;
$seconds-column-width-mobile: 34px;
$seconds-column-width-desktop: 42px;
$seconds-column-width-container: 54px;

.countdown-timer {
    .timer {
        @apply flex font-semibold text-new-l leading-new-l;
    }

    [data-days],
    [data-hours],
    [data-minutes] {
        &::after {
            @apply font-book px-2p;
            content: ':';
        }
    }

    .time-description {
        @apply text-xxs font-book text-center pr-12p;
        line-height: $timer-text-line-height;
    }

    .time-column {
        @apply flex flex-col;

        &.seconds {
            width: $seconds-column-width-mobile;

            .time-description {
                @apply pr-0;
            }
        }
    }

    @screen lg {
        .timer {
            @apply text-xl;
        }

        [data-days],
        [data-hours],
        [data-minutes] {
            &::after {
                @apply px-1;
            }
        }

        .time-description {
            @apply text-xs leading-xxs;
            padding-right: $time-description-padding-right-desktop;
        }

        .time-column {
            &.seconds {
                width: $seconds-column-width-desktop;
            }
        }
    }

    @screen xl {
        .timer {
            @apply text-new-xxl;
        }

        .time-description {
            @apply leading-s pr-20p;
        }

        .time-column {
            &.seconds {
                width: $seconds-column-width-container;
            }
        }
    }
}
</style>
