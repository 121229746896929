<template>
    <SimpleSliderSlide
        v-if="hasAllRequiredValues"
        :scroll-snap-on-mobile="true"
        class="marketing-influencer-slider-slide"
    >
        <div class="influencer-card">
            <div class="picture-container">
                <BasePicture
                    class="slide-image"
                    :width="image.width"
                    :height="image.height"
                    :src="image.url"
                    :alt="image.alt"
                    :sources="image.sources"
                    :is-lazy="true"
                />
            </div>

            <div class="picture-footer">
                <BasePicture
                    class="avatar"
                    :width="avatarImage.width"
                    :height="avatarImage.height"
                    :src="avatarImage.url"
                    :alt="avatarImage.alt"
                    :sources="avatarImage.sources"
                    :is-lazy="true"
                />
                <div class="influencer-info">
                    <BaseHeading class="influencer-name">
                        {{ influencerName }}
                    </BaseHeading>
                    <BaseParagraph class="products-count">
                        {{ productsCount }}
                    </BaseParagraph>
                </div>
            </div>
        </div>

        <div class="products-container">
            <CustomProductsWrapper
                #default="{ products }"
                :product-skus="productSkusArray"
                :with-stocks="true"
            >
                <VerticalProductsSimpleSlider
                    :products="products"
                    :items-count="4"
                    slider-class="marketing-influencer-products-slider"
                />
            </CustomProductsWrapper>
        </div>
    </SimpleSliderSlide>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BasePicture from '@atoms/BasePicture/BasePicture';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';
import SimpleSliderSlide from '@molecules/SimpleSlider/SimpleSliderSlide';
import VerticalProductsSimpleSlider from '@organisms/VerticalProductsSimpleSlider/VerticalProductsSimpleSlider';
import {
    DEFAULT_IMAGE_FORMAT,
    MARKETING_INFLUENCER_IMAGE_TYPES as IMAGE_TYPES,
    IMAGE_TYPE_MARKETING_INFLUENCER_513w_684h,
    IMAGE_TYPE_MARKETING_AVATAR_32w_32h,
    SOURCE_IMAGE_FORMATS,
} from '@types/Image';
import { parseArrayString } from '@modules/page-builder/helpers/component';
import CustomProductsWrapper from '@organisms/CustomProductsWrapper/CustomProductsWrapper';

export default {
    name: 'MarketingInfluencerSliderSlide',

    components: {
        CustomProductsWrapper,
        BaseHeading,
        BasePicture,
        BaseParagraph,
        SimpleSliderSlide,
        VerticalProductsSimpleSlider,
    },

    props: {
        influencerName: {
            type: String,
            required: true,
        },

        influencerAvatarUrl: {
            type: String,
            required: true,
        },

        imageUrl: {
            type: String,
            required: true,
        },

        productSkus: {
            type: String,
            required: true,
        },

        productsCount: {
            type: String,
            required: true,
        },
    },

    computed: {
        hasAllRequiredValues() {
            return (
                this.influencerName &&
                this.influencerAvatarUrl &&
                this.imageUrl &&
                this.productSkus &&
                this.productsCount
            );
        },

        productSkusArray() {
            return parseArrayString(this.productSkus);
        },

        image() {
            return this.$imaginator.getImage(
                this.imageUrl,
                'marketing-influencer',
                this.influencerName,
                IMAGE_TYPE_MARKETING_INFLUENCER_513w_684h,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS,
                Object.keys(IMAGE_TYPES)
            );
        },

        avatarImage() {
            return this.$imaginator.getImage(
                this.influencerAvatarUrl,
                'marketing-avatar',
                this.influencerName,
                IMAGE_TYPE_MARKETING_AVATAR_32w_32h,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS,
                [IMAGE_TYPE_MARKETING_AVATAR_32w_32h]
            );
        },
    },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';

$container-padding-desktop: $tailwindcss-spacing-5;
$slide-image-ratio: math.div(4, 3);
$slide-image-footer: 80px;
$slide-image-border-height: 2px;

$slide-image-width-lg: math.div(1, 3);
$slide-image-width-md: math.div(1, 2);

$slide-mobile-height: 110px;
$slides-show-on-mobile: 3;

$column-gutter: 32px;

$edge-slides-extra-margin: 8px;
$vertical-slider-extra-margin: 2 * $edge-slides-extra-margin;

$container-content-max-width: #{theme('container.maxWidth')};

@mixin products-slider-height(
    $container-max-width,
    $container-padding,
    $slide-image-width,
    $border-height,
    $column-gutter: 0px
) {
    height: calc(
        (
                ((#{$container-max-width} - (#{$container-padding} * 2)) * #{$slide-image-width}) *
                    #{$slide-image-ratio} - #{$column-gutter}
            ) + #{$slide-image-footer} - (2 * #{$border-height})
    );
}

$mobile-slide-padding: $tailwindcss-spacing-3;

.marketing-influencer-slider-slide {
    @apply flex flex-col shrink-0 w-full pr-3 outline-none;
    width: calc(100% - #{$mobile-slide-padding});

    &:last-of-type {
        @apply pr-5;
        flex-basis: 100%;
    }

    &.active {
        .influencer-card {
            .picture-footer {
                @apply bg-tertiary;

                .influencer-name {
                    @apply text-light;
                }

                .products-count {
                    @apply text-gray3;
                }
            }
        }
    }

    .influencer-card {
        .picture-footer {
            @apply flex py-12p bg-light;

            .influencer-name {
                @apply text-tertiary;
            }

            .products-count {
                @apply text-text;
            }
        }

        &:deep() {
            .avatar {
                @apply w-5 h-5 mx-12p self-center rounded-50 overflow-hidden;
            }
        }
    }

    .products-container {
        @apply mt-3;
    }

    &:deep() {
        .vertical-products-simple-slider {
            .marketing-influencer-products-slider {
                @apply bg-light;
                height: $slide-mobile-height * $slides-show-on-mobile +
                    $vertical-slider-extra-margin;
                border-top-width: $slide-image-border-height;
                border-bottom-width: $slide-image-border-height;

                .vertical-products-simple-slider-slide {
                    &:first-of-type {
                        .vertical-slider-product {
                            @apply mt-3;
                        }
                    }

                    &:last-of-type {
                        .vertical-slider-product {
                            @apply mb-3;
                        }
                    }
                }
            }

            .custom-products-wrapper {
                @apply block bg-gray4;
                height: $slide-mobile-height * $slides-show-on-mobile;
            }
        }
    }

    @screen md {
        @apply flex-row w-full pr-0 h-auto;

        &:last-of-type {
            @apply pr-0;
        }

        .influencer-card,
        .products-container {
            width: calc((100% / 2) - (#{$column-gutter} / 2));
        }

        .influencer-card {
            .influencer-name {
                @apply text-m leading-m;
            }
        }

        .products-container {
            @apply ml-5 mt-0;
        }

        &:deep() {
            .vertical-products-simple-slider {
                .marketing-influencer-products-slider,
                .custom-products-wrapper {
                    @include products-slider-height(
                        100vw,
                        $container-padding-desktop,
                        $slide-image-width-md,
                        $slide-image-border-height
                    );
                }
            }
        }
    }

    @screen lg {
        @apply mr-5;
        width: calc((100% - (#{$column-gutter} / 2)) * 2 / 3);

        &:last-of-type {
            padding-right: calc(
                ((100% - (#{$column-gutter} / 2)) * 1 / 3) + (#{$column-gutter} / 2)
            );
        }

        .influencer-card,
        .products-container {
            @apply shrink-0;
            width: calc((100% / 2) - (#{$column-gutter} / 2));
        }

        .products-container {
            @apply px-0 ml-5;
        }

        &:deep() {
            .vertical-products-simple-slider {
                .marketing-influencer-products-slider,
                .custom-products-wrapper {
                    @include products-slider-height(
                        100vw,
                        $container-padding-desktop,
                        $slide-image-width-lg,
                        $slide-image-border-height,
                        $column-gutter
                    );
                }

                .marketing-influencer-products-slider {
                    .vertical-products-simple-slider-slide {
                        &:first-of-type {
                            .vertical-slider-product {
                                @apply mt-5;
                            }
                        }

                        &:last-of-type {
                            .vertical-slider-product {
                                @apply mb-5;
                            }
                        }
                    }
                }
            }
        }
    }

    @screen container {
        &:deep() {
            .vertical-products-simple-slider {
                .marketing-influencer-products-slider,
                .custom-products-wrapper {
                    @include products-slider-height(
                        $container-content-max-width,
                        $container-padding-desktop,
                        $slide-image-width-lg,
                        0px,
                        $column-gutter
                    );
                }
            }
        }
    }
}
</style>
