<template>
    <div class="marketplace-cooperate-with-us-item">
        <div class="image">
            <BasePicture
                :width="image.width"
                :height="image.height"
                :src="image.url"
                :alt="image.alt"
                :sources="image.sources"
                :is-lazy="true"
            />
        </div>
        <BaseHeading class="heading" tag="h2" theme="h5">
            {{ heading }}
        </BaseHeading>
        <BaseParagraph class="text">
            {{ text }}
        </BaseParagraph>
    </div>
</template>

<script>
import {
    DEFAULT_IMAGE_FORMAT,
    MARKETPLACE_COOPERATE_WITH_US_TYPES as IMAGE_TYPES,
    SOURCE_IMAGE_FORMATS,
    IMAGE_TYPE_MARKETPLACE_COOPERATE_WITH_US_300w_300h,
} from '@types/Image';

import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';
import BasePicture from '@atoms/BasePicture/BasePicture';

export default {
    name: 'MarketplaceCooperateWithUs',

    components: {
        BaseHeading,
        BaseParagraph,
        BasePicture,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        text: {
            type: String,
            required: true,
        },

        imageUrl: {
            type: String,
            required: true,
        },
    },

    computed: {
        image() {
            return this.$imaginator.getImage(
                this.imageUrl,
                'marketplace_cooperate_with_us',
                this.heading,
                IMAGE_TYPES[IMAGE_TYPE_MARKETPLACE_COOPERATE_WITH_US_300w_300h].name,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.marketplace-cooperate-with-us-item {
    @apply flex flex-col items-center;

    &:not(:first-child) {
        @apply mt-4;
    }

    max-width: theme('customVariables.marketplaceCooperateWithUsItem.maxWidth');

    .image {
        @apply rounded-50 mb-2 overflow-hidden;

        width: theme('customVariables.marketplaceCooperateWithUsItem.imageWidthMobile');
        height: theme('customVariables.marketplaceCooperateWithUsItem.imageHeightMobile');
    }

    .heading {
        @apply mb-2 text-center;
    }

    .text {
        @apply text-center;
    }

    @screen md {
        width: calc((100% / 3) - #{theme('spacing.5')} * 2 / 3);

        &:not(:first-child) {
            @apply ml-5 mt-0;
        }
    }

    @screen lg {
        width: calc((100% / 3) - #{theme('spacing.9')} * 2 / 3);

        &:not(:first-child) {
            @apply ml-9;
        }

        .image {
            @apply mb-3;

            width: theme('customVariables.marketplaceCooperateWithUsItem.imageWidthDesktop');
            height: theme('customVariables.marketplaceCooperateWithUsItem.imageHeightDesktop');
        }

        .heading {
            @apply mb-3;
        }
    }
}
</style>
