<template>
    <FAQList v-bind="propsToBind" :class="cssClass">
        <template #default>
            <PageBuilderInterpreter :components-json="slotDefault" />
        </template>
        <template v-if="!!slotFooter" #footer>
            <PageBuilderInterpreter :components-json="slotFooter" />
        </template>
    </FAQList>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';

import FAQList from '@organisms/FAQList/FAQList';

export default {
    name: 'CmsFAQList',

    components: {
        FAQList,
        PageBuilderInterpreter,
    },

    extends: CmsBaseComponent,

    props: {
        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },

        slotFooter: {
            type: String,
            default: JSON.stringify(''),
        },

        ...getProps(FAQList),
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                slots: {
                    default: '',
                    footer: '',
                },
                props: {
                    heading: '',
                },
            },
        };
    },
};
</script>
