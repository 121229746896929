<template>
    <MarketingImageTilesSimpleSliderSlide v-bind="propsToBind" />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import MarketingImageTilesSimpleSliderSlide from '@molecules/MarketingImageTilesSimpleSlider/MarketingImageTilesSimpleSliderSlide';

export default {
    name: 'CmsMarketingImageTilesSimpleSliderSlide',

    components: {
        MarketingImageTilesSimpleSliderSlide,
    },

    extends: CmsBaseComponent,

    props: getProps(MarketingImageTilesSimpleSliderSlide),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    heading: '',
                    imageUrl: '',
                    url: '',
                },
            },
            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },
};
</script>
