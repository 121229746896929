<template>
    <PageBuilderInterpreter
        :tag="MarketingCardsSlider2"
        :components-json="slotDefault"
        :class="cssClass"
        :tag-props="propsToBind"
    />
</template>

<script>
import { PROMO_OFFERS_VIEW, PROMO_OFFERS_CLICK } from '@analytics-types/Events';

import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';

import { getMarketingCardsSlider2ComponentVersion } from '@assets/pagebuilder';

import MarketingCardsSlider2 from '@organisms/MarketingCardsSlider2/MarketingCardsSlider2';

export default {
    name: 'CmsMarketingCardsSlider2',

    components: {
        PageBuilderInterpreter,
    },

    extends: CmsBaseComponent,

    provide() {
        return {
            componentClick: this.componentClick,
        };
    },

    props: {
        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },
        ...getProps(MarketingCardsSlider2),
    },

    data() {
        return {
            MarketingCardsSlider2,
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                slots: {
                    default: '',
                },
                props: {
                    heading: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                staticClass: false,
            },

            observer: null,
        };
    },

    mounted() {
        if (this.disabled) {
            return;
        }

        this.observer = new IntersectionObserver(
            entries => {
                const isBlockIntersecting = entries.some(({ isIntersecting }) => isIntersecting);

                if (!isBlockIntersecting) {
                    return;
                }

                this.componentView();
            },
            {
                threshold: 0,
            }
        );

        this.observer.observe(this.$el);
    },

    destroyed() {
        this.observer?.disconnect();
    },

    methods: {
        componentClick({ subheading, id }) {
            const position = Object.keys(this.componentsList).indexOf(this.internalId);

            const offerPosition = JSON.parse(this.slotDefault).findIndex(el => el.id === id);

            this.$analytics.emit(PROMO_OFFERS_CLICK, {
                position,
                campaingName: this.heading,
                isFull: !!subheading,
                offerPosition,
            });
        },

        // eslint-disable-next-line vue/no-unused-properties
        componentView() {
            const position = Object.keys(this.componentsList).indexOf(this.internalId);

            const slotDefault = JSON.parse(this.slotDefault) || [];

            const isFull =
                getMarketingCardsSlider2ComponentVersion(null, { default: slotDefault }) === 'full';

            this.$analytics.emit(PROMO_OFFERS_VIEW, {
                position,
                campaingName: this.heading,
                isFull,
                offersCount: slotDefault.length,
            });
        },
    },
};
</script>
