<template>
    <Observer
        v-if="displayContainer"
        :options="CONTAINER_OBSERVER_OPTIONS"
        :observe-once="true"
        class="marketing-products-slider"
        :class="{ 'is-loading': !isLoaded }"
        @intersect="displayContent($event)"
    >
        <template v-if="isDisplayed">
            <Loader v-if="!isLoaded" />

            <CustomProductsDataProvider
                v-if="productSkus.length"
                #default="{ products: customProducts }"
                :product-skus="productSkus"
                :with-stocks="true"
                @loaded="onLoadedProducts($event)"
                @error="handleError()"
            >
                <ProductSimpleSlider
                    v-if="isLoaded"
                    :products="customProducts"
                    :items-count="itemsCount"
                    @product-click="onProductClick($event)"
                />
            </CustomProductsDataProvider>

            <RecommendedProductsDataProvider
                v-else
                :recommendation-type="recommendationType"
                :category-breadcrumbs="categoryBreadcrumbs"
                :product-sku="productSku"
                :custom-campaign-id="customCampaignId"
                @loaded="onLoadedProducts($event)"
                @error="handleError()"
            >
                <ProductSimpleSlider
                    v-if="isLoaded"
                    :products="products"
                    :items-count="itemsCount"
                    @product-click="onProductClick($event)"
                />
            </RecommendedProductsDataProvider>
        </template>
    </Observer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { ITEMS_COUNT } from '@configs/simple-slider';
import { DEFAULT_DEBOUNCE_TIME } from '@configs/recommendations';
import { PRODUCT_SLIDER_SLIDE_ATTRIBUTE } from '@configs/product';
import { VIEW_TIME_TO_SEND } from '@configs/analytics-events';

import { SYNERISE_RECOMMENDATION_TYPES } from '@types/Synerise';

import { IMPRESSION_CLICK, IMPRESSION_VIEW } from '@analytics-types/Events';

import { ProductBadge } from '@models/ProductBadge/ProductBadge';
import DataLayerProduct from '@models/Analytics/DataLayerProduct';

import { checkIfExistsInValuesMap } from '@assets/props';
import { mapProductToDataLayerProduct } from '@assets/recommendations';
import { debounceAggregate } from '@assets/debounce-aggregate';

import Loader from '@atoms/Loader/Loader';
import Observer from '@atoms/Observer/Observer';

import ProductSimpleSlider from '@molecules/ProductSimpleSlider/ProductSimpleSlider';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'MarketingProductsSlider',

    components: {
        Loader,
        Observer,
        ProductSimpleSlider,
        RecommendedProductsDataProvider: () => ({
            component: import(
                /* webpackChunkName: "recommended-products-data-provider" */
                '@molecules/RecommendedProductsDataProvider/RecommendedProductsDataProvider'
            ),
        }),

        CustomProductsDataProvider: () => ({
            component: import(
                /* webpackChunkName: "custom-products-data-provider" */
                '@molecules/CustomProductsDataProvider/CustomProductsDataProvider'
            ),
        }),
    },

    props: {
        recommendationType: {
            type: String,
            default: '',
            validator: checkIfExistsInValuesMap(SYNERISE_RECOMMENDATION_TYPES),
        },

        categoryBreadcrumbs: {
            type: Array,
            default: () => [],
        },

        productSku: {
            type: String,
            default: '',
        },

        itemsCount: {
            type: [Number, String],
            default: 6,
            validator: value => ITEMS_COUNT.includes(value),
        },

        customCampaignId: {
            type: String,
            default: '',
        },

        productsSkuList: {
            type: String,
            default: '',
        },

        promoActionBackground: {
            type: String,
            default: '',
        },

        promoActionTextColor: {
            type: String,
            default: '',
        },

        promoActionText: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            isDisplayed: false,
            isLoaded: false,
            displayContainer: true,
            products: [],
        };
    },

    computed: {
        ...mapConfigGetters(['currency']),

        productSkus() {
            const skus = this.productsSkuList.split(',');

            return skus.map(sku => sku.trim()).filter(sku => !!sku);
        },
    },

    watch: {
        async isLoaded(value) {
            if (!value) {
                return;
            }

            const productMap = new Map();

            this.slideObserver = new IntersectionObserver(
                (entries, observer) => {
                    entries.forEach(({ target, isIntersecting }) => {
                        const index = parseInt(
                            target.getAttribute(PRODUCT_SLIDER_SLIDE_ATTRIBUTE),
                            10
                        );

                        if (isIntersecting) {
                            if (productMap.has(index)) {
                                return;
                            }

                            const timeout = setTimeout(async () => {
                                observer.unobserve(target);
                                productMap.delete(index);

                                const product = this.products[index];

                                this.debouncedOnProductView({ product, index });
                            }, VIEW_TIME_TO_SEND);

                            productMap.set(index, timeout);
                        } else {
                            clearTimeout(productMap.get(index));
                            productMap.delete(index);
                        }
                    });
                },
                {
                    threshold: 0.5,
                }
            );

            await this.$nextTick();

            const slides = this.$el.querySelectorAll(`[${PRODUCT_SLIDER_SLIDE_ATTRIBUTE}]`);

            slides.forEach(slide => {
                this.slideObserver.observe(slide);
            });
        },
    },

    beforeCreate() {
        this.CONTAINER_OBSERVER_OPTIONS = {
            root: null,
            threshold: 0,
            rootMargin: '0px 0px 200px 0px',
        };

        this.debouncedOnProductView = debounceAggregate(products => {
            this.sendProductViewsToAnalytics(products);
        }, DEFAULT_DEBOUNCE_TIME);
    },

    mounted() {
        if (!this.recommendationType && !this.productsSkuList) {
            this.displayContainer = false;
            this.isDisplayed = false;
        }
    },

    methods: {
        displayContent(intersect) {
            if (intersect) {
                this.isDisplayed = true;
            }
        },

        handleError() {
            this.displayContainer = false;
            this.isDisplayed = false;
        },

        onLoadedProducts({ products }) {
            if (!products.length) {
                this.displayContainer = false;

                return;
            }

            this.products = products.map(product => ({
                ...product,
                productBadgeConfig: [
                    new ProductBadge({
                        backgroundColor: this.promoActionBackground,
                        color: this.promoActionTextColor,
                        content: this.promoActionText,
                    }).getPlainObject(),
                ],
                hasProductBadge: true,
            }));

            this.isLoaded = true;
        },

        sendProductViewsToAnalytics(products) {
            const productsToSend = products.flat().map(({ product, index }) => {
                let dataLayerProducts = {};

                if (this.productSkus.length) {
                    dataLayerProducts = new DataLayerProduct({
                        product,
                        position: index + 1,
                    }).build();
                } else {
                    dataLayerProducts = mapProductToDataLayerProduct({ product, index });
                }

                const list = this.recommendationType || null;

                return {
                    ...dataLayerProducts,
                    list,
                };
            });

            this.$analytics.emit(IMPRESSION_VIEW, {
                currency: this.currency,
                products: productsToSend,
                route: this.$route,
            });
        },

        onProductClick({ product, index }) {
            let analyticsProduct = {};

            if (this.productSkus.length) {
                analyticsProduct = new DataLayerProduct({
                    product,
                    position: index + 1,
                }).build();
            } else {
                analyticsProduct = mapProductToDataLayerProduct({
                    product,
                    index,
                });
            }

            const actionFieldList = this.recommendationType || null;

            this.$analytics.emit(IMPRESSION_CLICK, {
                currency: this.currency,
                product: analyticsProduct,
                actionFieldList,
                route: this.$route,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';

$heading-height-mobile: 28px + $tailwindcss-spacing-3;
$heading-height-tablet: 32px + $tailwindcss-spacing-3;
$heading-height-lg-min: 40px + $tailwindcss-spacing-3;

$min-height-mobile: 331px;
$min-height-lg-min: 321px;
$min-height-container: 409px;

$multiplier: math.div(100px, 22px);

@mixin min-height-lg-min($min-height, $multiplier) {
    min-height: calc(#{$min-height} + ((100vw - 1024px) / #{$multiplier}));
}

.marketing-products-slider {
    @apply flex items-center;
    min-height: $min-height-mobile;

    &.is-loading {
        @apply bg-gray4;
    }

    @screen md {
        @include min-height-lg-min($min-height-lg-min, $multiplier);
    }

    @screen container {
        min-height: $min-height-container;
    }
}
</style>
