<template>
    <ButtonWithIcon
        :size="buttonSize"
        :show-right-slot="false"
        :disabled="isLoading"
        :data-test-id="ADD_TO_WISHLIST_BUTTON"
        :class="{ added: isAdded, 'only-icon': onlyIcon, small: isSmall }"
        class="button-add-to-wishlist"
        theme="pure"
        @click.prevent.stop="$emit('toggle')"
    >
        <template #left>
            <SvgIcon :width="iconSize" :height="iconSize">
                <Heart />
            </SvgIcon>
        </template>
        <span v-if="!onlyIcon">{{ title }}</span>
    </ButtonWithIcon>
</template>

<script>
import { ADD_TO_WISHLIST_BUTTON } from '@types/AutomaticTestIDs';

import Heart from '@static/icons/24px/heart.svg?inline';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';

import ButtonWithIcon from '@molecules/ButtonWithIcon/ButtonWithIcon';

export default {
    name: 'ButtonAddToWishlist',

    components: {
        SvgIcon,
        ButtonWithIcon,
        Heart,
    },

    props: {
        onlyIcon: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        isAdded: {
            type: Boolean,
            default: false,
        },
        isSmall: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        title() {
            const { isLoading, isAdded } = this;

            if (isLoading) {
                return this.$t('Loading...');
            }

            if (isAdded) {
                return this.$t('Added to favorites');
            }

            return this.$t('Add to favorites');
        },
        iconSize() {
            return this.isSmall ? '16px' : '24px';
        },
        buttonSize() {
            return this.isSmall ? 'small' : 'normal';
        },
    },

    beforeCreate() {
        this.ADD_TO_WISHLIST_BUTTON = ADD_TO_WISHLIST_BUTTON;
    },
};
</script>

<style lang="scss" scoped>
.button-add-to-wishlist {
    &.normal {
        @apply px-0;
    }

    .left {
        @apply mr-0;
    }

    &:deep() {
        .content {
            @apply ml-12p text-gray1 text-m;
        }
        .right {
            @apply hidden;
        }
    }

    &.added {
        &:deep() {
            .content {
                @apply text-primary;
            }
        }
    }

    &.small {
        &:deep() {
            .content {
                @apply ml-2 text-gray1 text-s font-book;
            }
        }
    }
}
</style>
