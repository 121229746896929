<template>
    <PageBuilderInterpreter
        :tag="MarketingPopularCategories"
        :components-json="slotDefault"
        :class="cssClass"
        :tag-props="propsToBind"
    />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import MarketingPopularCategories from '@organisms/MarketingPopularCategories/MarketingPopularCategories';
import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';

export default {
    name: 'CmsMarketingPopularCategories',

    components: {
        PageBuilderInterpreter,
    },

    extends: CmsBaseComponent,

    props: {
        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },
        ...getProps(MarketingPopularCategories),
    },

    data() {
        return {
            MarketingPopularCategories,
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                slots: {
                    default: '',
                },
                props: {
                    heading: '',
                },
            },
        };
    },
};
</script>
