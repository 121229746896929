<template>
    <div tabindex="1" class="base-tag" :disabled="disabled">
        <slot />
        <ButtonIcon class="close-button" :disabled="disabled" @click="$emit('close')">
            <SvgIcon width="16px" height="16px">
                <Close2 />
            </SvgIcon>
        </ButtonIcon>
    </div>
</template>

<script>
import ButtonIcon from '@atoms/ButtonIcon/ButtonIcon';
import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import Close2 from '@static/icons/16px/close2.svg?inline';

export default {
    name: 'BaseTag',
    components: {
        SvgIcon,
        ButtonIcon,
        Close2,
    },
    props: {
        /**
         * Disabled attribute.
         */
        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.base-tag {
    @apply pl-3 rounded-16 leading-s border-solid border-1
    border-gray1 text-s text-gray1 font-book inline-flex items-center cursor-default pt-1 pb-1;

    &:hover {
        @apply border-gray3;
    }

    &:focus {
        @apply outline-none shadow-7;
    }

    &[disabled] {
        @apply bg-inactive text-label border-border pointer-events-none;
        &:hover {
            @apply border-1;
        }

        .close-button {
            span {
                svg {
                    @apply opacity-40;
                }
            }
        }
    }

    .close-button {
        @apply mx-3;

        &:focus {
            @apply outline-none;
        }
    }

    &:deep() {
        .button-icon {
            &:active {
                @apply bg-t;

                .svg-icon {
                    stroke: theme('colors.tertiary');
                }

                &:hover {
                    @apply bg-t;
                }
            }
            &:hover {
                .svg-icon {
                    stroke: theme('colors.tertiary');
                }
            }
        }
    }
}
</style>
