<script>
import { h } from 'vue';

export default {
    props: {
        isSale: {
            type: Boolean,
            required: true,
        },

        dataTestId: {
            type: String,
            required: true,
        },

        htmlElement: {
            type: String,
            required: true,
        },
    },
    render() {
        return h('div', {
            class: {
                price: true,
                'is-sale': this.isSale,
            },
            attrs: {
                'data-test-id': this.dataTestId,
            },
            domProps: {
                innerHTML: this.htmlElement,
            },
        });
    },
};
</script>
