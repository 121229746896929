<template>
    <MarketingHero v-bind="propsToBind" />
</template>

<script>
import { THEMES } from '@types/MarketingBanner';

import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';

import MarketingHero from '@organisms/MarketingHeroBanner/MarketingHero';

export default {
    name: 'CmsMarketingHero',

    components: {
        MarketingHero,
    },

    extends: CmsBaseComponent,

    props: getProps(MarketingHero),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    heading: '',
                    description: '',
                    buttonLink: '',
                    buttonLabel: '',
                    imageUrl: '',
                    isReversed: '',
                    timerId: '',
                    date: '',
                    theme: THEMES.THEME_LIGHT,
                    customBackground: '',
                    eventTitle: '',
                    eventDates: '',
                    eventDetails: '',
                    eventAddress: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
                theme: Object.values(THEMES),
            },
            // eslint-disable-next-line vue/no-unused-properties
            propsCustomFormConfig: {
                imageUrl: {
                    isImageRelativeUrl: true,
                },
            },
        };
    },
};
</script>
