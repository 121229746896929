<template>
    <CustomProductsWrapper
        #default="{ products }"
        class="custom-products-simple-slider-wrapper"
        :product-skus="skus"
        :with-stocks="true"
    >
        <ProductsSimpleSliderWrapper :items-count="itemsCount" :products="products" />
    </CustomProductsWrapper>
</template>

<script>
import { ITEMS_COUNT } from '@configs/simple-slider';

import { parseArrayString } from '@modules/page-builder/helpers/component';

import ProductsSimpleSliderWrapper from '@molecules/ProductsSimpleSliderWrapper/ProductsSimpleSliderWrapper';

import CustomProductsWrapper from '@organisms/CustomProductsWrapper/CustomProductsWrapper';

export default {
    name: 'CustomProductsSimpleSliderWrapper',

    components: {
        CustomProductsWrapper,
        ProductsSimpleSliderWrapper,
    },

    props: {
        productSkus: {
            type: String,
            default: '',
        },

        itemsCount: {
            type: Number,
            default: 6,
            validator: value => ITEMS_COUNT.includes(value),
        },
    },

    computed: {
        skus() {
            return parseArrayString(this.productSkus);
        },
    },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';

$min-height-mobile: 380px;
$min-height-lg-min: 374px;
$min-height-container: 510px;

$multiplier: math.div(100px, 22px);

@mixin min-height-lg-min($min-height, $multiplier) {
    min-height: calc(#{$min-height} + ((100vw - 1024px) / #{$multiplier}));
}

.custom-products-simple-slider-wrapper {
    @apply flex items-center;
    min-height: $min-height-mobile;

    &.is-loading {
        @apply bg-gray4;
    }

    @screen lg {
        @include min-height-lg-min($min-height-lg-min, $multiplier);
    }

    @screen container {
        min-height: $min-height-container;
    }
}
</style>
