<template>
    <BaseLink :path="url" class="marketing-simple-picture-box">
        <div>
            <BasePicture
                class="picture"
                :alt="image.alt"
                :src="image.url"
                :height="image.height"
                :width="image.width"
            />
        </div>
        <div class="label">
            {{ label }}
        </div>
    </BaseLink>
</template>

<script>
import BaseLink from '@atoms/BaseLink/BaseLink';
import BasePicture from '@atoms/BasePicture/BasePicture';

import { IMAGE_TYPE_CART, DEFAULT_IMAGE_FORMAT, SOURCE_IMAGE_FORMATS } from '@types/Image';

export default {
    name: 'MarketingSimplePictureBox',

    components: { BasePicture, BaseLink },

    props: {
        label: {
            type: String,
            required: true,
        },

        imageUrl: {
            type: String,
            required: true,
        },

        url: {
            type: String,
            required: true,
        },
    },

    computed: {
        image() {
            return this.$imaginator.getImage(
                this.imageUrl,
                `${this.label}`,
                `${this.label}`,
                IMAGE_TYPE_CART,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS
            );
        },
    },
};
</script>

<style scoped lang="scss">
$picture-width-mobile: theme('customVariables.pictureBox.imageWidthMobile');
$picture-width-desktop: theme('customVariables.pictureBox.imageWidthDesktop');
.marketing-simple-picture-box {
    @apply flex border-1 border-gray4 border-solid;

    .picture {
        width: $picture-width-mobile;
    }

    .label {
        @apply flex-grow flex items-center mx-4;
        @apply text-s leading-s;
    }

    &:hover {
        @apply no-underline;
    }

    @screen md {
        .picture {
            width: $picture-width-desktop;
        }

        .label {
            @apply text-m leading-m;
        }
    }
}
</style>
