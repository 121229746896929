<template>
    <div class="promo-action-redirect" :class="theme" :style="{ backgroundColor }">
        <BaseHeading v-if="heading" class="heading" theme="h5">
            {{ heading }}
        </BaseHeading>

        <BaseParagraph v-if="text" class="text">
            {{ text }}
        </BaseParagraph>

        <BaseButton
            v-if="buttonLink && buttonText"
            :to="buttonLink"
            :theme="buttonTheme"
            class="button"
        >
            {{ buttonText }}
        </BaseButton>
    </div>
</template>

<script>
import { THEMES, BUTTON_THEMES } from '@types/PromoActionRedirect';

import { checkIfExistsInValuesMap } from '@assets/props';

import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';
import BaseButton from '@atoms/BaseButton/BaseButton';

export default {
    name: 'PromoActionRedirect',

    components: {
        BaseHeading,
        BaseParagraph,
        BaseButton,
    },

    props: {
        heading: {
            type: String,
            default: '',
        },

        backgroundColor: {
            type: String,
            default: '',
        },

        text: {
            type: String,
            default: '',
        },

        buttonText: {
            type: String,
            default: '',
        },

        buttonLink: {
            type: String,
            default: '',
        },

        theme: {
            type: String,
            default: THEMES.THEME_LIGHT,
            validator: checkIfExistsInValuesMap(THEMES),
        },

        buttonTheme: {
            type: String,
            default: BUTTON_THEMES.THEME_PRIMARY,
            validator: checkIfExistsInValuesMap(BUTTON_THEMES),
        },
    },
};
</script>

<style lang="scss" scoped>
.promo-action-redirect {
    @apply text-center p-5;

    .text {
        @apply text-center text-s leading-s text-gray1 mt-2p;
    }

    .button {
        @apply mt-4;
    }

    &.light {
        .heading,
        .text {
            @apply text-light;
        }
    }

    @screen lg {
        .text {
            @apply text-r leading-r;
        }
    }
}
</style>
