<template>
    <div class="vertical-products-simple-slider">
        <ContainerContent class="slider-container">
            <SimpleSlider
                orientation="vertical"
                :items-count="itemsCount"
                :additional-scroll-offset="0"
                class="slider"
                :slider-class="sliderClass"
                :navigation-buttons-styles="navigationButtonsStyles"
                :horizontal-navigation-buttons="false"
            >
                <template #slides>
                    <VerticalProductsSimpleSliderSlide
                        v-for="product in products"
                        :key="`vertical-products-simple-slider-slide-${product.sku}`"
                        :product="product"
                        class="item"
                    />
                </template>
            </SimpleSlider>
        </ContainerContent>
    </div>
</template>

<script>
import debounce from 'lodash.debounce';

import { md as BREAKPOINT_MD } from '@theme/resources/variables/responsiveBreakpoints.json';

import SimpleSlider from '@molecules/SimpleSlider/SimpleSlider';
import ContainerContent from '@molecules/ContainerContent/ContainerContent';

import VerticalProductsSimpleSliderSlide from '@organisms/VerticalProductsSimpleSlider/VerticalProductsSimpleSliderSlide';

const RESIZE_DEBOUNCE = 150;

export default {
    name: 'VerticalProductsSimpleSlider',

    components: {
        SimpleSlider,
        VerticalProductsSimpleSliderSlide,
        ContainerContent,
    },

    props: {
        products: {
            type: Array,
            default: () => [],
        },

        sliderClass: {
            type: String,
            default: '',
        },

        itemsCount: {
            type: Number,
            required: true,
        },
    },

    data: () => ({
        navigationButtonsStyles: {},
    }),

    computed: {
        mobileDeviceBreakpoint() {
            return parseInt(BREAKPOINT_MD, 10) - 1;
        },
    },

    mounted() {
        this.getNavigationButtonsStyles();
        this.onResizeFn = debounce(this.getNavigationButtonsStyles, RESIZE_DEBOUNCE);
        window.addEventListener('resize', this.onResizeFn, false);
    },

    beforeDestroy() {
        this.onResizeFn.cancel();
        window.removeEventListener('resize', this.onResizeFn, false);
    },

    methods: {
        isMobileDevice() {
            return window.matchMedia(`(max-width: ${this.mobileDeviceBreakpoint}px)`).matches;
        },
        getNavigationButtonsStyles() {
            if (this.isMobileDevice()) {
                this.navigationButtonsStyles = { display: 'none' };

                return;
            }

            this.navigationButtonsStyles = {};
        },
    },
};
</script>

<style lang="scss" scoped>
.vertical-products-simple-slider {
    &:deep() .simple-slider {
        &.vertical {
            .navigation-buttons {
                @apply flex-col justify-between w-7 -top-50 left-1/2 bg-transparent;
                height: calc(100% + 100px);
                transform: translateX(-50%);

                .navigation-button {
                    @apply h-7;
                }

                .button-icon {
                    .svg {
                        @apply text-tertiary;
                    }
                }
            }

            .simple-slider-slide {
                &:first-of-type {
                    .vertical-slider-product {
                        @apply mt-2;
                    }
                }

                &:last-of-type {
                    .vertical-slider-product {
                        @apply mb-2;
                    }
                }

                @screen lg {
                    &:first-of-type {
                        .vertical-slider-product {
                            @apply mt-4;
                        }
                    }

                    &:last-of-type {
                        .vertical-slider-product {
                            @apply mb-4;
                        }
                    }
                }
            }
        }
    }
}
</style>
