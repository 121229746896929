<template>
    <section v-if="hasAllRequiredValues" class="marketing-influencer-slider">
        <ContainerContent class="marketing-influencer-container">
            <div class="wrapper">
                <MarketingInfluencerSliderHeadings :heading="heading" :sub-heading="subHeading" />

                <SimpleSlider
                    :items-count="2"
                    :horizontal-navigation-buttons="false"
                    class="main-influencer-slider"
                >
                    <template #slides>
                        <slot />
                    </template>
                </SimpleSlider>
            </div>
        </ContainerContent>
    </section>
</template>

<script>
/* eslint-disable max-len */
import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import SimpleSlider from '@molecules/SimpleSlider/SimpleSlider';
import MarketingInfluencerSliderHeadings from './MarketingInfluencerSliderHeadings';

export default {
    name: 'MarketingInfluencerSlider',

    components: {
        ContainerContent,
        MarketingInfluencerSliderHeadings,
        SimpleSlider,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        subHeading: {
            type: String,
            required: true,
        },
    },

    computed: {
        hasAllRequiredValues() {
            return this.heading && this.subHeading;
        },
    },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';

$container-padding-desktop: $tailwindcss-spacing-5;
$slide-image-footer: 80px;
$slide-image-width: math.div(1, 3);
$navigation-width: 96px;
$container-content-max-width: #{theme('container.maxWidth')};

$column-gutter: 32px;

@mixin right-edge-of-navigation($container-max-width) {
    right: calc(
        (#{$container-max-width} - #{$container-padding-desktop} * 2) * #{$slide-image-width} / 2 - (#{$column-gutter} /
                    2)
    );
}

.marketing-influencer-slider {
    .wrapper {
        @apply w-full;
    }

    &:deep() .main-influencer-slider {
        @apply relative;

        .container-content {
            @apply px-0;
        }

        @screen md {
            .wrapper.horizontal {
                @apply py-7;
            }

            .navigation-buttons {
                @apply flex;
                left: calc(50% - #{$navigation-width} - #{$tailwindcss-spacing-3});

                .button-icon {
                    .svg {
                        @apply text-light;
                    }
                }
            }

            .navigation-button {
                @apply bg-transparent;
            }
        }

        @screen lg {
            .navigation-buttons {
                @apply bg-light left-auto;
                @include right-edge-of-navigation(100vw);
                top: calc(50% - #{$slide-image-footer});
                transform: translateX(calc(#{$navigation-width} / 2));

                .button-icon {
                    .svg {
                        @apply text-tertiary;
                    }
                }
            }
        }

        @screen container {
            .navigation-buttons {
                @include right-edge-of-navigation($container-content-max-width);
            }
        }
    }

    @screen lg {
        .marketing-influencer-container {
            @apply px-5;
        }
    }
}
</style>
