export const PROMO_VIDEO_WIDTH = 485;
export const PROMO_VIDEO_HEIGHT = 647;

export const THEME_LIGHT = 'light';
export const THEME_DARK = 'dark';

export const THEMES = {
    THEME_LIGHT,
    THEME_DARK,
};

export const MEDIA_SIZE = {
    desktop: {
        width: 1600,
        height: 448,
    },
    mobile: {
        width: 1024,
        height: 1366,
    },
};
