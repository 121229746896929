<template>
    <section class="marketing-simple-picture-box-grid">
        <ContainerContent class="grid-container">
            <div class="grid-field">
                <slot />
            </div>
        </ContainerContent>
        <ButtonLarge
            v-if="isButtonEnabled"
            :variant="BUTTON_VARIANT"
            :to="buttonLink"
            class="grid-button"
        >
            {{ buttonLabel }}
        </ButtonLarge>
    </section>
</template>

<script>
import ContainerContent from '@molecules/ContainerContent/ContainerContent';

import { ButtonLarge, BUTTON_LARGE_VARIANTS } from '@modivo-ui/components/ButtonLarge/v1';

export default {
    name: 'MarketingSimplePictureBoxGrid',

    components: {
        ContainerContent,
        ButtonLarge,
    },

    props: {
        isButtonEnabled: {
            type: Boolean,
            default: false,
        },

        buttonLabel: {
            type: String,
            default: '',
        },

        buttonLink: {
            type: String,
            default: '',
        },
    },

    beforeCreate() {
        this.BUTTON_VARIANT = BUTTON_LARGE_VARIANTS.SECONDARY;
    },
};
</script>

<style scoped lang="scss">
.marketing-simple-picture-box-grid {
    @apply flex flex-col items-center;

    .grid-container {
        @apply px-0;
    }

    .grid-field {
        @apply grid grid-cols-2 gap-12p w-full;
    }

    .grid-button {
        @apply mt-4;
    }

    @screen lg {
        .grid-container {
            @apply px-5;
        }

        .grid-field {
            @apply grid-cols-4;
        }
    }
}
</style>
