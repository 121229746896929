<template>
    <div>
        <pre>{{ preview }}</pre>
    </div>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

export default {
    name: 'CmsAppMessageIconBannerV2',

    extends: CmsBaseComponent,

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        deeplink: {
            type: String,
            required: true,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        icon: {
            type: Object,
            default: () => ({
                light: '',
                dark: '',
            }),
        },

        // eslint-disable-next-line vue/no-unused-properties
        header: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        description: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        deeplinkInputTitle: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        contentColor: {
            type: Object,
            default: () => ({
                light: '',
                dark: '',
            }),
        },

        // eslint-disable-next-line vue/no-unused-properties
        backgroundColor: {
            type: Object,
            default: () => ({
                light: '',
                dark: '',
            }),
        },

        // eslint-disable-next-line vue/no-unused-properties
        buttonTextColor: {
            type: Object,
            default: () => ({
                light: '',
                dark: '',
            }),
        },

        // eslint-disable-next-line vue/no-unused-properties
        buttonBackgroundColor: {
            type: Object,
            default: () => ({
                light: '',
                dark: '',
            }),
        },

        // eslint-disable-next-line vue/no-unused-properties
        tag: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        slotVisibleRules: {
            type: String,
            default: JSON.stringify(''),
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            propsCustomFormConfig: {
                deeplink: {
                    isUrl: true,
                },

                icon: {
                    hasImageTheme: true,
                },

                contentColor: {
                    hasColorTheme: true,
                },

                backgroundColor: {
                    hasColorTheme: true,
                },

                buttonTextColor: {
                    hasColorTheme: true,
                },

                buttonBackgroundColor: {
                    hasColorTheme: true,
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    deeplink: '',
                },

                slots: {
                    visibleRules: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },

    computed: {
        preview() {
            return {
                ...this.propsToBind,
                slotVisibleRules: JSON.parse(this.slotVisibleRules) || '',
            };
        },
    },
};
</script>
