<template>
    <CustomProductsSimpleSliderWrapper
        v-bind="propsToBind"
        :class="cssClass"
        @loaded="onLoadedProducts($event)"
    />
</template>

<script>
import { ITEMS_COUNT } from '@configs/simple-slider';

import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';

import CustomProductsSimpleSliderWrapper from '@organisms/CustomProductsSimpleSliderWrapper/CustomProductsSimpleSliderWrapper';

export default {
    name: 'CmsCustomProductsSimpleSliderWrapper',

    components: {
        CustomProductsSimpleSliderWrapper,
    },

    extends: CmsBaseComponent,

    props: getProps(CustomProductsSimpleSliderWrapper),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    itemsCount: 6,
                },
            },
            // eslint-disable-next-line vue/no-unused-properties
            options: {
                itemsCount: Object.values(ITEMS_COUNT),
            },
        };
    },
};
</script>
