<template>
    <section class="marketing-multi-link-banner-small">
        <ContainerContent class="banner-container">
            <div class="wrapper">
                <div
                    :class="{
                        'image-container': true,
                        'image-container--with-mobile-image': !!mobileImage,
                    }"
                >
                    <BasePicture
                        class="desktop-image"
                        :width="image.width"
                        :height="image.height"
                        :src="image.url"
                        :alt="image.alt"
                        :sources="image.sources"
                        :is-lazy="true"
                    />
                    <BasePicture
                        v-if="mobileImage"
                        class="mobile-image"
                        :width="mobileImage.width"
                        :height="mobileImage.height"
                        :src="mobileImage.url"
                        :alt="mobileImage.alt"
                        :sources="mobileImage.sources"
                        :is-lazy="true"
                    />
                </div>
                <div class="details">
                    <BaseHeading class="heading" :tag="headingTag" :style="headingStyle">
                        {{ heading }}
                    </BaseHeading>
                    <div v-if="!!$slots.description" class="sub-heading">
                        <slot name="description" />
                    </div>
                    <slot name="links" />
                </div>
            </div>
        </ContainerContent>
    </section>
</template>

<script>
import { checkIfExistsInValuesMap } from '@assets/props';

import {
    IMAGE_TYPE_MARKETING_BANNER_768w_432h,
    DEFAULT_IMAGE_FORMAT,
    SOURCE_IMAGE_FORMATS,
} from '@types/Image';
import { HEADING_TAGS } from '@types/MarketingBanner';

import BasePicture from '@atoms/BasePicture/BasePicture';
import BaseHeading from '@atoms/BaseHeading/BaseHeading';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';

export default {
    name: 'MarketingMultiLinkBannerSmall',

    components: {
        ContainerContent,
        BasePicture,
        BaseHeading,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        imageUrl768x432: {
            type: String,
            required: true,
        },

        mobileImageUrl768x432: {
            type: String,
            default: '',
        },

        headingTag: {
            type: String,
            default: HEADING_TAGS.SPAN,
            validator: checkIfExistsInValuesMap(HEADING_TAGS),
        },

        headingColor: {
            type: String,
            default: '',
        },
    },

    computed: {
        image() {
            return this.$imaginator.getImage(
                this.imageUrl768x432,
                'marketing-banner',
                this.heading,
                IMAGE_TYPE_MARKETING_BANNER_768w_432h,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS,
                [IMAGE_TYPE_MARKETING_BANNER_768w_432h]
            );
        },

        mobileImage() {
            if (!this.mobileImageUrl768x432) {
                return null;
            }

            return this.$imaginator.getImage(
                this.mobileImageUrl768x432,
                'marketing-banner',
                this.heading,
                IMAGE_TYPE_MARKETING_BANNER_768w_432h,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS,
                [IMAGE_TYPE_MARKETING_BANNER_768w_432h]
            );
        },

        headingStyle() {
            return {
                color: this.headingColor || null,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-multi-link-banner-small {
    .banner-container {
        @apply px-0;
    }

    .wrapper {
        @apply flex w-full flex-col;
    }

    .image-container {
        @apply flex-1;

        &.image-container--with-mobile-image {
            .desktop-image {
                @apply hidden;
            }
        }
    }

    .details {
        @apply flex-1 py-4;
    }

    .heading {
        @apply text-l leading-l font-semibold text-tertiary text-center;
    }

    .sub-heading {
        @apply mt-4 text-r leading-r font-book text-tertiary text-center;
    }

    @screen md {
        .banner-container {
            @apply px-3;
        }

        .image-container {
            @apply mr-5;
            max-width: theme('customVariables.marketingMultiLinkBannerSmall.imageWidth');

            &,
            &.image-container--with-mobile-image {
                .desktop-image {
                    @apply block;
                }
            }

            .mobile-image {
                @apply hidden;
            }
        }

        .wrapper {
            @apply flex-row;
        }

        .details {
            @apply py-5;
        }

        .heading {
            @apply text-xl leading-xl text-left;
        }

        .sub-heading {
            @apply mt-5 text-m leading-m text-left;
        }
    }

    @screen lg {
        .banner-container {
            @apply px-5;
        }

        .heading {
            @apply text-xxl leading-xxxl;
        }
    }
}
</style>
