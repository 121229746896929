import { THEME_PRIMARY, THEME_SECONDARY, THEME_TRANSPARENT_WHITE } from '@types/Button';

export const THEME_LIGHT = 'light';
export const THEME_DARK = 'dark';

export const THEMES = {
    THEME_LIGHT,
    THEME_DARK,
};

export const BUTTON_THEMES = {
    THEME_PRIMARY,
    THEME_SECONDARY,
    THEME_TRANSPARENT_WHITE,
};
