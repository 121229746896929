<template>
    <div class="marketing-category-slider-box">
        <BasePicture
            :width="image.width"
            :height="image.height"
            :src="image.url"
            :alt="image.alt"
            :sources="image.sources"
            :is-lazy="true"
        />

        <MarketingCategorySliderHeading class="image-bottom" :link="link" :title="title" />
    </div>
</template>

<script>
import BasePicture from '@atoms/BasePicture/BasePicture';
import { IMAGE_TYPE_MARKETING_CATEGORY_318w_424h } from '@types/Image';
import MarketingCategorySliderHeading from '@organisms/MarketingCategorySlider/MarketingCategorySliderHeading';

export default {
    name: 'MarketingCategorySliderBox',

    components: {
        MarketingCategorySliderHeading,
        BasePicture,
    },

    props: {
        imageUrl: {
            type: String,
            required: true,
        },

        link: {
            type: String,
            required: true,
        },

        title: {
            type: String,
            required: true,
        },
    },

    computed: {
        image() {
            return this.$imaginator.getImage(
                this.imageUrl,
                'marketing-category-slider',
                this.title,
                IMAGE_TYPE_MARKETING_CATEGORY_318w_424h
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-category-slider-box {
    @apply relative h-full;

    .image-bottom {
        @apply absolute bottom-0 w-full text-center bg-overlay-loader inline-flex flex-col py-2;
    }
}
</style>
