<template>
    <SimpleSliderSlide v-if="hasAllRequiredValues" class="marketing-cards-slider-slide">
        <BaseLink :path="url" class="link">
            <BasePicture
                class="slide-image"
                :width="image.width"
                :height="image.height"
                :src="image.url"
                :alt="image.alt"
                :sources="image.sources"
                :is-lazy="true"
            />
            <BaseHeading class="sub-heading">
                {{ heading }}
            </BaseHeading>
            <BaseHeading class="heading">
                {{ subHeading }}
            </BaseHeading>
        </BaseLink>
    </SimpleSliderSlide>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BasePicture from '@atoms/BasePicture/BasePicture';
import BaseLink from '@atoms/BaseLink/BaseLink';
import SimpleSliderSlide from '@molecules/SimpleSlider/SimpleSliderSlide';
import {
    DEFAULT_IMAGE_FORMAT,
    MARKETING_CARD_IMAGE_TYPES as IMAGE_TYPES,
    SOURCE_IMAGE_FORMATS,
} from '@types/Image';

export default {
    name: 'MarketingCardsSliderSlide',

    components: {
        BaseHeading,
        BasePicture,
        BaseLink,
        SimpleSliderSlide,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        subHeading: {
            type: String,
            required: true,
        },

        imageUrl: {
            type: String,
            required: true,
        },

        url: {
            type: String,
            required: true,
        },
    },

    computed: {
        image() {
            return this.$imaginator.getImage(
                this.imageUrl,
                'marketing-card',
                `${this.heading} ${this.subHeading}`,
                IMAGE_TYPES.marketing_card_492w_656h.name,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS,
                Object.keys(IMAGE_TYPES)
            );
        },

        hasAllRequiredValues() {
            return this.subHeading && this.heading && this.imageUrl && this.url;
        },
    },
};
</script>

<style lang="scss" scoped>
$slide-min-width-mobile: 242px;
$slide-min-width-desktop: calc((100% / 3) - (32px * 2) / 3);

.marketing-cards-slider-slide {
    @apply mr-3;
    min-width: $slide-min-width-mobile;

    &:first-child {
        @apply ml-3;
    }

    &:last-child {
        @apply mr-0 pr-3;
        min-width: calc(#{$slide-min-width-mobile} + #{$tailwindcss-spacing-3});
    }

    .link {
        &:hover {
            @apply no-underline;
        }
    }

    .sub-heading {
        @apply block text-gray2 font-book mt-12p;
    }

    .heading {
        @apply block;
    }

    @screen lg {
        @apply mr-5;
        min-width: $slide-min-width-desktop;

        &:first-child {
            @apply ml-0;
        }

        &:last-child {
            @apply mr-0 pr-0;
            min-width: $slide-min-width-desktop;
        }

        &:hover {
            .slide-image {
                &:deep(.image) {
                    transform: scale(1.2) translateY(-4%);
                    transition: transform 0.2s;
                }
            }
        }

        .slide-image {
            &:deep(.image) {
                @apply overflow-hidden;
            }
        }
    }
}
</style>
