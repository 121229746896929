<template>
    <section v-if="!disabled" class="marketing-sale-bar" :style="styles">
        <div class="image-wrapper">
            <BasePicture
                v-if="backgroundImageUrl"
                class="image"
                :width="image.width"
                :height="image.height"
                :src="image.url"
                :alt="image.alt"
                :sources="image.sources"
                :sources-with-media="sourcesWithMedia"
                :is-lazy="true"
            />
        </div>
        <ContainerContent class="marketing-sale-bar-container" :class="{ small: description2 }">
            <BaseHeading class="heading" tag="h2" :style="textColorStyle">
                {{ heading }}
            </BaseHeading>
            <div class="description-wrapper">
                <BaseParagraph :style="textColorStyle" class="description">
                    {{ description1 }}
                </BaseParagraph>
                <BaseParagraph v-if="description2" :style="textColorStyle" class="description">
                    {{ description2 }}
                </BaseParagraph>
            </div>
            <ButtonLarge
                v-if="buttonLabel && buttonLink"
                :variant="buttonVariant"
                :to="buttonLink"
                target="_blank"
                class="sale-bar-link"
            >
                {{ buttonLabel }}
            </ButtonLarge>
        </ContainerContent>
    </section>
</template>

<script>
import { mapState } from 'vuex';

import {
    DEFAULT_IMAGE_FORMAT,
    MAGENTO_IMAGE_TYPES as IMAGE_TYPES,
    SOURCE_IMAGE_FORMATS,
} from '@types/Image';

import { checkIfExistsInValuesMap } from '@assets/props';

import { tertiary, gray4 } from '@theme/resources/variables/colors.json';

import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';
import BasePicture from '@atoms/BasePicture/BasePicture';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';

import { ButtonLarge, BUTTON_LARGE_VARIANTS } from '@modivo-ui/components/ButtonLarge/v1';

export default {
    name: 'MarketingSaleBar',

    components: {
        ContainerContent,
        BasePicture,
        BaseHeading,
        BaseParagraph,
        ButtonLarge,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        description1: {
            type: String,
            required: true,
        },

        description2: {
            type: String,
            default: '',
        },

        buttonLabel: {
            type: String,
            default: '',
        },

        buttonLink: {
            type: String,
            default: '',
        },

        textColor: {
            type: String,
            default: tertiary,
        },

        backgroundColor: {
            type: String,
            default: gray4,
        },

        backgroundImageUrl: {
            type: String,
            default: '',
        },

        backgroundImageMobileUrl: {
            type: String,
            default: '',
        },

        buttonVariant: {
            type: String,
            default: BUTTON_LARGE_VARIANTS.SECONDARY,
            validator: checkIfExistsInValuesMap(BUTTON_LARGE_VARIANTS),
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        ...mapState(['isMobile']),

        image() {
            const result = this.$imaginator.getImage(
                this.backgroundImageUrl,
                'marketing-sale-bar',
                `${this.heading}`,
                IMAGE_TYPES.mag.name,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS
            );

            if (!this.backgroundImageMobileUrl) {
                return result;
            }

            const mobileImage = this.$imaginator.getImage(
                this.backgroundImageMobileUrl,
                'marketing-sale-bar',
                `${this.heading}`,
                IMAGE_TYPES.mag.name,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS
            );

            Object.entries(mobileImage.sources).forEach(([type, source]) => {
                result.sources[type] = result.sources[type].concat(source);
            });

            return result;
        },

        sourcesWithMedia() {
            const { sources } = this.image;
            const result = [];

            Object.keys(sources).forEach(type => {
                sources[type].forEach((source, index) => {
                    result.push({
                        type,
                        srcset: source.src,
                        media: this.getMediaForSource(index),
                    });
                });
            });

            return result;
        },

        textColorStyle() {
            return { color: this.textColor };
        },

        styles() {
            const { backgroundImageUrl, backgroundColor } = this;

            return [
                this.textColorStyle,
                {
                    backgroundColor: backgroundImageUrl ? gray4 : backgroundColor,
                },
            ];
        },
    },

    methods: {
        getMediaForSource(index) {
            const mediaMap = {
                0: '(min-width: 1024px)',
                1: '(max-width: 1023px)',
            };

            return mediaMap[index] || '';
        },
    },
};
</script>

<style lang="scss" scoped>
$height: 220px;

.marketing-sale-bar {
    @apply relative overflow-hidden w-full flex justify-center;
    margin-top: theme('customVariables.marketingSaleBar.barMarginTopMobile');

    .image-wrapper {
        @apply absolute h-full w-full;
    }

    &:deep(.image) {
        @apply h-full;

        .image {
            @apply w-full h-full object-cover;
        }
    }

    .marketing-sale-bar-container {
        @apply flex-col flex-nowrap text-center items-center py-5 z-1;

        &.small {
            .description {
                @apply font-book text-r leading-s;
            }

            .sale-bar-link {
                @apply mt-3;
            }
        }
    }

    .heading {
        @apply uppercase text-new-xl leading-new-xxl;
    }

    .description {
        @apply inline text-center font-semibold leading-new-m text-new-r;
    }

    .sale-bar-link {
        @apply mt-4;
    }

    @screen md {
        @apply mt-0;
        height: $height;

        .marketing-sale-bar-container {
            @apply py-0;
        }

        .description {
            @apply block;
        }
    }

    @screen lg {
        .marketing-sale-bar-container {
            &.small {
                .heading {
                    @apply text-new-xl leading-new-xxl;
                }
            }

            .heading {
                @apply text-new-xxxl leading-new-xxxxl;
            }
        }

        .description-wrapper {
            @apply w-1/2;
        }
    }
}
</style>
