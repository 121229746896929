<template>
    <ContainerContent class="marketplace-familiar-brands">
        <BaseHeading class="heading" tag="h2" theme="h3">
            {{ heading }}
        </BaseHeading>

        <ul class="brands">
            <slot />
        </ul>
    </ContainerContent>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';

export default {
    name: 'MarketplaceFamiliarBrands',

    components: {
        BaseHeading,
        ContainerContent,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.marketplace-familiar-brands {
    @apply w-full flex-col flex-nowrap mt-7;

    .heading {
        @apply text-center mb-5;
    }

    .brands {
        @apply flex justify-center flex-wrap;
    }

    @screen md {
        .brands {
            @apply flex-nowrap;
        }
    }

    @screen lg {
        @apply mt-9;

        .heading {
            @apply text-xl leading-xl;
        }
    }
}
</style>
