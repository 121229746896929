<template>
    <AccordionItem
        :key="STATES.ACTIVE"
        :active-item-key="state"
        class="faq-list-item"
        @accordion-toggle="toggleContent()"
    >
        <template #accordion-title>
            <BaseHeading theme="h5" class="title">
                {{ title }}
            </BaseHeading>
        </template>
        <template #accordion-content>
            <BaseParagraph class="description">
                <slot />
            </BaseParagraph>
        </template>
    </AccordionItem>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';

import AccordionItem from '@molecules/Accordion/AccordionItem';

const STATES = {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
};

export default {
    name: 'FAQListItem',

    components: {
        BaseHeading,
        BaseParagraph,
        AccordionItem,
    },

    props: {
        title: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            state: STATES.INACTIVE,
        };
    },

    beforeCreate() {
        this.STATES = STATES;
    },

    methods: {
        toggleContent() {
            this.state = this.state === STATES.ACTIVE ? STATES.INACTIVE : STATES.ACTIVE;
        },
    },
};
</script>

<style lang="scss" scoped>
.faq-list-item {
    @apply border-b-1 border-border;

    &:last-child {
        @apply border-b-1;
    }

    .title {
        @apply my-3 font-book text-r leading-r text-tertiary text-left;
    }

    .description {
        @apply text-s leading-s;
    }
}
</style>
