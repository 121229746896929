<template>
    <div>
        <pre>{{ propsToBind }}</pre>
    </div>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

export default {
    name: 'CmsSyneriseConfig',

    extends: CmsBaseComponent,

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        apiKey: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        trackerKey: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        trackerDomain: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        scriptUrl: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webPdpProductNotification: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webPdp1: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webPdp2: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webPdpUnavbl1: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webPrecart1: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webCart1: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webPdpSimilar: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webCategory1: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webSearchResults1: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webErrorPage1: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webEmptyCart1: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    apiKey: '',
                    trackerKey: '',
                    trackerDomain: '',
                    scriptUrl: '',
                    webPdpProductNotification: '',
                    webPdp1: '',
                    webPdp2: '',
                    webPdpUnavbl1: '',
                    webPrecart1: '',
                    webCart1: '',
                    webPdpSimilar: '',
                    webCategory1: '',
                    webSearchResults1: '',
                    webErrorPage1: '',
                    webEmptyCart1: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            propsCustomFormConfig: {
                scriptUrl: {
                    isUrl: true,
                },

                trackerDomain: {
                    isUrl: true,
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },
};
</script>
