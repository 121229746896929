export const googleCalendarBaseUrl = 'https://calendar.google.com/calendar/r/eventedit';

export const createGoogleCalendarLink = (title, dates, details, address) => {
    const linkParams = {
        text: title,
        dates,
        details,
        location: address,
    };

    const filteredLinkParams = Object.entries(linkParams)
        .filter(([, value]) => value)
        .map(linkPart => linkPart.join('='))
        .join('&');

    const createdLink = `${googleCalendarBaseUrl}?${filteredLinkParams}`;

    return encodeURI(createdLink);
};
