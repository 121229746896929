import FreeDelivery from '@static/icons/48px/delivery.svg?inline';
import Product from '@static/icons/48px/product.svg?inline';
import Return from '@static/icons/48px/return2.svg?inline';
import PremiumBrands from '@static/icons/48px/diamond.svg?inline';
import SafePayment from '@static/icons/48px/payment2.svg?inline';

export const ICON_TYPE_FREE_DELIVERY = 'freeDelivery';
export const ICON_TYPE_PRODUCT = 'product';
export const ICON_TYPE_RETURN = 'return';
export const ICON_TYPE_PREMIUM_BRANDS = 'premiumBrands';
export const ICON_TYPE_SAFE_PAYMENT = 'safePayment';

export const ICON_TYPES = {
    ICON_TYPE_FREE_DELIVERY,
    ICON_TYPE_PRODUCT,
    ICON_TYPE_RETURN,
    ICON_TYPE_PREMIUM_BRANDS,
    ICON_TYPE_SAFE_PAYMENT,
};

export const ICON_MAP = {
    [ICON_TYPE_FREE_DELIVERY]: FreeDelivery,
    [ICON_TYPE_PRODUCT]: Product,
    [ICON_TYPE_RETURN]: Return,
    [ICON_TYPE_PREMIUM_BRANDS]: PremiumBrands,
    [ICON_TYPE_SAFE_PAYMENT]: SafePayment,
};
