<template>
    <MarketingCategorySlider v-bind="propsToBind" :class="cssClass" />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import MarketingCategorySlider from '@organisms/MarketingCategorySlider/MarketingCategorySlider';

export default {
    name: 'CmsMarketingCategorySlider',

    components: {
        MarketingCategorySlider,
    },

    extends: CmsBaseComponent,

    props: getProps(MarketingCategorySlider),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    imageUrl: '',
                    customCampaignId: '',
                    productSkus: '',
                    link: '',
                    title: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                staticClass: false,
            },
        };
    },
};
</script>
