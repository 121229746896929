<template>
    <MarketingHeroBlock :class="cssClass" v-bind="propsToBind" @click.native="componentClick()">
        <PageBuilderInterpreter :components-json="slotDefault" />
    </MarketingHeroBlock>
</template>

<script>
import { HERO_BANNER_CLICK, HERO_BANNER_VIEW } from '@analytics-types/Events';
import { LABELS } from '@analytics-types/Labels';

import { THEMES } from '@types/MarketingBlocks';

import { getHeroBlockComponentVersion } from '@assets/pagebuilder';

import { getProps } from '@modules/page-builder/helpers/component';
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';

import MarketingHeroBlock from '@organisms/MarketingHeroBlock/MarketingHeroBlock';

import { BUTTON_LARGE_VARIANTS } from '@modivo-ui/components/ButtonLarge/v1';

export default {
    name: 'CmsMarketingHeroBlock',

    components: { MarketingHeroBlock, PageBuilderInterpreter },

    extends: CmsBaseComponent,

    props: {
        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },
        ...getProps(MarketingHeroBlock),
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                slots: {
                    default: '',
                },
                props: {
                    url: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                staticClass: false,
                theme: Object.values(THEMES),
                buttonVariant: Object.values(BUTTON_LARGE_VARIANTS),
            },

            observer: null,
        };
    },

    computed: {
        componentVersion() {
            return getHeroBlockComponentVersion(this.$props);
        },
    },

    mounted() {
        if (this.disabled) {
            return;
        }

        this.observer = new IntersectionObserver(
            entries => {
                const isBlockIntersecting = entries.some(({ isIntersecting }) => isIntersecting);

                if (!isBlockIntersecting) {
                    return;
                }

                this.componentView();
            },
            {
                threshold: 0,
            }
        );

        this.observer.observe(this.$el);
    },

    destroyed() {
        this.observer?.disconnect();
    },

    methods: {
        componentClick() {
            const position = Object.keys(this.componentsList).indexOf(this.internalId);

            this.$analytics.emit(HERO_BANNER_CLICK, {
                componentName: LABELS.HERO,
                version: this.componentVersion,
                position,
                campaingName: this.heading,
            });
        },

        componentView() {
            const position = Object.keys(this.componentsList).indexOf(this.internalId);

            this.$analytics.emit(HERO_BANNER_VIEW, {
                componentName: LABELS.HERO,
                version: this.componentVersion,
                position,
                campaingName: this.heading,
            });
        },
    },
};
</script>
