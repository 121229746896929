<template>
    <BasePicture
        :width="imageSize.width"
        :height="imageSize.height"
        :src="imageDesktopSrc"
        :sources-with-media="sourcesWithMedia"
        :is-lazy="isImageLazyLoaded"
        alt=""
    />
</template>

<script>
import { mapState } from 'vuex';

import {
    DEFAULT_IMAGE_FORMAT,
    IMAGE_TYPE_MAGENTO,
    SOURCE_IMAGE_FORMATS,
    IMAGE_FORMAT_JPG_EXTENSION,
} from '@types/Image';

import { isHttpLink } from '@assets/link';

import BasePicture from '@atoms/BasePicture/BasePicture';

const mediaDesktop = '(min-width: 768px)';
const mediaMobile = '(max-width: 767px)';

export default {
    name: 'MarketingHeroBlockImage',

    components: {
        BasePicture,
    },

    props: {
        imageDesktop: {
            type: String,
            required: true,
        },

        imageMobile: {
            type: String,
            required: true,
        },

        isImageLazyLoaded: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        ...mapState(['isMobile']),

        sourcesWithMedia() {
            return [
                this.getSourceWithMedia(this.imageDesktop, mediaDesktop),
                this.getSourceWithMedia(this.imageMobile, mediaMobile),
            ].flat();
        },

        imageSize() {
            const { isMobile } = this;

            return {
                width: isMobile ? 1024 : 1600,
                height: isMobile ? 576 : 448,
            };
        },

        imageDesktopSrc() {
            const { imageDesktop } = this;

            if (isHttpLink(imageDesktop)) {
                return imageDesktop;
            }

            const { url } = this.getImaginatorImage(imageDesktop);

            return url || null;
        },
    },

    methods: {
        getImaginatorImage(image) {
            return this.$imaginator.getImage(
                image,
                'marketing-hero-block-image',
                '',
                IMAGE_TYPE_MAGENTO,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS
            );
        },

        getSourceWithMedia(image, media) {
            const result = [];

            if (!isHttpLink(image)) {
                const { sources } = this.getImaginatorImage(image);

                Object.keys(sources).forEach(type => {
                    sources[type].forEach(source => {
                        result.push({
                            type,
                            srcset: source.src,
                            media,
                        });
                    });
                });
            } else {
                result.push({
                    type: IMAGE_FORMAT_JPG_EXTENSION,
                    srcset: image,
                    media,
                });
            }

            return result;
        },
    },
};
</script>
