<template>
    <Observer
        v-if="displayContainer"
        :options="CONTAINER_OBSERVER_OPTIONS"
        :observe-once="true"
        class="recently-viewed-products-simple-slider-wrapper"
        :class="{ 'is-loading': !isLoaded, 'with-heading': heading }"
        @intersect="displayContent($event)"
    >
        <template v-if="isDisplayed">
            <Loader v-if="!isLoaded" />
            <RecentlyViewedProductsDataProvider
                :sku="sku"
                @loaded="loadedProducts($event)"
                @error="handleError()"
            >
                <component
                    :is="productsSimpleSliderComponent"
                    v-if="isLoaded"
                    :products="productsLoaded"
                    :items-count="itemsCount"
                    :heading="heading"
                    @product-click="onProductClick($event)"
                />
            </RecentlyViewedProductsDataProvider>
        </template>
    </Observer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { PRODUCT_ERROR_DOMAIN } from '@errors/feature-domain-names';

import { ITEMS_COUNT, RECENTLY_VIEWED } from '@configs/simple-slider';
import { PRODUCT_SLIDER_SLIDE_ATTRIBUTE } from '@configs/product';
import { DEFAULT_DEBOUNCE_TIME } from '@configs/recommendations';
import { VIEW_TIME_TO_SEND } from '@configs/analytics-events';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { IMPRESSION_CLICK, IMPRESSION_VIEW } from '@analytics-types/Events';

import DataLayerProduct from '@models/Analytics/DataLayerProduct';

import { debounceAggregate } from '@assets/debounce-aggregate';

import Loader from '@atoms/Loader/Loader';
import Observer from '@atoms/Observer/Observer';

const { mapState: mapRecentlyViewedProductsState } = createNamespacedHelpers(
    'recentlyViewedProducts'
);
const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'RecentlyViewedProductsSimpleSliderWrapper',

    components: {
        Loader,
        Observer,
        RecentlyViewedProductsDataProvider: () => ({
            component: import(
                /* webpackChunkName: "recently-viewed-products-data-provider" */
                '@molecules/RecentlyViewedProductsDataProvider/RecentlyViewedProductsDataProvider'
            ),
        }),
    },

    props: {
        heading: {
            type: String,
            default: '',
        },

        itemsCount: {
            type: Number,
            default: 6,
            validator: value => ITEMS_COUNT.includes(value),
        },

        threshold: {
            type: Number,
            default: 6,
        },

        sku: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            isDisplayed: false,
            isProductsLoaded: false,
            productsSimpleSliderComponent: null,
            displayContainer: false,
            productsLoaded: [],
            productObserver: null,
        };
    },

    computed: {
        ...mapRecentlyViewedProductsState(['products']),
        ...mapConfigGetters(['currency']),

        isLoaded() {
            return this.isProductsLoaded && this.productsSimpleSliderComponent;
        },
    },

    watch: {
        async productsLoaded(products) {
            if (!products.length) {
                return;
            }

            await this.$nextTick();

            const productMap = new Map();

            this.productObserver = new IntersectionObserver(
                (entries, observer) => {
                    entries.forEach(({ target, isIntersecting }) => {
                        const index = parseInt(
                            target.getAttribute(PRODUCT_SLIDER_SLIDE_ATTRIBUTE),
                            10
                        );

                        if (isIntersecting) {
                            if (productMap.has(index)) {
                                return;
                            }

                            const timeout = setTimeout(async () => {
                                observer.unobserve(target);
                                productMap.delete(index);

                                const product = this.productsLoaded[index];

                                this.debouncedOnProductView({ product, index });
                            }, VIEW_TIME_TO_SEND);

                            productMap.set(index, timeout);
                        } else {
                            clearTimeout(productMap.get(index));
                            productMap.delete(index);
                        }
                    });
                },
                {
                    threshold: 0.5,
                }
            );

            const slides = this.$el.querySelectorAll(`[${PRODUCT_SLIDER_SLIDE_ATTRIBUTE}]`);

            slides.forEach(slide => {
                this.productObserver.observe(slide);
            });
        },
    },

    beforeCreate() {
        this.CONTAINER_OBSERVER_OPTIONS = {
            root: null,
            threshold: 0,
            rootMargin: '0px 0px 200px 0px',
        };

        this.debouncedOnProductView = debounceAggregate(products => {
            this.sendProductViewsToAnalytics(products);
        }, DEFAULT_DEBOUNCE_TIME);
    },

    beforeDestroy() {
        this.productObserver?.disconnect();
        this.productObserver = null;
    },

    mounted() {
        if (
            this.$recentlyViewedProducts.get().length >= this.threshold ||
            this.products.length >= this.threshold
        ) {
            this.displayContainer = true;
        }
    },

    methods: {
        loadedProducts(products) {
            this.isProductsLoaded = products.length >= this.threshold;

            if (!this.isProductsLoaded) {
                this.displayContainer = false;
                this.isDisplayed = false;
            } else {
                this.productsLoaded = products;
            }
        },

        displayContent(intersect) {
            if (intersect) {
                this.isDisplayed = true;
                this.loadProductsSimpleSliderComponent();
            }
        },

        loadProductsSimpleSliderComponent() {
            import(
                /* webpackChunkName: "products-simple-slider-wrapper" */
                '@molecules/ProductsSimpleSliderWrapper/ProductsSimpleSliderWrapper'
            )
                .then(component => {
                    this.productsSimpleSliderComponent = component.default;
                })
                .catch(err => {
                    this.handleError();
                    this.$errorHandler.captureDomainError(PRODUCT_ERROR_DOMAIN, err, {
                        [ERROR_ACTION_TAG_NAME]: 'lazyload',
                    });
                });
        },

        handleError() {
            this.displayContainer = false;
            this.isDisplayed = false;
        },

        onProductClick({ product, index }) {
            const analyticsProduct = new DataLayerProduct({
                product,
                position: index + 1,
            }).build();

            if (analyticsProduct) {
                this.$analytics.emit(IMPRESSION_CLICK, {
                    currency: this.currency,
                    product: analyticsProduct,
                    actionFieldList: RECENTLY_VIEWED,
                    route: this.$route,
                });
            }
        },

        sendProductViewsToAnalytics(products) {
            const productsToSend = products
                .flat()
                .map(({ product, index }) => ({
                    ...new DataLayerProduct({
                        product,
                        position: index + 1,
                    }).build(),
                    list: RECENTLY_VIEWED,
                }))
                .filter(product => !!product);

            if (productsToSend.length) {
                this.$analytics.emit(IMPRESSION_VIEW, {
                    currency: this.currency,
                    products: productsToSend,
                    route: this.$route,
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';

$heading-height-mobile: 28px + $tailwindcss-spacing-3;
$heading-height-tablet: 32px + $tailwindcss-spacing-3;
$heading-height-lg-min: 40px + $tailwindcss-spacing-3;

$min-height-mobile: 331px;
$min-height-lg-min: 321px;
$min-height-container: 409px;

$min-height-with-heading-mobile: $min-height-mobile + $heading-height-mobile;
$min-height-with-heading-tablet: $min-height-lg-min + $heading-height-tablet;
$min-height-with-heading-container: $min-height-container + $heading-height-lg-min;

$multiplier: math.div(100px, 22px);

@mixin min-height-lg-min($min-height, $multiplier) {
    min-height: calc(#{$min-height} + ((100vw - 1024px) / #{$multiplier}));
}

.recently-viewed-products-simple-slider-wrapper {
    @apply flex items-center;
    min-height: $min-height-mobile;

    &.with-heading {
        min-height: $min-height-with-heading-mobile;
    }

    &.is-loading {
        @apply bg-gray4;
    }

    @screen md {
        @include min-height-lg-min($min-height-lg-min, $multiplier);

        &.with-heading {
            @include min-height-lg-min($min-height-with-heading-tablet, $multiplier);
        }
    }

    @screen container {
        min-height: $min-height-container;

        &.with-heading {
            min-height: $min-height-with-heading-container;
        }
    }
}
</style>
