<template>
    <SimpleSliderSlide
        v-if="hasAllRequiredValues"
        :scroll-snap-on-mobile="true"
        class="marketing-cards-slider-2-slide"
    >
        <template v-if="imageUrl">
            <BasePicture
                :width="image.width"
                :height="image.height"
                :src="image.url"
                :alt="image.alt"
                :sources="image.sources"
                :is-lazy="true"
                class="slide-image"
            />
        </template>
        <template v-else-if="videoUrl">
            <IframeVideo
                :width="videoSettings.width"
                :height="videoSettings.height"
                :is-lazy="true"
                :src="videoSettings.src"
            />
        </template>
        <BaseHeading class="heading">
            {{ heading }}
        </BaseHeading>
        <BaseParagraph class="sub-heading">
            {{ subHeading }}
        </BaseParagraph>
        <component :is="componentTag" :path="url" class="link" />
    </SimpleSliderSlide>
</template>

<script>
import { mapState } from 'vuex';

import {
    DEFAULT_IMAGE_FORMAT,
    MARKETING_CARD_IMAGE_TYPES as IMAGE_TYPES,
    SOURCE_IMAGE_FORMATS,
} from '@types/Image';
import { PROMO_VIDEO_WIDTH, PROMO_VIDEO_HEIGHT } from '@types/MarketingBlocks';

import { isHttpLink } from '@assets/link';

import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';
import BasePicture from '@atoms/BasePicture/BasePicture';
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';
import BaseLink from '@atoms/BaseLink/BaseLink';
import IframeVideo from '@atoms/IframeVideo/IframeVideo';

import SimpleSliderSlide from '@molecules/SimpleSlider/SimpleSliderSlide';

export default {
    name: 'MarketingCardsSlider2Slide',

    components: {
        BaseHeading,
        BaseParagraph,
        BasePicture,
        BaseLinkStatic,
        BaseLink,
        SimpleSliderSlide,
        IframeVideo,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        subHeading: {
            type: String,
            default: '',
        },

        imageUrl: {
            type: String,
            default: '',
        },

        url: {
            type: String,
            required: true,
        },

        videoUrl: {
            type: String,
            default: '',
        },
    },

    computed: {
        ...mapState(['isTabletOrDesktop']),

        image() {
            return this.$imaginator.getImage(
                this.imageUrl,
                'marketing-card',
                `${this.heading} ${this.subHeading}`,
                IMAGE_TYPES.marketing_card_492w_656h.name,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS,
                Object.keys(IMAGE_TYPES)
            );
        },

        videoSettings() {
            const src = `${this.videoUrl}&autoplay=1&loop=1&muted=1&controls=0`;

            return {
                src,
                width: PROMO_VIDEO_WIDTH,
                height: PROMO_VIDEO_HEIGHT,
            };
        },

        hasAllRequiredValues() {
            return this.heading && (this.imageUrl || this.videoUrl);
        },

        componentTag() {
            return isHttpLink(this.url) ? BaseLinkStatic : BaseLink;
        },
    },
};
</script>

<style lang="scss" scoped>
$slide-gap-desktop: 24px;
$slide-gap-container: 38px;
$slide-min-width-mobile: 260px;
$slide-max-width: 485px;

@function slideMinWidth($slideGap) {
    @return calc((100% / 3) - (#{$slideGap} * 2) / 3);
}

.marketing-cards-slider-2-slide {
    @apply mr-3 flex-shrink-0 relative;
    min-width: $slide-min-width-mobile;
    max-width: $slide-max-width;
    width: calc(100vw - 80px);

    &:first-child {
        @apply ml-3;
    }

    &:last-child {
        @apply mr-0 pr-3;

        min-width: calc(#{$slide-min-width-mobile} + #{$tailwindcss-spacing-3});
    }

    .link {
        @apply absolute top-0 left-0 w-full h-full;

        &:hover {
            @apply no-underline;
        }
    }

    .heading {
        @apply block text-m leading-m mt-2;
    }

    .sub-heading {
        @apply block mt-1 text-r leading-r text-gray2;
    }

    @screen md {
        width: calc((100vw - 80px) / 2);
    }

    @screen lg {
        margin-right: $slide-gap-desktop;
        min-width: slideMinWidth($slide-gap-desktop);
        width: calc((100vw - 140px) / 2);

        &:last-child {
            min-width: calc(#{slideMinWidth($slide-gap-desktop)} + #{$tailwindcss-spacing-3});
        }

        .heading {
            @apply text-l leading-l mt-4;
        }
    }

    @screen container {
        margin-right: $slide-gap-container;
        min-width: slideMinWidth($slide-gap-container);

        &:first-child {
            @apply ml-0;
        }

        &:last-child {
            @apply mr-0 pr-0;
            min-width: slideMinWidth($slide-gap-container);
        }
    }
}
</style>
