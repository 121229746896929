<template>
    <section
        v-if="hasAllRequiredProps"
        class="marketing-banner"
        :class="[
            theme,
            {
                'with-new-images': hasNewImages,
            },
        ]"
    >
        <ContainerContent class="banner-container">
            <component :is="pageUrl ? 'BaseLink' : 'div'" :path="pageUrl" class="link-wrapper">
                <div class="wrapper">
                    <BasePicture
                        class="image"
                        :width="image.width"
                        :height="image.height"
                        :src="image.url"
                        :alt="image.alt"
                        :sources="image.sources"
                        :sources-with-media="sourcesWithMedia"
                        :is-lazy="true"
                    />
                    <div class="description-container">
                        <div v-if="hasVideo" class="video-wrapper">
                            <BasePicture
                                class="video-placeholder"
                                :alt="videoPlaceholderImage.alt"
                                :src="videoPlaceholderImage.url"
                                :is-lazy="true"
                            />
                            <IframeVideo class="video" :src="videoUrlWithParams" :is-lazy="true" />
                        </div>
                        <div class="headings-wrapper">
                            <BaseHeading class="sub-heading">
                                {{ subHeading }}
                            </BaseHeading>
                            <BaseHeading class="heading">
                                {{ heading }}
                            </BaseHeading>
                            <BaseParagraph v-if="description" class="description">
                                {{ description }}
                            </BaseParagraph>
                            <BaseButton
                                v-if="buttonLabel"
                                :theme="finalButtonTheme"
                                class="action-button"
                            >
                                {{ buttonLabel }}
                            </BaseButton>
                        </div>
                    </div>
                </div>
            </component>
        </ContainerContent>
    </section>
</template>

<script>
import { checkIfExistsInValuesMap } from '@assets/props';

import { THEMES as BUTTON_ALL_THEMES } from '@types/Button';
import { THEMES, BUTTON_THEMES } from '@types/MarketingBanner';
import {
    DEFAULT_IMAGE_FORMAT,
    IMAGE_TYPE_MARKETING_BANNER_VIDEO_PLACEHOLDER_640w_360h,
    IMAGE_TYPE_MARKETING_BANNER_1152w_450h,
    IMAGE_TYPE_MARKETING_BANNER_1532w_450h,
    IMAGE_TYPE_MARKETING_BANNER_450w_450h,
    IMAGE_TYPE_MARKETING_BANNER_767w_450h,
    MARKETING_BANNER_IMAGE_TYPES as IMAGE_TYPES,
    OLD_MARKETING_BANNER_IMAGE_TYPES as OLD_IMAGE_TYPES,
    SOURCE_IMAGE_FORMATS,
} from '@types/Image';

import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseButton from '@atoms/BaseButton/BaseButton';
import BasePicture from '@atoms/BasePicture/BasePicture';
import IframeVideo from '@atoms/IframeVideo/IframeVideo';
import BaseLink from '@atoms/BaseLink/BaseLink';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';

export default {
    name: 'MarketingBanner',

    components: {
        BasePicture,
        IframeVideo,
        BaseLink,
        BaseButton,
        BaseHeading,
        ContainerContent,
        BaseParagraph,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        subHeading: {
            type: String,
            required: true,
        },

        imageUrl450x450: {
            type: String,
            required: true,
        },

        imageUrl767x450: {
            type: String,
            required: true,
        },

        imageUrl1532x450: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line max-len
        // @todo (PM-415): props "imageUrl1532x1149" changed to "imageUrl1532x450", left for backward compatibility
        imageUrl1532x1149: {
            type: String,
            default: null,
        },
        // @todo end

        // eslint-disable-next-line max-len
        // @todo (MOD-2139): props "buttonUrl" changed in MOD-2063 to "bannerUrl", left for backward compatibility
        buttonUrl: {
            type: String,
            default: null,
        },
        // @todo end

        // eslint-disable-next-line max-len
        // @todo (MOD-2147): props "imageUrl" changed in MOD-2137 to "imageUrl450x450", "imageUrl767x450", "imageUrl1532x450" left for backward compatibility
        imageUrl: {
            type: String,
            default: null,
        },
        // @todo end

        bannerUrl: {
            type: String,
            default: null,
        },

        buttonLabel: {
            type: String,
            default: null,
        },

        theme: {
            type: String,
            default: THEMES.THEME_LIGHT,
            validator: checkIfExistsInValuesMap(THEMES),
        },

        buttonTheme: {
            type: String,
            default: BUTTON_THEMES.BUTTON_THEME_DEFAULT,
            validator: checkIfExistsInValuesMap(BUTTON_THEMES),
        },

        videoUrl: {
            type: String,
            default: null,
        },

        videoAutoplay: {
            type: Boolean,
            default: true,
        },

        videoLoop: {
            type: Boolean,
            default: true,
        },

        videoPlaceholder: {
            type: String,
            default: null,
        },

        description: {
            type: String,
            default: null,
        },
    },

    computed: {
        // @todo MOD-2147 remove this method
        hasNewImages() {
            return !!(
                this.imageUrl450x450 &&
                this.imageUrl767x450 &&
                (this.imageUrl1532x450 || this.imageUrl1532x1149)
            );
        },

        image() {
            if (this.hasNewImages) {
                const image450x450 = this.$imaginator.getImage(
                    this.imageUrl450x450,
                    'marketing-banner',
                    `${this.heading} ${this.subHeading}`,
                    IMAGE_TYPES.marketing_banner_450w_450h.name,
                    DEFAULT_IMAGE_FORMAT,
                    SOURCE_IMAGE_FORMATS,
                    [IMAGE_TYPE_MARKETING_BANNER_450w_450h]
                );

                const image767x450 = this.$imaginator.getImage(
                    this.imageUrl767x450,
                    'marketing-banner',
                    `${this.heading} ${this.subHeading}`,
                    IMAGE_TYPES.marketing_banner_767w_450h.name,
                    DEFAULT_IMAGE_FORMAT,
                    SOURCE_IMAGE_FORMATS,
                    [IMAGE_TYPE_MARKETING_BANNER_767w_450h]
                );

                const result = this.$imaginator.getImage(
                    this.imageUrl1532x450 || this.imageUrl1532x1149,
                    'marketing-banner',
                    `${this.heading} ${this.subHeading}`,
                    IMAGE_TYPES.marketing_banner_1532w_450h.name,
                    DEFAULT_IMAGE_FORMAT,
                    SOURCE_IMAGE_FORMATS,
                    [IMAGE_TYPE_MARKETING_BANNER_1532w_450h, IMAGE_TYPE_MARKETING_BANNER_1152w_450h]
                );

                [image767x450, image450x450].forEach(image => {
                    Object.entries(image.sources).forEach(([type, source]) => {
                        result.sources[type] = result.sources[type].concat(source);
                    });
                });

                return result;
            }

            return this.$imaginator.getImage(
                this.imageUrl,
                'marketing-banner',
                `${this.heading} ${this.subHeading}`,
                OLD_IMAGE_TYPES.marketing_banner_1532w_450h.name,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS,
                Object.keys(OLD_IMAGE_TYPES)
            );
        },

        videoPlaceholderImage() {
            return this.$imaginator.getImage(
                this.videoPlaceholder,
                'video-placeholder',
                'video-placeholder',
                IMAGE_TYPE_MARKETING_BANNER_VIDEO_PLACEHOLDER_640w_360h
            );
        },

        sourcesWithMedia() {
            if (!this.hasNewImages) {
                return [];
            }

            const { sources } = this.image;
            const result = [];

            Object.keys(sources).forEach(type => {
                sources[type].forEach(source => {
                    result.push({
                        type,
                        srcset: source.src,
                        media: this.getMediaForSource(source),
                    });
                });
            });

            return result;
        },

        hasAllRequiredProps() {
            return !!this.subHeading && !!this.heading && (!!this.imageUrl || this.hasNewImages);
        },

        finalButtonTheme() {
            const { buttonTheme } = this;

            if (buttonTheme === BUTTON_THEMES.BUTTON_THEME_DEFAULT) {
                return this.theme === THEMES.THEME_LIGHT
                    ? BUTTON_ALL_THEMES.THEME_SECONDARY
                    : BUTTON_ALL_THEMES.THEME_TRANSPARENT_WHITE;
            }

            return buttonTheme;
        },

        // eslint-disable-next-line max-len
        // @todo (MOD-2139): props "buttonUrl" changed in MOD-2063 to "bannerUrl", left for backward compatibility
        pageUrl() {
            return this.bannerUrl || this.buttonUrl;
        },
        // @todo end

        hasVideo() {
            return this.videoUrl && this.videoPlaceholder;
        },

        videoUrlWithParams() {
            const videoParams = ['sidedock=0', 'title=0', 'byline=0', 'portrait=0', 'controls=0'];

            if (this.videoAutoplay) {
                videoParams.push('autoplay=1');
                videoParams.push('muted=1');
            }

            if (this.videoLoop) {
                videoParams.push('loop=1');
            }

            return `${this.videoUrl}?${videoParams.join('&')}`;
        },
    },

    methods: {
        getMediaForSource(source) {
            if (source.width === 450) {
                return '(max-width: 450px)';
            }

            if (source.width === 767) {
                return '(min-width: 451px)';
            }

            if (source.width === 1152) {
                return '(min-width: 768px)';
            }

            if (source.width === 1532) {
                return '(min-width: 1153px)';
            }

            return '';
        },
    },
};
</script>

<style lang="scss" scoped>
$banner-height: 450px;
$banner-dark-background-color: rgba(34, 34, 34, 0.5);
$banner-light-background-color: rgba($tailwindcss-colors-light, 0.5);
$video-wrapper-max-width-md: 70%;
$video-wrapper-max-width-xl: 60%;
$video-wrapper-max-width-container: 54%;

.marketing-banner {
    &.light {
        .headings-wrapper {
            background-color: $banner-light-background-color;
        }
    }

    &.dark {
        .headings-wrapper {
            background-color: $banner-dark-background-color;
        }

        .sub-heading {
            @apply text-light;
        }

        .heading {
            @apply text-light;
        }

        .description {
            @apply text-light;
        }
    }

    .banner-container {
        @apply px-0;
    }

    .link-wrapper {
        @apply flex w-full;
    }

    .wrapper {
        @apply flex w-full relative overflow-hidden;
        height: $banner-height;
    }

    &.with-new-images {
        &:deep(.image) {
            .image {
                @apply max-w-none max-h-none absolute left-1/2;
                transform: translateX(-50%);
            }
        }
    }

    &:not(.with-new-images) {
        &:deep(.image) {
            .image {
                @apply h-full max-w-none absolute right-0;
            }
        }
    }

    .description-container {
        @apply absolute flex flex-col justify-end w-full h-full;
    }

    .video-wrapper {
        @apply relative w-full flex items-center flex-auto;
    }

    .video,
    .video-placeholder {
        @apply absolute w-full h-full top-1/2 left-1/2;
        transform: translate(-50%, -50%);
    }

    .video-placeholder {
        &:deep() {
            .base-picture {
                @apply justify-center items-center;
            }
        }
    }

    .headings-wrapper {
        @apply flex flex-col justify-center items-stretch text-center w-full p-3;
        backdrop-filter: blur(8px);
    }

    .heading {
        @apply text-l leading-l;
    }

    .description {
        @apply text-center;
    }

    .action-button {
        @apply mt-3 justify-center;
    }

    @screen md {
        .banner-container {
            @apply px-3;
        }

        &.with-new-images {
            &:deep(.image) {
                .image {
                    @apply left-auto right-0 transform-none;
                }
            }
        }

        .description-container {
            @apply flex-row-reverse items-center;
        }

        .video-wrapper {
            @apply justify-center w-2/3;
        }

        .video,
        .video-placeholder {
            max-width: $video-wrapper-max-width-md;
        }

        .video-placeholder {
            @apply static transform-none;
        }

        .headings-wrapper {
            @apply top-0 h-full w-1/3 p-3 text-left items-start;
        }

        .sub-heading {
            @apply text-m leading-m;
        }

        .heading {
            @apply text-xl leading-xl mt-2;
        }

        .description {
            @apply mt-5 text-m leading-m text-left;
        }

        .action-button {
            @apply mt-5;
        }
    }

    @screen lg {
        .banner-container {
            @apply px-5;
        }

        .headings-wrapper {
            @apply p-5;
        }

        .heading {
            @apply text-xxl leading-xxxl;
        }
    }

    @screen xl {
        .video,
        .video-placeholder {
            max-width: $video-wrapper-max-width-xl;
        }
    }

    @screen container {
        .video,
        .video-placeholder {
            max-width: $video-wrapper-max-width-container;
        }
    }
}
</style>
