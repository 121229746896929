<template>
    <ContainerContent v-if="!disabled" class="marketing-hero-block">
        <div class="hero-media">
            <slot />
        </div>
        <MarketingHeroBlockContent
            :theme="theme"
            :heading="heading"
            :description="description"
            :button-label="buttonLabel"
            :button-variant="buttonVariant"
            :bg-color="contentBackgroundColor"
            class="hero-content"
        />
        <component :is="componentTag" :path="url" class="link" />
    </ContainerContent>
</template>

<script>
import { checkIfExistsInValuesMap } from '@assets/props';
import { isHttpLink } from '@assets/link';

import { THEMES } from '@types/MarketingBlocks';

import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';
import BaseLink from '@atoms/BaseLink/BaseLink';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import MarketingHeroBlockContent from '@molecules/MarketingHeroBlockContent/MarketingHeroBlockContent';

import { BUTTON_LARGE_VARIANTS } from '@modivo-ui/components/ButtonLarge/v1';

export default {
    name: 'MarketingHeroBlock',

    components: {
        ContainerContent,
        MarketingHeroBlockContent,
        BaseLinkStatic,
        BaseLink,
    },

    provide() {
        return {
            backgroundColor: this.contentBackgroundColor,
        };
    },

    props: {
        url: {
            type: String,
            required: true,
        },

        heading: {
            type: String,
            default: '',
        },

        description: {
            type: String,
            default: '',
        },

        buttonLabel: {
            type: String,
            default: '',
        },

        contentBackgroundColor: {
            type: String,
            default: '#0000',
        },

        theme: {
            type: String,
            default: THEMES.THEME_DARK,
            validator: checkIfExistsInValuesMap(THEMES),
        },

        buttonVariant: {
            type: String,
            default: BUTTON_LARGE_VARIANTS.SECONDARY,
            validator: checkIfExistsInValuesMap(BUTTON_LARGE_VARIANTS),
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        componentTag() {
            return isHttpLink(this.url) ? BaseLinkStatic : BaseLink;
        },
    },
};
</script>

<style lang="scss" scoped>
$marketing-hero-block-max-height: 450px;

.marketing-hero-block {
    @apply p-0 relative;

    .hero-media {
        @apply w-full h-full;
    }

    .hero-content {
        @apply px-3;
    }

    .link {
        @apply absolute top-0 left-0 w-full h-full;
    }

    @screen lg {
        @apply overflow-hidden;
        max-height: $marketing-hero-block-max-height;

        .hero-content {
            @apply absolute px-0 top-0 bottom-0 left-32 my-auto;
        }
    }
}
</style>
