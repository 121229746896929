<template>
    <BaseLink :path="url" class="marketing-multi-link-banner-link">
        <SvgIcon width="16px" height="16px" class="icon">
            <GiftIcon />
        </SvgIcon>
        {{ label }}
    </BaseLink>
</template>

<script>
import GiftIcon from '@static/icons/16px/gift.svg?inline';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import BaseLink from '@atoms/BaseLink/BaseLink';

export default {
    name: 'MarketingMultiLinkBannerLink',

    components: {
        SvgIcon,
        GiftIcon,
        BaseLink,
    },

    props: {
        url: {
            type: String,
            required: true,
        },

        label: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-multi-link-banner-link {
    @apply flex items-center mt-3 mx-3 text-r leading-r font-semibold text-tertiary;

    .icon {
        @apply mr-2;
        color: inherit;
    }
}
</style>
