<template>
    <div ref="accordionsContainer" class="cms-with-accordions">
        <slot />
    </div>
</template>

<script>
const ACCORDION_SECTION_OPEN_CLASS = 'open';
const ACCORDION_SELECTOR = 'data-psb-accordion';
const ACCORDION_SECTION_SELECTOR = 'data-psb-accordion-section';
const ACCORDION_BUTTON_SELECTOR = 'data-psb-accordion-button';

export default {
    name: 'CmsWithAccordions',

    mounted() {
        this.initAccordions();
    },

    methods: {
        initAccordions() {
            const accordions = this.getAccordions();

            accordions.forEach(accordion => {
                const sections = this.getAccordionSections(accordion);

                sections.forEach(section => {
                    const button = this.getAccordionSectionButton(section);

                    if (button) {
                        button.addEventListener(
                            'click',
                            event => {
                                const isOpen = Array.from(section.classList).includes(
                                    ACCORDION_SECTION_OPEN_CLASS
                                );

                                event.preventDefault();
                                this.closeSections(sections);

                                if (!isOpen) {
                                    this.openSection(section);
                                }
                            },
                            false
                        );
                    }
                });
            });
        },

        openSection(section) {
            section.classList.add(ACCORDION_SECTION_OPEN_CLASS);
        },

        closeSections(sections) {
            sections.forEach(section => {
                section.classList.remove(ACCORDION_SECTION_OPEN_CLASS);
            });
        },

        getAccordions() {
            return Array.from(
                this.$refs.accordionsContainer.querySelectorAll(`[${ACCORDION_SELECTOR}]`)
            );
        },

        getAccordionSections(accordion) {
            return Array.from(accordion.querySelectorAll(`[${ACCORDION_SECTION_SELECTOR}]`));
        },

        getAccordionSectionButton(section) {
            return section.querySelector(`[${ACCORDION_BUTTON_SELECTOR}]`);
        },
    },
};
</script>

<style lang="scss" scoped>
.cms-with-accordions {
    &:deep() {
        .accordion-section {
            @apply border-solid border-b-1 border-border;

            &.open {
                .accordion-button {
                    &::after {
                        background: url('~static/icons/16px/arrow1-up.svg') no-repeat;
                    }
                }

                .accordion-content {
                    height: auto;
                }
            }

            &:last-of-type {
                @apply border-b-0;
            }
        }

        .accordion-button {
            @apply relative font-semibold cursor-pointer text-gray1 py-3 pr-4;

            &::after {
                @apply absolute right-0 top-20 w-3 h-3;
                content: '';
                background: url('~static/icons/16px/arrow1-down.svg') no-repeat;
            }
        }

        .accordion-content {
            @apply text-s leading-s text-tertiaryOption overflow-hidden;
            height: 0;

            p {
                @apply mb-4 text-gray1;
            }
        }
    }
}
</style>
