import { createNamespacedHelpers } from 'vuex';

import { LIMIT_FOR_SHOW_NEWSLETTER_TOOLTIP } from '@configs/tooltips';
import {
    IS_NEWSLETTER_TOOLTIP_DISABLED,
    NEWSLETTER_TOOLTIP_SHOWN_COUNTER,
    NEWSLETTER_TOOLTIP_LAST_VIEW_EXPIRATION_KEY,
    STORAGE_TYPE_SESSION,
    IS_ADD_TO_WISHLIST_CLICKED,
} from '@configs/storage';

import { NEWSLETTER_TOOLTIP, NEWSLETTER_TOOLTIP_RELEASER } from '@configs/modals';

import { isShowLimitExceeded, setTooltipDataInStorage } from '@assets/tooltip';

const { mapState: mapCustomerState } = createNamespacedHelpers('customer');

export default {
    computed: {
        ...mapCustomerState(['isCustomerSubscribedToNewsletter']),
    },

    methods: {
        setIsNewsletterTooltipEnabled(releaser) {
            if (this.isCustomerSubscribedToNewsletter) {
                return;
            }

            const tooltipLastViewExpirationDate = this.$storage.getItem(
                NEWSLETTER_TOOLTIP_LAST_VIEW_EXPIRATION_KEY
            );

            if (tooltipLastViewExpirationDate > new Date().getTime()) {
                return;
            }

            const isViewsLimitExceeded = isShowLimitExceeded({
                storage: this.$storage,
                limit: LIMIT_FOR_SHOW_NEWSLETTER_TOOLTIP,
                isShownStorageName: IS_NEWSLETTER_TOOLTIP_DISABLED,
                counterStorageName: NEWSLETTER_TOOLTIP_SHOWN_COUNTER,
            });

            if (isViewsLimitExceeded) {
                return;
            }

            this.$modals.open(NEWSLETTER_TOOLTIP, {
                [NEWSLETTER_TOOLTIP_RELEASER]: releaser,
            });
            setTooltipDataInStorage({
                storage: this.$storage,
                isShownStorageName: IS_NEWSLETTER_TOOLTIP_DISABLED,
                counterStorageName: NEWSLETTER_TOOLTIP_SHOWN_COUNTER,
            });
            this.$storage.setItem(IS_ADD_TO_WISHLIST_CLICKED, true, null, STORAGE_TYPE_SESSION);
        },
    },
};
