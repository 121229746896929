<template>
    <div v-if="hasAllRequiredValues" class="marketing-celebrity-slider-slide-content">
        <div class="text">
            <BaseHeading class="title">
                {{ title }}
            </BaseHeading>
            <BaseParagraph class="content">
                {{ content }}
            </BaseParagraph>
        </div>

        <CustomProductsSimpleSliderWrapper
            :items-count="ITEMS_COUNT"
            :product-skus="productSkus"
            class="celebrity-products-slider"
        />
    </div>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';

import CustomProductsSimpleSliderWrapper from '@organisms/CustomProductsSimpleSliderWrapper/CustomProductsSimpleSliderWrapper';

const ITEMS_COUNT = 3;

export default {
    name: 'MarketingCelebritySliderSlideContent',

    components: {
        CustomProductsSimpleSliderWrapper,
        BaseParagraph,
        BaseHeading,
    },

    props: {
        title: {
            type: String,
            required: true,
        },

        content: {
            type: String,
            required: true,
        },

        productSkus: {
            type: String,
            required: true,
        },
    },

    computed: {
        hasAllRequiredValues() {
            return this.title && this.content && this.productSkus;
        },
    },

    beforeCreate() {
        this.ITEMS_COUNT = ITEMS_COUNT;
    },
};
</script>

<style lang="scss" scoped>
$navigation-buttons-height: $tailwindcss-spacing-8;
$margin-left-mobile: $tailwindcss-spacing-3;
$product-card-hover-one-side-tablet: #{theme('customVariables.productsSlider.productHoverDesktop')};
$product-card-hover-one-side-desktop: #{theme(
        'customVariables.productsSlider.productHoverContainer'
    )};
$two-product-cards-width: 66.66%;

.marketing-celebrity-slider-slide-content {
    @apply pt-5;

    .text {
        @apply px-3;
    }

    .title {
        @apply text-new-m leading-new-m;
    }

    .content {
        @apply text-tertiary mt-1 leading-s;
    }

    &:deep() {
        .simple-slider {
            .navigation-buttons {
                @apply hidden;
            }
        }
    }

    @screen lg {
        @apply pt-5;

        .text {
            @apply py-0 px-12p max-w-full;
        }

        .content {
            max-width: $two-product-cards-width;
        }

        .title {
            @apply text-new-l leading-new-l;
        }

        .celebrity-products-slider {
            @apply ml-0;
        }

        &:deep() {
            .simple-slider {
                .navigation-buttons {
                    @apply flex;
                    height: $navigation-buttons-height;
                }
            }
        }
    }

    @screen container {
        @apply pt-8;

        .text {
            padding: 0 $product-card-hover-one-side-desktop;
        }
    }

    @screen xxl {
        .title {
            @apply text-xl leading-new-xl;
        }
    }
}
</style>
