<template>
    <ButtonWithIcon
        ref="copyToClipboardButton"
        :theme="theme"
        :show-left-slot="false"
        :class="{ 'prevent-click': isPending }"
        class="copy-to-clipboard-button"
        @click="copyDiscountCodeToClipboard()"
    >
        {{ buttonLabel }}
        <template #right>
            <transition :name="transitionName" mode="out-in">
                <SvgIcon :key="isCopied ? 'check' : 'copy'" class="icon" width="24px" height="24px">
                    <component :is="iconComponent" />
                </SvgIcon>
            </transition>
        </template>
    </ButtonWithIcon>
</template>

<script>
import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import ButtonWithIcon from '@molecules/ButtonWithIcon/ButtonWithIcon';
import Copy from '@static/icons/16px/copy.svg?inline';
import Check from '@static/icons/16px/check2.svg?inline';
import { copyToClipboard } from '@assets/clipboard';
import { ERROR_ACTION_TAG_NAME } from '@types/Errors';
import { THEMES } from '@types/Button';

const CHANGE_LABEL_TO_COPIED_AFTER = 100;
const RESET_TO_DEFAULT_STATE_AFTER = 900;

export default {
    name: 'CopyToClipboardButton',

    components: {
        SvgIcon,
        Copy,
        Check,
        ButtonWithIcon,
    },

    props: {
        valueToCopy: {
            type: String,
            required: true,
        },

        beforeCopyLabel: {
            type: String,
            default: '',
        },

        afterCopyLabel: {
            type: String,
            default: '',
        },

        theme: {
            type: String,
            default: THEMES.THEME_SECONDARY,
            validator: value => Object.values(THEMES).includes(value),
        },
    },

    data() {
        return {
            isPending: false,
            isCopied: false,
        };
    },

    computed: {
        iconComponent() {
            return this.isCopied ? Check : Copy;
        },

        transitionName() {
            return this.isCopied ? 'fade' : '';
        },

        buttonLabel() {
            return this.isCopied
                ? this.afterCopyLabel || this.$t('Copied')
                : this.beforeCopyLabel || this.$t('Copy to clipboard');
        },
    },

    methods: {
        copyDiscountCodeToClipboard() {
            this.isPending = true;
            const { valueToCopy } = this;

            try {
                copyToClipboard(valueToCopy);

                setTimeout(() => {
                    this.isCopied = true;

                    this.$emit('value-copied');
                }, CHANGE_LABEL_TO_COPIED_AFTER);

                setTimeout(() => {
                    this.isCopied = false;
                    this.isPending = false;
                }, RESET_TO_DEFAULT_STATE_AFTER);
            } catch (err) {
                this.isCopied = false;
                this.isPending = false;

                this.$errorHandler.captureError(
                    err,
                    {
                        [ERROR_ACTION_TAG_NAME]: 'copyToClipboard',
                    },
                    {
                        valueToCopy,
                    }
                );
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.copy-to-clipboard-button {
    &.prevent-click {
        @apply pointer-events-none bg-gray1 text-light;

        .icon {
            @apply text-light;
        }
    }

    .fade-enter-active {
        transition: opacity 0.3s ease 0.2s;
    }

    .fade-enter {
        @apply opacity-0;
    }
}
</style>
