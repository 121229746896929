import { render, staticRenderFns } from "./LoyaltyClubDarkSection.vue?vue&type=template&id=4659a728&scoped=true"
import script from "./LoyaltyClubDarkSection.vue?vue&type=script&lang=js"
export * from "./LoyaltyClubDarkSection.vue?vue&type=script&lang=js"
import style0 from "./LoyaltyClubDarkSection.vue?vue&type=style&index=0&id=4659a728&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4659a728",
  null
  
)

export default component.exports