<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { CMS_TEXT_ALLOWED_HTML_TAGS } from '@modules/page-builder/page-builder.config';
import { clearTags } from '@assets/html';

export default {
    name: 'CmsText',

    extends: CmsBaseComponent,
    props: {
        text: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    text: '',
                },
            },
            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
            },
        };
    },

    computed: {
        textClearTags() {
            return clearTags(this.text, CMS_TEXT_ALLOWED_HTML_TAGS);
        },
    },

    render(createElement) {
        return createElement('span', {
            domProps: { innerHTML: this.textClearTags },
        });
    },
};
</script>
