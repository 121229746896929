<template>
    <MarketingMultiLinkBannerSmall v-bind="propsToBind" :class="cssClass">
        <template v-if="!!slotDescription" #description>
            <PageBuilderInterpreter :components-json="slotDescription" />
        </template>
        <template v-if="!!slotLinks" #links>
            <PageBuilderInterpreter
                :tag="MarketingMultiLinkBannerLinks"
                :components-json="slotLinks"
            />
        </template>
    </MarketingMultiLinkBannerSmall>
</template>

<script>
import { HEADING_TAGS } from '@types/MarketingBanner';

import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';

import MarketingMultiLinkBannerSmall from '@organisms/MarketingMultiLinkBanner/MarketingMultiLinkBannerSmall';
import MarketingMultiLinkBannerLinks from '@organisms/MarketingMultiLinkBanner/MarketingMultiLinkBannerLinks';

export default {
    name: 'CmsMarketingMultiLinkBannerSmall',

    components: { PageBuilderInterpreter, MarketingMultiLinkBannerSmall },

    extends: CmsBaseComponent,

    props: {
        slotDescription: {
            type: String,
            default: '',
        },

        slotLinks: {
            type: String,
            default: '',
        },

        ...getProps(MarketingMultiLinkBannerSmall),
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    imageUrl768x432: '',
                    mobileImageUrl768x432: '',
                    heading: '',
                    headingTag: HEADING_TAGS.SPAN,
                    headingColor: '',
                },

                slots: {
                    description: '',
                    links: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                staticClass: false,
                headingTag: Object.values(HEADING_TAGS),
            },
        };
    },

    beforeCreate() {
        this.MarketingMultiLinkBannerLinks = MarketingMultiLinkBannerLinks;
    },
};
</script>
