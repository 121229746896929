<template>
    <div v-if="time || name || description" class="live-stream-accordion-item-title">
        <div v-if="time" class="time">{{ time }}</div>
        <div v-if="name" class="name">{{ name }}</div>
        <div v-if="description" class="description">
            {{ description }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'LiveStreamAccordionItemTitle',

    props: {
        time: {
            type: String,
            default: '',
        },

        name: {
            type: String,
            default: '',
        },

        description: {
            type: String,
            default: '',
        },
    },
};
</script>

<style lang="scss" scoped>
.live-stream-accordion-item-title {
    @apply flex flex-wrap py-4;

    .name {
        @apply pl-3 font-semibold text-gray1;
    }

    .description {
        @apply text-gray1 w-full pt-2;
    }

    @screen lg {
        .description {
            @apply pl-3 pt-0 w-auto;
        }
    }
}
</style>
