<template>
    <div class="marketing-multi-link-banner-links">
        <div class="links-content">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'MarketingMultiLinkBannerLinks',
};
</script>

<style lang="scss" scoped>
.marketing-multi-link-banner-links {
    @apply overflow-hidden;

    .links-content {
        @apply mt-1 -mx-3 flex flex-wrap justify-center;
    }

    @screen md {
        .links-content {
            @apply mt-2 justify-start;
        }
    }
}
</style>
