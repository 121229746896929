<template>
    <li class="marketplace-step">
        <div class="dot" />
        <BaseParagraph class="text">
            <slot />
        </BaseParagraph>
    </li>
</template>

<script>
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';

export default {
    name: 'MarketplaceStep',

    components: {
        BaseParagraph,
    },
};
</script>

<style lang="scss" scoped>
$dot-and-line-gap: 16px;

.marketplace-step {
    @apply flex-1 text-center relative;

    &::before {
        @apply text-xxxl leading-xxxl block text-primary font-semibold;

        content: counter(marketplace-steps);
    }

    .text {
        @apply text-tertiary text-center mt-12p px-2;
    }

    @screen md {
        &:last-of-type {
            .dot {
                &::after {
                    content: initial;
                }
            }
        }

        &::before {
            font-size: theme('customVariables.marketplaceSteps.fontSizeMedium');
            line-height: theme('customVariables.marketplaceSteps.lineHeightMedium');
        }

        .dot {
            @apply relative;

            &::before {
                @apply block bg-gray4 my-12p mx-auto h-3 w-3 rounded-16;

                content: '';
            }

            &::after {
                @apply absolute block bg-gray4 top-1/2 h-1p;

                content: '';
                width: calc(100% - #{$dot-and-line-gap * 2});
                left: calc(50% + #{$dot-and-line-gap});
            }
        }

        .paragraph {
            @apply mx-auto;

            max-width: theme('customVariables.marketplaceSteps.paragraphMaxWidth');
        }
    }
}
</style>
