<template>
    <PageBuilderInterpreter
        :tag="MarketingCelebritySliderNew"
        :components-json="slotDefault"
        :class="cssClass"
        :tag-props="propsToBind"
    />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';
import { getProps } from '@modules/page-builder/helpers/component';

import MarketingCelebritySliderNew from '@organisms/MarketingCelebritySliderNew/MarketingCelebritySlider';

export default {
    name: 'CmsMarketingCelebritySliderNew',

    components: {
        PageBuilderInterpreter,
    },

    extends: CmsBaseComponent,

    props: {
        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },
        ...getProps(MarketingCelebritySliderNew),
    },

    data() {
        return {
            MarketingCelebritySliderNew,
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                slots: {
                    default: '',
                },
            },
        };
    },
};
</script>
