<template>
    <ul class="accordion">
        <slot :set-active-item-key="setActiveItemKey" :active-item-key="activeItemKey" />
    </ul>
</template>

<script>
export default {
    name: 'Accordion',

    data() {
        return {
            activeItemKey: '',
        };
    },

    methods: {
        setActiveItemKey({ key }) {
            this.activeItemKey = key;
        },
    },
};
</script>

<style lang="scss" scoped>
.accordion {
    @apply m-0 p-0 list-none;

    &-item:last-child {
        @apply border-b-0;
    }
}
</style>
