<template>
    <MarketingVideoBanner v-bind="propsToBind" :class="cssClass" />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import MarketingVideoBanner from '@organisms/MarketingVideoBanner/MarketingVideoBanner';

export default {
    name: 'CmsMarketingVideoBanner',

    components: {
        MarketingVideoBanner,
    },

    extends: CmsBaseComponent,

    props: getProps(MarketingVideoBanner),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            options: {
                staticClass: false,
            },

            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    heading: '',
                    description: '',
                    buttonLink: '',
                    buttonLabel: '',
                    videoSrc: '',
                },
            },
        };
    },
};
</script>
