<template>
    <div v-if="hasAllRequiredValues" class="marketing-celebrity-slider-slide-content">
        <div class="text">
            <BaseHeading class="title">
                {{ title }}
            </BaseHeading>
            <BaseParagraph class="content">
                {{ content }}
            </BaseParagraph>
        </div>

        <CustomProductsSimpleSliderWrapper
            :items-count="productsSliderItems"
            :product-skus="productSkus"
            class="celebrity-products-slider"
        />
    </div>
</template>

<script>
import XlBreakpointMediaQuery from '@mixins/XlBreakpointMediaQuery';

import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';

import CustomProductsSimpleSliderWrapper from '@organisms/CustomProductsSimpleSliderWrapper/CustomProductsSimpleSliderWrapper';

export default {
    name: 'MarketingCelebritySliderSlideContent',

    components: {
        CustomProductsSimpleSliderWrapper,
        BaseParagraph,
        BaseHeading,
    },

    mixins: [XlBreakpointMediaQuery],

    props: {
        title: {
            type: String,
            required: true,
        },

        content: {
            type: String,
            required: true,
        },

        productSkus: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            itemsCountXl: 4,
        };
    },

    computed: {
        hasAllRequiredValues() {
            return this.title && this.content && this.productSkus;
        },
    },
};
</script>

<style lang="scss" scoped>
$margin-left-mobile: $tailwindcss-spacing-3;

.marketing-celebrity-slider-slide-content {
    .text {
        @apply px-3;
    }

    .title {
        @apply text-l leading-l;
    }

    .content {
        @apply text-tertiary mt-3;
    }

    .celebrity-products-slider {
        @apply mt-3;
    }

    &:deep() {
        .simple-slider {
            .navigation-buttons {
                @apply hidden;
            }
        }
    }

    @screen md {
        .text {
            @apply ml-3;
        }

        .title {
            @apply text-xl leading-xl;
        }

        .celebrity-products-slider {
            @apply ml-0;
        }
    }

    @screen lg {
        &:deep() {
            .simple-slider {
                .navigation-buttons {
                    @apply flex;
                }
            }
        }
    }
}
</style>
