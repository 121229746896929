<template>
    <MarketingMultiBanner v-bind="propsToBind" :class="cssClass" @link-click="componentClick()">
        <template v-if="hasSlider" #slider>
            <PageBuilderInterpreter :components-json="slotSlider" />
        </template>
    </MarketingMultiBanner>
</template>

<script>
import { MULTI_BANNER_CLICK, MULTI_BANNER_VIEW } from '@analytics-types/Events';

import { THEMES } from '@types/MarketingBlocks';

import { getMultibannerComponentVersion } from '@assets/pagebuilder';

import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';

import MarketingMultiBanner from '@organisms/MarketingMultiBanner/MarketingMultiBanner';
import Deferred from '@eobuwie/core-components/assets/deferred';

import { BUTTON_LARGE_VARIANTS } from '@modivo-ui/components/ButtonLarge/v1';

export default {
    name: 'CmsMarketingMultiBanner',

    components: {
        MarketingMultiBanner,
        PageBuilderInterpreter,
    },

    extends: CmsBaseComponent,

    provide() {
        return {
            componentLoaded: this.componentLoaded,
            sliderNameOverride: this.heading,
            sliderListOverride: `main ${this.componentVersion}`,
        };
    },

    props: {
        slotSlider: {
            type: String,
            default: JSON.stringify(''),
        },

        ...getProps(MarketingMultiBanner),
    },

    data() {
        return {
            count: 0,
            isSliderLoadedPromise: new Deferred(),
            hasProductsInSlider: true,
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                slots: {
                    slider: '',
                },
                props: {
                    url: '',
                    heading: '',
                    description: '',
                    buttonLabel: '',
                    backgroundColor: '#0000',
                    isDesktopMediaLeftVideo: false,
                    desktopMediaLeftSrc: '',
                    isDesktopMediaRightVideo: false,
                    desktopMediaRightSrc: '',
                    isMobileMediaVideo: false,
                    mobileMediaSrc: '',
                    theme: THEMES.THEME_DARK,
                    buttonVariant: BUTTON_LARGE_VARIANTS.SECONDARY,
                    isMediaLazyLoaded: true,
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                staticClass: false,
                theme: Object.values(THEMES),
                buttonVariant: Object.values(BUTTON_LARGE_VARIANTS),
            },

            observer: null,
        };
    },

    computed: {
        hasSlider() {
            const emptySlots = ['', []].map(slot => JSON.stringify(slot));

            return !emptySlots.includes(this.slotSlider) && this.hasProductsInSlider;
        },

        componentVersion() {
            const { description, buttonLabel } = this.$props;

            return getMultibannerComponentVersion(
                {
                    description,
                    buttonLabel,
                },
                {
                    slider: JSON.parse(this.slotSlider),
                }
            );
        },
    },

    mounted() {
        if (this.disabled) {
            return;
        }

        this.observer = new IntersectionObserver(
            entries => {
                const isBlockIntersecting = entries.some(({ isIntersecting }) => isIntersecting);

                if (!isBlockIntersecting) {
                    return;
                }

                this.componentView();
                this.removeObserver();
            },
            {
                threshold: 0,
            }
        );

        this.observer.observe(this.$el);

        if (!this.hasSlider) {
            this.isSliderLoadedPromise.resolve();
        }
    },

    destroyed() {
        this.removeObserver();
    },

    methods: {
        componentClick() {
            const position = Object.keys(this.componentsList).indexOf(this.internalId);

            this.$analytics.emit(MULTI_BANNER_CLICK, {
                version: this.componentVersion,
                position,
                campaingName: this.heading,
            });
        },

        async componentView() {
            const position = Object.keys(this.componentsList).indexOf(this.internalId);

            await this.isSliderLoadedPromise.promise;

            this.$analytics.emit(MULTI_BANNER_VIEW, {
                version: this.componentVersion,
                position,
                campaingName: this.heading,
                offersCount: this.count,
            });
        },

        componentLoaded({ count }) {
            this.count = count;

            this.isSliderLoadedPromise.resolve();

            if (!count) {
                this.hasProductsInSlider = false;
            }
        },

        removeObserver() {
            this.observer?.disconnect();
            this.observer = null;
        },
    },
};
</script>
