<template>
    <label v-if="$slots.default" class="label" :for="id">
        <!--
            @slot Slot for custom inner element
        -->
        <slot />
    </label>
</template>

<script>
export default {
    name: 'BaseLabel',

    props: {
        /**
         * Id of label element.
         */
        id: {
            type: [String, Number],
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.label {
    @apply cursor-pointer;
}
</style>
