<script>
import { createNamespacedHelpers } from 'vuex';

import { NEWSLETTER_RELEASER_WISHLIST_PRODUCT } from '@configs/modals';
import { isNewsletterTooltipTestOn } from '@configs/abtest-newsletter-tooltip';

import NewsletterTooltip from '@mixins/NewsletterTooltip';

const { mapActions: mapCustomerActions, mapGetters: mapCustomerGetters } = createNamespacedHelpers(
    'customer'
);

export default {
    name: 'AddToWishlistWrapper',

    mixins: [NewsletterTooltip],

    props: {
        product: {
            type: Object,
            required: true,
        },

        chosenSize: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            isLoading: false,
        };
    },

    computed: {
        ...mapCustomerGetters(['isOnWishlist']),
    },

    methods: {
        ...mapCustomerActions(['addToWishlist', 'removeFromWishlist']),

        async toggle(shouldEnableNewsletterTooltip = false) {
            const { product, chosenSize } = this;

            this.isLoading = true;

            const productWithChosenSize = {
                ...product,
                chosenSize: chosenSize?.size || '',
                pageType: this.$route.name,
                actionField: this.$route.path,
            };

            if (this.isOnWishlist(product.sku)) {
                await this.removeFromWishlist(productWithChosenSize);
            } else {
                const result = await this.addToWishlist(productWithChosenSize);

                if (
                    result &&
                    shouldEnableNewsletterTooltip &&
                    isNewsletterTooltipTestOn(this.$abTests)
                ) {
                    this.setIsNewsletterTooltipEnabled(NEWSLETTER_RELEASER_WISHLIST_PRODUCT);
                }
            }

            this.isLoading = false;
        },
    },

    render() {
        return this.$scopedSlots.default({
            isLoading: this.isLoading,
            isAdded: this.isOnWishlist(this.product.sku),
            toggle: this.toggle,
        });
    },
};
</script>
