<template>
    <PromoActionRedirect v-bind="propsToBind" />
</template>

<script>
import { THEMES, BUTTON_THEMES } from '@types/PromoActionRedirect';

import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';

import PromoActionRedirect from '@organisms/PromoActionRedirect/PromoActionRedirect';

export default {
    name: 'CmsPromoActionRedirect',

    components: {
        PromoActionRedirect,
    },

    extends: CmsBaseComponent,

    props: getProps(PromoActionRedirect),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
                theme: Object.values(THEMES),
                buttonTheme: Object.values(BUTTON_THEMES),
            },
        };
    },
};
</script>
