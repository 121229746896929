<template>
    <section class="marketing-celebrity-slider">
        <ContainerContent>
            <SimpleSlider
                v-if="applySlider"
                :items-count="1"
                navigation-buttons-class="marketing-celebrity-slider-navigation"
                :horizontal-navigation-buttons="false"
                tag="div"
            >
                <template #slides>
                    <slot />
                </template>
            </SimpleSlider>

            <slot v-else />
        </ContainerContent>
    </section>
</template>

<script>
import SimpleSlider from '@molecules/SimpleSlider/SimpleSlider';
import ContainerContent from '@molecules/ContainerContent/ContainerContent';

export default {
    name: 'MarketingCelebritySlider',

    components: {
        SimpleSlider,
        ContainerContent,
    },

    computed: {
        applySlider() {
            return this.$slots.default.length > 1;
        },
    },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';

$picture-ratio: 0.75;
$picture-container-width-md: math.div(100%, 2);
$picture-container-width-xl: calc((100% / 3) - (32px * 2) / 3);
$container-padding: $tailwindcss-spacing-3;
$navigation-buttons-width: $tailwindcss-spacing-7 * 2;

.marketing-celebrity-slider {
    &:deep() {
        .container-content {
            @apply px-0;
        }

        .navigation-buttons {
            &.marketing-celebrity-slider-navigation {
                @apply flex bg-transparent right-16;
                top: calc((100vw - (#{$container-padding} * 2)) / #{$picture-ratio});
            }
        }
    }

    @screen md {
        &:deep() {
            .container-content {
                @apply px-3;
            }

            .navigation-buttons {
                &.marketing-celebrity-slider-navigation {
                    @apply flex right-auto top-auto bottom-0;
                    left: calc(#{$picture-container-width-md} - #{$navigation-buttons-width});
                }
            }
        }
    }

    @screen xl {
        &:deep() {
            .navigation-buttons {
                &.marketing-celebrity-slider-navigation {
                    left: calc(#{$picture-container-width-xl} - #{$navigation-buttons-width});
                }
            }
        }
    }
}
</style>
