<template>
    <div :class="theme" class="marketing-multi-banner-content">
        <BaseHeading class="heading" theme="h3">
            {{ heading }}
        </BaseHeading>
        <BaseParagraph v-if="description" class="description">
            {{ description }}
        </BaseParagraph>
        <ButtonLarge v-if="buttonLabel" :to="buttonLink" :variant="buttonVariant" class="button">
            {{ buttonLabel }}
        </ButtonLarge>
    </div>
</template>

<script>
import { THEMES } from '@types/MarketingBlocks';

import { checkIfExistsInValuesMap } from '@assets/props';

import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';

import { ButtonLarge, BUTTON_LARGE_VARIANTS } from '@modivo-ui/components/ButtonLarge/v1';

export default {
    name: 'MarketingMultiBannerContent',

    components: {
        BaseHeading,
        BaseParagraph,
        ButtonLarge,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        description: {
            type: String,
            default: '',
        },

        buttonLink: {
            type: String,
            default: '',
        },

        buttonLabel: {
            type: String,
            default: '',
        },

        theme: {
            type: String,
            default: THEMES.THEME_DARK,
            validator: checkIfExistsInValuesMap(THEMES),
        },

        buttonVariant: {
            type: String,
            default: BUTTON_LARGE_VARIANTS.SECONDARY,
            validator: checkIfExistsInValuesMap(BUTTON_LARGE_VARIANTS),
        },
    },
};
</script>

<style lang="scss" scoped>
$marketing-hero-max-height-mobile: 240px;
$marketing-hero-max-height-desktop: 433px;
$marketing-content-max-width-lg: 312px;
$marketing-content-max-width-xl: 397px;
$marketing-content-max-width-container: 448px;

.marketing-multi-banner-content {
    @apply bg-transparent w-full py-3 flex flex-col;
    max-height: $marketing-hero-max-height-mobile;

    &.light {
        .heading,
        .description {
            @apply text-light;
        }
    }

    &.dark {
        .heading,
        .description {
            @apply text-tertiary;
        }
    }

    .description {
        @apply mt-1 text-ellipsis overflow-hidden line-clamp-2;
    }

    .button {
        @apply mt-5;
    }

    @screen lg {
        @apply block;
        max-height: $marketing-hero-max-height-desktop;
        max-width: $marketing-content-max-width-lg;

        .heading {
            @apply text-xl leading-xl;
        }

        .description {
            @apply line-clamp-3;
        }
    }

    @screen xl {
        max-width: $marketing-content-max-width-xl;
    }

    @screen container {
        max-width: $marketing-content-max-width-container;
    }
}
</style>
